import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useOktaAuth } from '@okta/okta-react';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import AddIcon from '@mui/icons-material/Add';
import { GrowerMerchantService } from '../../services/Grower';

export const ADD_NEW_MERCHANT = 'addNewMerchant';

function getMerchantByID(merchantData, merchantID) {
  if (merchantID === ADD_NEW_MERCHANT) {
    return ADD_NEW_MERCHANT;
  }
  return merchantData.find(
    ({ merchant }) => merchant.merchantID === merchantID,
  );
}

function SelectPrimaryMerchant(props) {
  const { onClose, igasNumber, open } = props;
  const { authState } = useOktaAuth();
  const [merchants, setMerchants] = useState([]);

  useEffect(() => {
    GrowerMerchantService.get(igasNumber, authState).then(setMerchants);
  }, [igasNumber, authState]);

  const handleListItemClick = useCallback((value) => {
    onClose(getMerchantByID(merchants, value));
  }, [merchants, onClose]);

  if (_.isEmpty(merchants)) {
    return null;
  }

  return (
    <Dialog open={open}>
      <DialogTitle id="simple-dialog-title">Select a Primary Merchant</DialogTitle>
      <List>
        {merchants.map(({ merchant }) => (
          <ListItem
            button
            onClick={() => handleListItemClick(merchant.merchantID)}
            key={merchant.merchantID}
          >
            <ListItemAvatar>
              <Avatar>
                <HomeWorkIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={merchant.name} />
          </ListItem>
        ))}

        <ListItem autoFocus button onClick={() => handleListItemClick(ADD_NEW_MERCHANT)}>
          <ListItemAvatar>
            <Avatar>
              <AddIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Add a new merchant" />
        </ListItem>
      </List>
    </Dialog>
  );
}

SelectPrimaryMerchant.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  igasNumber: PropTypes.string,
};

SelectPrimaryMerchant.defaultProps = {
  onClose: _.noop,
  open: false,
  igasNumber: '',
};

export default SelectPrimaryMerchant;
