import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import styles from './InlineLoading.module.scss';

function InlineLoading(
  props: { isLoading: boolean, children: React.ReactElement },
): React.ReactElement {
  const { isLoading, children } = props;

  if (!isLoading) {
    return children;
  }

  return (
    <div className={styles.loadingContainer}>
      <CircularProgress />
    </div>
  );
}

export default InlineLoading;
