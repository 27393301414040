import React, { useEffect, useMemo, useState } from 'react';
import { PieChart } from 'react-minimal-pie-chart';
import { useOktaAuth } from '@okta/okta-react';
import { AuditorService } from '../../services/Auditor';
import { AuditorTotal } from '../../model/Auditor';
import styles from './AuditorRunningTotal.module.scss';

const completedColor = '#0975C0';
const forApprovalColor = '#95E06C';
const draftColor = '#2F195F';
const deactivatedColor = '#19475f';
const remainingColor = '#a1cae7';
const failedColor = '#ff0000';

export default React.memo(() => {
  const { authState } = useOktaAuth();
  const [auditorTotals, setAuditorTotals] = useState<AuditorTotal[]>([]);

  useEffect(() => {
    AuditorService.getRunningTotals(authState!)
      .then(setAuditorTotals);
  }, [authState]);

  const data = useMemo(() => (
    auditorTotals.map((obj) => {
      let total = obj.totalAudits || 0;
      let pieData: { color: string, title: string, value: number }[] = [];

      if (obj.completedAudits && obj.completedAudits !== 0) {
        total -= obj.completedAudits;
        pieData = [...pieData, {
          color: completedColor,
          title: 'Completed',
          value: obj.completedAudits,
        }];
      }

      if (obj.toBeReviewed && obj.toBeReviewed !== 0) {
        total -= obj.toBeReviewed;
        pieData = [...pieData, {
          color: forApprovalColor,
          title: 'For Approval',
          value: obj.toBeReviewed,
        }];
      }

      if (obj.draftAudits && obj.draftAudits !== 0) {
        total -= obj.draftAudits;
        pieData = [...pieData, {
          color: draftColor,
          title: 'Draft',
          value: obj.draftAudits,
        }];
      }

      if (obj.deactivatedAudits && obj.deactivatedAudits !== 0) {
        total -= obj.deactivatedAudits;
        pieData = [...pieData, {
          color: deactivatedColor,
          title: 'Deactivated',
          value: obj.deactivatedAudits,
        }];
      }

      if (obj.failedAudits && obj.failedAudits !== 0) {
        total -= obj.failedAudits;
        pieData = [...pieData, {
          color: failedColor,
          title: 'Failed',
          value: obj.failedAudits,
        }];
      }

      return {
        ...obj,
        pieData: [{
          color: remainingColor,
          title: 'Remaining',
          value: total,
        }, ...pieData],
      };
    })
  ), [auditorTotals]);

  return (
    <>
      <div className={styles.legendContainer}>
        <div className={styles.legendIndividualContainer}>
          <div style={{ backgroundColor: completedColor }} className={styles.legendColor} />
          <p>Completed</p>
        </div>
        <div className={styles.legendIndividualContainer}>
          <div style={{ backgroundColor: forApprovalColor }} className={styles.legendColor} />
          <p>For Approval</p>
        </div>
        <div className={styles.legendIndividualContainer}>
          <div style={{ backgroundColor: draftColor }} className={styles.legendColor} />
          <p>Draft</p>
        </div>
        <div className={styles.legendIndividualContainer}>
          <div style={{ backgroundColor: deactivatedColor }} className={styles.legendColor} />
          <p>Deactivated</p>
        </div>
        <div className={styles.legendIndividualContainer}>
          <div style={{ backgroundColor: remainingColor }} className={styles.legendColor} />
          <p>Remaining</p>
        </div>
        <div className={styles.legendIndividualContainer}>
          <div style={{ backgroundColor: failedColor }} className={styles.legendColor} />
          <p>Failed</p>
        </div>
      </div>
      <div className={styles.container}>
        {
          data?.map((auditorData) => (
            <div className={styles.pieContainer} key={`auditor-audit-totals-${auditorData.auditorID}`}>
              <h3>{`${auditorData.firstname} ${auditorData.surname}`}</h3>
              <PieChart
                data={auditorData.pieData}
                totalValue={auditorData.totalAudits}
                animate
                lineWidth={20}
                paddingAngle={18}
                label={({ dataEntry }) => dataEntry?.value}
                labelStyle={(index) => ({
                  fill: auditorData.pieData[index].color,
                  fontFamily: 'sans-serif',
                })}
                labelPosition={60}
              />
            </div>
          ))
        }
      </div>
    </>
  );
});
