import React, {
  useEffect,
  useCallback,
  useState,
} from 'react';
import { useOktaAuth } from '@okta/okta-react';
import Typography from '@mui/material/Typography';
import { GrowerMerchantService } from '../../../services/Grower';
import SearchTable from '../../../components/Table';
import config from '../../../config.json';
import styles from './GrowerMerchantStores.module.scss';
import SearchMerchantModal from '../../../components/SearchMerchantModal/SearchMerchantModal';
import { Merchant } from '../../../model/Merchant';
import { StorageLocationService } from '../../../services/StorageLocation';

type GrowerMerchantStoresType = {
  igasNumber: string;
};

function GrowerMerchantStores(props: GrowerMerchantStoresType) {
  const { igasNumber } = props;
  const [merchants, setMerchants] = useState([]);
  const [isLoading, setLoadingState] = useState(true);
  const { authState } = useOktaAuth();

  const getMerchants = useCallback(() => {
    setLoadingState(true);
    GrowerMerchantService.getStores(igasNumber, authState)
      .then(setMerchants)
      .then(() => {
        setLoadingState(false);
      });
  }, [authState, igasNumber]);

  useEffect(() => {
    getMerchants();
  }, [getMerchants]);

  const handleMerchantSelect = useCallback((selectedMerchant: Merchant) => {
    StorageLocationService.createGrower({
      active: true,
      igasNumber,
      isDropOff: false,
      merchantID: selectedMerchant.merchantID,
      storageCapacity: 0,
    }, authState!).then(() => {
      getMerchants();
    });
  }, [authState, getMerchants, igasNumber]);

  return (
    <>
      <div className={styles.headerContainer}>
        <Typography variant="h5" component="h1" className={styles.merchantTitle}>
          Merchant Stores
        </Typography>
        <SearchMerchantModal
          onMerchantSelect={handleMerchantSelect}
          className={styles.addButton}
        />
      </div>
      <SearchTable
        metadata={config.merchantStores.table}
        data={merchants}
        isLoading={isLoading}
      />
    </>
  );
}

export default GrowerMerchantStores;
