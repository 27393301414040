import React, { useCallback, useState } from 'react';
import { Button } from '@mui/material';
import { useOktaAuth } from '@okta/okta-react';
import { useFormContext } from 'react-hook-form';
import { PageLoadingComponent } from '../../components';
import { AuditService } from '../../services/Audit';

type AuditSendForApprovalButtonTypes<T> = {
  auditID: number;
  onGet: (auditID: number) => Promise<T>;
};

export function AuditSendForApprovalButton<T>(
  { auditID, onGet }: AuditSendForApprovalButtonTypes<T>,
) {
  const { reset } = useFormContext();
  const [isLoading, setLoadingState] = useState<boolean>(false);
  const { authState } = useOktaAuth();

  const handleSendForReview = useCallback(() => {
    setLoadingState(true);
    AuditService.sendForReview(auditID!, authState!)
      .then(() => Promise.resolve(onGet(auditID!)))
      .then((audit) => reset(audit!))
      .finally(() => setLoadingState(false));
  }, [authState, auditID, onGet, reset]);

  return (
    <PageLoadingComponent isLoading={isLoading}>
      <Button
        color="primary"
        variant="contained"
        id="sendAuditForApproval"
        onClick={handleSendForReview}
      >
        Send for approval
      </Button>
    </PageLoadingComponent>
  );
}

export default React.memo(AuditSendForApprovalButton);
