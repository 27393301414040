import { AuthState } from '@okta/okta-auth-js';
import { BaseService } from '../TransportService';
import { GrowerAnalysis, GrowerCountyCount } from '../../model/Grower';

const SERVICE_NAME = 'grower/analysis';

export default {
  count(auth: AuthState) {
    return BaseService.get<GrowerAnalysis>(`${SERVICE_NAME}/count`, { auth });
  },
  countyCount(auth: AuthState) {
    return BaseService.get<GrowerCountyCount[]>(`${SERVICE_NAME}/county/count`, { auth });
  },
};
