import React, { useCallback, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import _ from 'lodash';
import { GrowerAuditService } from '../../services/Grower';
import { MerchantAuditService } from '../../services/Merchant';
import Table from '../../components/Table';
import { PageLoadingComponent } from '../../components';
import config from '../../config.json';
import { GrowerAudit } from '../../model/Audit';
import { MerchantAudit } from '../../model/Merchant';

function RecordedAuditPage() {
  const navigate = useNavigate();
  const [growers, setGrowers] = useState<GrowerAudit[]>([]);
  const [merchants, setMerchants] = useState<MerchantAudit[]>([]);
  const { authState } = useOktaAuth();
  const [isLoading, setLoadingState] = useState(false);

  useEffect(() => {
    setLoadingState(true);
    Promise.all([
      GrowerAuditService.getRecordedList(authState!).then(setGrowers),
      MerchantAuditService.getRecordedList(authState!).then(setMerchants),
    ]).finally(() => setLoadingState(false));
  // Only run once
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGrowerSelect = useCallback((selectedID: number) => {
    const audit = growers.find((grower) => grower.auditID === selectedID);
    navigate('/growerDetail/audit', {
      state: {
        igasNumber: audit?.igasNumber,
        auditID: selectedID,
        readOnly: true,
        isReviewing: true,
      },
    });
  }, [growers, navigate]);

  const handleMerchantSelect = useCallback((selectedID: number) => {
    const audit = merchants.find((merchant) => merchant.merchantAuditID === selectedID);
    navigate('/merchantDetail/audit', {
      state: {
        merchantID: audit?.merchantID,
        merchantAuditID: selectedID,
        readOnly: true,
        isReviewing: true,
      },
    });
  }, [merchants, navigate]);

  if (!isLoading && _.isEmpty(growers) && _.isEmpty(merchants)) {
    return <h3>All audits reviewed! 😀</h3>;
  }

  return (
    <>
      <PageLoadingComponent isLoading={isLoading} />
      <Table
        metadata={config.recordedAudit.growerTable}
        data={growers}
        onSelect={handleGrowerSelect}
      />
      <Table
        metadata={config.recordedAudit.merchantsTable}
        data={merchants}
        onSelect={handleMerchantSelect}
      />
    </>
  );
}

export default RecordedAuditPage;
