import React, { useCallback, useState } from 'react';
import { Typography } from '@mui/material';
import { useOktaAuth } from '@okta/okta-react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { StorageLocationAutocomplete, PageLoadingComponent } from '../../../../../components';
import styles from './LocationFields.module.scss';
import StorageLocationDetails, { StorageLocationDetailsProps } from '../StorageLocationDetails/StorageLocationDetails';
import { StorageLocationService } from '../../../../../services/StorageLocation';
import { StorageLocation, ZStorageLocation } from '../../../../../model/Merchant';
import { AvailableValue } from '../../../../../model/AvailableValues';

type LocationFieldsTypes = Pick<StorageLocationDetailsProps, 'linkableLocations'> & {
  onClose: () => void,
  location: Partial<StorageLocation>
};

function LocationFields({
  onClose,
  location,
  linkableLocations,
}: LocationFieldsTypes) {
  const { authState } = useOktaAuth();
  const [isLoading, setLoadingState] = useState<boolean>(false);
  const methods = useForm<StorageLocation>({
    mode: 'onBlur',
    defaultValues: location,
    resolver: zodResolver(ZStorageLocation),
  });
  const {
    setValue,
    watch,
    getValues,
  } = methods;
  const storageLocationID = watch('storageLocationID');

  const handleSubmit: SubmitHandler<StorageLocation> = useCallback((data) => {
    setLoadingState(true);
    if (!data?.storageLocationDetailsID) {
      StorageLocationService.create(data, authState!)
        .finally(onClose)
        .finally(() => setLoadingState(false));
    } else {
      StorageLocationService.update(data, authState!)
        .finally(onClose)
        .finally(() => setLoadingState(false));
    }
  }, [authState, onClose]);

  return (
    <FormProvider {...methods}>
      <PageLoadingComponent isLoading={isLoading} />
      <form>
        <div className={styles.nameContainer}>
          {storageLocationID ? (
            <div className={styles.siteName} id="growerDetails">
              <Typography variant="body2" color="text.secondary">
                Storage Location name
              </Typography>
              <span>{getValues('siteName')}</span>
            </div>
          ) : (
            <StorageLocationAutocomplete
              onValueChange={
              (value?: AvailableValue | null) => {
                setValue('siteName', value?.label as string, { shouldValidate: true });
                setValue('storageLocationID', value?.code as number, { shouldValidate: true });
              }
            }
              onNewOption={
              (value?: AvailableValue | null) => {
                setValue('siteName', value?.code as string, { shouldValidate: true });
              }
            }
              label="Select a Storage Location"
              name="siteName"
            />
          )}
        </div>
        <StorageLocationDetails linkableLocations={linkableLocations} onSubmit={handleSubmit} />
      </form>
    </FormProvider>
  );
}

export default LocationFields;
