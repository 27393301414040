import React, { useMemo, useCallback } from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import ClearIcon from '@mui/icons-material/Clear';
import DangerousIcon from '@mui/icons-material/Dangerous';
import { useOktaAuth } from '@okta/okta-react';
import { useFormContext } from 'react-hook-form';
import { DefaultExtensionType, FileIcon, defaultStyles } from 'react-file-icon';
import { Divider, IconButton, Tooltip } from '@mui/material';
import Select from '../../components/Select/Select';
import Date from '../../components/Date/Date';
import { AuditAnswerAttachment, AuditQuestion as AuditQuestionType } from '../../model/Audit';
import Input from '../../components/Input/Input';
import { AuditAnswerService } from '../../services/Audit';
import Checkbox from '../../components/Checkbox/Checkbox';
import styles from './AuditQuestion.module.scss';
import config from '../../config.json';
import { FileUpload } from '../../components';

type AuditQuestionPropsType = {
  question: AuditQuestionType;
  name: string;
  readOnly?: boolean;
  index?: number;
};

function AuditQuestion(props: AuditQuestionPropsType) {
  const { watch, getValues, setValue } = useFormContext();
  const { authState } = useOktaAuth();
  const {
    question,
    name,
    readOnly,
    index,
  } = props;
  const values = getValues();

  const {
    questionText,
    questionType,
    questionCode,
    questionID,
    defaultAnswer,
  } = question;

  const [answer, auditAnswerID, attachments = [], isIncorrect] = watch([`${name}.answer`, `${name}.auditAnswerID`, `${name}.attachments`, `${name}.isIncorrect`]);
  const type = useMemo(() => (values?.igasNumber ? 'grower' : 'storageLocation'), [values?.igasNumber]);

  const handleUpload = useCallback((files: File[]) => AuditAnswerService.uploadFile(
    type,
    type === 'grower' ? values?.igasNumber : values?.storageLocationDetailsID,
    questionCode,
    files,
    authState!,
    auditAnswerID,
  ).then((attachment = []) => {
    const newList = [...attachments, ...attachment];
    setValue(`${name}.attachments`, newList, { shouldDirty: true });
  }), [type, values, questionCode, authState, auditAnswerID, attachments, setValue, name]);

  const handleDownload = useCallback((attachment: AuditAnswerAttachment) => {
    AuditAnswerService.downloadFile(attachment.attachmentID!, attachment.filename, authState!);
  }, [authState]);

  const handleDeleteAttachment = useCallback((attachment: AuditAnswerAttachment) => {
    AuditAnswerService.delete(attachment.attachmentID!, authState!).then(() => {
      const newList = attachments.filter(
        (file: AuditAnswerAttachment) => file.attachmentID !== attachment.attachmentID,
      );
      setValue(`${name}.attachments`, newList, { shouldDirty: true });
    });
  }, [attachments, authState, name, setValue]);

  const content = useMemo(() => {
    const commonProps = {
      id: `question_${questionID}`,
      key: `question_${questionID}`,
      label: `${questionCode} Answer`,
      className: styles.field,
      name: `${name}.answer`,
      required: true,
      disabled: readOnly,
    };

    if (questionType === 'YESNONA') {
      return (
        <Select
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...commonProps}
          availableValues={config.typelist.yesnona}
        />
      );
    }

    if (questionType === 'BOOLEAN') {
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <Checkbox {...commonProps} />;
    }

    if (questionType === 'DATE') {
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <Date {...commonProps} />;
    }

    if (questionType === 'MONTHYEAR') {
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <Date {...commonProps} views={['year', 'month']} openTo="year" />;
    }

    if (questionType === 'NUMBER') {
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <Input {...commonProps} type="number" />;
    }
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Input {...commonProps} />;
  }, [name, questionCode, questionID, questionType, readOnly]);

  return (
    <Stack direction="column" justifyContent="center" mt={index === 0 ? 0 : 10} width="100%" divider={<Divider orientation="vertical" flexItem />}>
      <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
        <Typography variant="body1" component="p" className={styles.question}>
          {`${questionCode} ${questionText}`}
        </Typography>
        <Stack direction="row">
          {isIncorrect && readOnly ? <DangerousIcon /> : null}
          <FileUpload onFileUpload={handleUpload} id={`question_${questionID}_proof`} iconOnly />
        </Stack>
      </Stack>
      <Stack direction="column" spacing={2} justifyContent="center" width="100%">
        {content}
        <Input
          key={`question_${questionID}_explanation`}
          id={`question_${questionID}_explanation`}
          label={`${questionCode} Explanation`}
          className={styles.explanationField}
          name={`${name}.explanation`}
          required={questionType === 'YESNONA' && (defaultAnswer === 'Yes' ? answer === 'No' : (defaultAnswer === 'No' && answer === 'Yes'))}
          disabled={readOnly}
          multiline
          rows={4}
        />
        {/* {type === 'grower' ? (
          <Input
            key={`question_${questionID}_recommendation`}
            id={`question_${questionID}_recommendation`}
            label={`${questionCode} Recommendation`}
            className={styles.recommendationField}
            name={`${name}.recommendation`}
            disabled={readOnly}
            helperText="This will be sent to the grower"
            multiline
            rows={4}
          />
        ) : null} */}
      </Stack>
      <Stack direction="row" spacing={2} height={50} mt={2} justifyContent="flex-start">
        {(attachments || []).map((attachment: AuditAnswerAttachment) => (
          <Tooltip title={attachment.filename} arrow placement="top" key={attachment.filename}>
            <div className={styles.icon}>
              <IconButton
                aria-label="download"
                size="small"
                className={styles.fileIcon}
                onClick={() => handleDownload(attachment)}
              >
                <FileIcon
                  extension={attachment.fileType}
                  {...defaultStyles[attachment.fileType as unknown as DefaultExtensionType]}
                />
              </IconButton>
              <IconButton aria-label="deleteAttachment" color="secondary" onClick={() => handleDeleteAttachment(attachment)} className={styles.iconDelete}>
                <ClearIcon />
              </IconButton>
            </div>
          </Tooltip>
        ))}
      </Stack>
    </Stack>
  );
}

AuditQuestion.defaultProps = {
  readOnly: false,
};

export default AuditQuestion;
