import React, { useCallback, useState } from 'react';
import { Button } from '@mui/material';
import { useOktaAuth } from '@okta/okta-react';
import { useFormContext } from 'react-hook-form';
import { PageLoadingComponent } from '../../components';
import { AuditService } from '../../services/Audit';
import { Audit } from '../../model/Audit';

type AuditRevertToDraftButtonTypes = {
  auditID: number;
  onGet: (auditID: number) => Promise<Audit>;
};

export const AuditRevertToDraftButton: React.FC<AuditRevertToDraftButtonTypes> = (
  { auditID, onGet },
) => {
  const { reset } = useFormContext();
  const [isLoading, setLoadingState] = useState<boolean>(false);
  const { authState } = useOktaAuth();

  const handleRevertToDraft = useCallback(() => {
    if (auditID) {
      setLoadingState(true);
      AuditService.revertToDraft(auditID, authState!)
        .then(() => Promise.resolve(onGet(auditID!)))
        .then((audit) => reset(audit!))
        .finally(() => setLoadingState(false));
    }
  }, [auditID, authState, reset, onGet]);

  return (
    <PageLoadingComponent isLoading={isLoading}>
      <Button
        color="primary"
        variant="contained"
        id="reverToDraft"
        onClick={handleRevertToDraft}
      >
        Revert to draft
      </Button>
    </PageLoadingComponent>
  );
};

export default React.memo(AuditRevertToDraftButton);
