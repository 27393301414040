import { Poll } from '../../model/File/File';

const BASE_RETRY_INTERVAL = 1000;
const OFFSET = 250;
export const delay = (ms: number) => new Promise((res) => { setTimeout(res, ms); });

export const pollDownload = async (
  callback: () => Promise<Poll>,
  attempt: number = 1,
  maxAttempt: number = 30,
): Promise<Poll | undefined> => {
  if (attempt > maxAttempt) {
    return;
  }

  const retryInterval = BASE_RETRY_INTERVAL + OFFSET * attempt;
  const response = await callback();
  if (response.isReady) {
    // eslint-disable-next-line consistent-return
    return response;
  }
  await delay(retryInterval);

  // eslint-disable-next-line consistent-return
  return pollDownload(callback, attempt + 1, maxAttempt);
};
