import * as z from 'zod';
import { getDateFromString, isBeforeDate } from '../../util/dateUtils';

export const ZAuditorReviewDates = z.object({
  toDate: z.string(),
  fromDate: z.string(),
}).superRefine((data, ctx) => {
  const fromDate = getDateFromString(data.fromDate);
  const toDate = getDateFromString(data.toDate);

  if (!isBeforeDate(fromDate!, toDate!)) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      path: ['toDate'],
      message: 'Date should be greater than the start date',
    });
  }
});

export const ZAuditorReview = z.object({
  igasNumber: z.string(),
  firstname: z.string(),
  surname: z.string(),
  address: z.object({
    addressLine1: z.string(),
    addressLine2: z.string().optional(),
    town: z.string(),
    county: z.string(),
    eircode: z.string(),
  }),
});

export const ZAuditeeToFollowUpOn = z.object({
  igasNumber: z.string(),
  firstname: z.string(),
  surname: z.string(),
  address: z.object({
    addressLine1: z.string(),
    addressLine2: z.string().optional(),
    town: z.string(),
    county: z.string(),
    eircode: z.string(),
  }),
  auditID: z.number(),
  answer: z.string(),
  questionText: z.string(),
  explanation: z.string(),
});

export type AuditorReview = z.infer<typeof ZAuditorReview>;
export type AuditorReviewDates = z.infer<typeof ZAuditorReviewDates>;
export type AuditeeToFollowUpOn = z.infer<typeof ZAuditeeToFollowUpOn>;
