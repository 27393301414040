import { BaseService } from '../TransportService';

const SERVICE_NAME = 'merchant/branch';

export default {
  createBranch(headquarterID, branchID, auth) {
    const branch = { headquarterID, branchID };
    return BaseService.post(SERVICE_NAME, branch, auth);
  },
  getBranches(id, auth) {
    const params = { headquarterID: id };
    return BaseService.get(`${SERVICE_NAME}/branches`, { params, auth });
  },
  getHeadquarter(id, auth) {
    const params = { branchID: id };
    return BaseService.get(`${SERVICE_NAME}/headquarter`, { params, auth });
  },
  deleteBranch(headquarterID, branchID, auth) {
    const params = { headquarterID, branchID };
    return BaseService.delete(SERVICE_NAME, params, auth);
  },
};
