import React, { useCallback } from 'react';
import Button, { ButtonProps } from '@mui/material/Button';

function ButtonComponent(props: ButtonProps) {
  const { onClick } = props;

  const handleClick = useCallback((event: any) => {
    event.preventDefault();
    if (onClick) {
      onClick(event);
    }
  }, [onClick]);

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Button {...props} onClick={handleClick} />
  );
}

export default ButtonComponent;
