/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useEffect, useState, useCallback, useMemo,
} from 'react';
import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Typography from '@mui/material/Typography';

import { PageLoadingComponent } from '../../components';

import AddGrowerPage from './AddGrowerPage/AddGrowerPage';
import AddMerchantsToGrower from './AddMerchantsToGrower/AddMerchantsToGrower';
import AddNotesToGrower from './AddNotesToGrower/AddNotesToGrower';

const BASE_PATH = '/addGrowerWizard';

const STEPS = ['Add Grower Details', 'Add Merchant to Grower', 'Add Notes'];

const STEP_DETAILS = [{
  label: 'Add Grower Details',
  path: `${BASE_PATH}/addGrower`,
}, {
  label: 'Add Merchants',
  path: `${BASE_PATH}/addMerchant`,
  isOptional: true,
}, {
  label: 'Add Notes',
  path: `${BASE_PATH}/addNotes`,
  isOptional: true,
}];

function AddGrowerWizard() {
  const [isPageInitialized, setPageInitialization] = useState(false);
  const [wizardData, updateWizardData] = useState({});
  const [activeStep, setActiveStep] = useState(0);
  // const [isLoading, setLoadingState] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading] = useState(false);

  useEffect(() => {
    if (location.pathname !== BASE_PATH) {
      const stepIndex = STEP_DETAILS.findIndex((step) => step.path === location.pathname);
      setActiveStep(stepIndex);
    }
    setPageInitialization(true);
  // we only want this to run once
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleStep = useCallback((stepIndex) => () => {
    const step = STEP_DETAILS[stepIndex];
    setActiveStep(stepIndex);
    navigate(step.path);
  }, [navigate]);

  const handleCancel = useCallback((igasNumber) => {
    // const document = { selectedDocument: 'growerRegistrationLetter' };
    // setLoadingState(true);
    // GrowerService.generate(igasNumber, document, authState).then((url) => {
    //   setLoadingState(false);
    //   return url;
    // }).then(() => {
    // });
    if (wizardData?.igasNumber || igasNumber) {
      navigate('/growerDetail', {
        state: {
          igasNumber: igasNumber || wizardData?.igasNumber,
        },
      });
    }
  }, [navigate, wizardData?.igasNumber]);

  const handleNext = useCallback(async () => {
    const isLastStep = activeStep === STEPS.length - 1;
    if (isLastStep) {
      handleCancel();
    }

    const newActiveStep = activeStep + 1;

    setActiveStep(newActiveStep);
    handleStep(newActiveStep)();
  }, [activeStep, handleCancel, handleStep]);

  const handleFinish = useCallback(() => {
    navigate('/growerDetail', {
      state: {
        igasNumber: wizardData.igasNumber,
      },
    });
  }, [navigate, wizardData]);

  const shouldRedirect = (
    isPageInitialized
    && location.pathname === BASE_PATH
  );

  const pageProps = useMemo(() => ({
    wizardData,
    updateWizardData,
    onNext: handleNext,
    onCancel: handleCancel,
    onFinish: handleFinish,
  }), [
    wizardData,
    updateWizardData,
    handleNext,
    handleCancel,
    handleFinish,
  ]);

  return (
    <div>
      <PageLoadingComponent isLoading={isLoading} />
      <Stepper alternativeLabel nonLinear activeStep={activeStep}>
        {STEP_DETAILS.map(({ label, isOptional }, index) => {
          const buttonProps = {};
          if (isOptional) {
            buttonProps.optional = <Typography variant="caption">Optional</Typography>;
          }
          return (
            <Step key={label}>
              <StepButton
                onClick={handleStep(index)}
                {...buttonProps}
              >
                {label}
              </StepButton>
            </Step>
          );
        })}
      </Stepper>
      <Routes>
        <Route path="addGrower" element={<AddGrowerPage {...pageProps} />} />
        <Route path="addMerchant" element={<AddMerchantsToGrower {...pageProps} />} />
        <Route path="addNotes" element={<AddNotesToGrower {...pageProps} />} />
      </Routes>
      {shouldRedirect ? <Navigate to={`${BASE_PATH}/addGrower`} /> : null}
    </div>
  );
}

export default AddGrowerWizard;
