import { AuthState } from '@okta/okta-auth-js';
import { StorageLocationAudit } from '../../model/Audit';
import { BaseService } from '../TransportService';

const SERVICE_NAME = 'storage/location/audit';

export default {
  create(data: StorageLocationAudit, auth: AuthState): Promise<StorageLocationAudit> {
    return BaseService.post<StorageLocationAudit>(SERVICE_NAME, data, auth);
  },
  get(storageLocationAuditID: number, auth: AuthState): Promise<StorageLocationAudit> {
    const params = { storageLocationAuditID };
    return BaseService.get<StorageLocationAudit>(SERVICE_NAME, { params, auth });
  },
  getByAuditID(auditID: number, auth: AuthState): Promise<StorageLocationAudit> {
    const params = { auditID };
    return BaseService.get<StorageLocationAudit>(`${SERVICE_NAME}/audit`, { params, auth });
  },
  update(data: StorageLocationAudit, auth: AuthState): Promise<void> {
    return BaseService.put<StorageLocationAudit, void>(SERVICE_NAME, data, auth);
  },
};
