import { BaseService, DocumentService } from '../TransportService';

const SERVICE_NAME = 'invoice/directDebit';

export default {
  generateAll(filename, auth, shouldSave = true) {
    return DocumentService.get(`${SERVICE_NAME}/generate/all`, { shouldSave }, filename, auth);
  },
  generateOne(igasNumber, auth) {
    const params = { igasNumber };
    return BaseService.get(`${SERVICE_NAME}/generate/one`, { params, auth });
  },
  validate(auth) {
    return BaseService.get(`${SERVICE_NAME}/validate`, { auth });
  },
};
