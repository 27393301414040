import React from 'react';
import { Button } from '../../components';

export default function ClearStorage() {
  const handleClick = React.useCallback(() => {
    for (const key in localStorage) {
      if (key.startsWith('merchantAuditForIGAS')) {
        localStorage.removeItem(key);
      }
    }
    localStorage.setItem('auditListKey', '[]');
  }, []);

  return <Button onClick={handleClick}>Clear</Button>;
}
