import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
} from 'react';
import _ from 'lodash';
import { useOktaAuth } from '@okta/okta-react';
import { AuditService, AuditListService } from '../../services/Audit';
import { AuditorService } from '../../services/Auditor';
import CheckboxField from '../../components/CheckboxField/CheckboxField';
import {
  Button,
  PageLoadingComponent,
  getAddressDisplayName,
  Table,
  useModal,
  ProgressBar,
  SelectField,
  AuditorRunningTotal,
} from '../../components';
import config from '../../config.json';
import styles from './AuditAssignPage.module.scss';

function AuditAssignPage() {
  const [auditorTotals, setAuditorTotals] = useState([]);
  const [auditors, setAuditors] = useState([]);
  const [auditees, setAuditees] = useState([]);
  const [auditYears, setAuditYears] = useState([]);
  const [shouldFilter, setShouldFilter] = useState(true);
  const [shouldOverride, setShouldOverride] = useState(true);
  const [selectedAudits, setSelectedAudits] = useState([]);
  const [loadingState, setLoadingState] = useState({ isLoading: false });
  const [isCountyVisible, setCountyVisible] = useState(false);
  const [harvestYear, setHarvestYear] = useState();
  const { authState } = useOktaAuth();
  const ModalService = useModal();

  const handleError = useCallback(() => {
    setLoadingState(false);
    ModalService.openModal({
      title: 'Error Occur',
      description: 'An Error occurred. Please try again.',
    });
  }, [ModalService]);

  useEffect(() => {
    setLoadingState({ isLoading: true });
    Promise.all([
      AuditorService.getActive(authState).then(setAuditors),
      AuditService.getAuditYears(authState).then((audits) => {
        const previousAudits = audits.map((audit) => ({
          label: audit.harvestYear,
          code: audit.harvestYear,
        }));
        setAuditYears(previousAudits);
      }),
    ]).finally(() => {
      setLoadingState({ isLoading: false });
    });
  }, [authState]);

  const generateNewList = useCallback(() => {
    ModalService.openModal({
      title: 'Do you want to generate a new list?',
      description: 'This will generate a new audit list. Are you sure you want to continue?',
    }).then(() => {
      setLoadingState({
        isLoading: true,
        message: 'This may take a while. Generating and saving new Audit list.',
      });
      AuditListService.create(authState)
        .then(setAuditees)
        .catch(handleError)
        .finally(() => setLoadingState({ isLoading: false }));
    });
  }, [ModalService, authState, handleError]);

  const handleGenerateAuditList = useCallback((value) => {
    setHarvestYear(value);

    setLoadingState({
      isLoading: true,
      message: 'Retrieving existing audit list.',
    });

    Promise.all([
      AuditListService.get(value, authState).then(setAuditees),
      AuditorService.getTotalsForHarvestYear(authState).then(setAuditorTotals),
    ]).then(() => {
      setLoadingState({ isLoading: false });
    });
  }, [authState]);

  const handleAssignment = useCallback((growerArray) => {
    const growers = growerArray.map((growerObject) => growerObject?.growers || growerObject);

    ModalService.openModal({
      title: 'Audit Assign',
      description: `Who do you want to assign these ${growers.length} growers to?`,
      hasSelect: true,
      selectProps: {
        id: 'auditorList',
        defaultValue: growers[0].auditorID,
        availableValues: auditors.map((auditor) => ({
          label: `${auditor?.firstname} ${auditor?.surname}`,
          code: auditor.auditorID,
        })),
      },
    }).then((selectedAuditor) => {
      setLoadingState({ isLoading: true });
      growers.forEach((grower) => {
        _.set(grower, 'auditorID', selectedAuditor);
      });
      AuditListService.assign(growers, shouldFilter, shouldOverride, authState).then((newList) => {
        setAuditees(newList);
        setLoadingState({ isLoading: false });
        setSelectedAudits([]);
      }).catch(handleError);
    });
  }, [ModalService, auditors, shouldFilter, authState, handleError, shouldOverride]);

  const normalTable = useMemo(() => {
    let newAuditees = auditees;
    if (shouldFilter) {
      newAuditees = auditees.filter((auditee) => !auditee.auditorID);
    }
    const structuredGrowers = newAuditees.map((auditee) => ({
      igasNumber: auditee.igasNumber,
      name: `${auditee.firstname} ${auditee.surname}`,
      address: getAddressDisplayName(auditee.address),
      auditorID: auditee.auditorID,
      auditorName: auditors.find((auditor) => auditor.auditorID === auditee.auditorID)?.firstname,
      growers: auditee,
    }));
    return (
      <Table
        data={structuredGrowers}
        metadata={config.auditAssignNormal.table}
        onMultiSelection={setSelectedAudits}
        selectedRows={selectedAudits}
      />
    );
  }, [auditees, auditors, selectedAudits, shouldFilter]);

  const countyTable = useMemo(() => {
    let newAuditees = auditees;
    if (shouldFilter) {
      newAuditees = auditees.filter((auditee) => !auditee.auditorID);
    }
    const groupedGrowers = _.groupBy(newAuditees, 'address.county');
    const structuredCounties = Object.entries(groupedGrowers).map(([county, growers]) => ({
      number: growers.length,
      growers,
      county,
    }));
    return (
      <Table
        data={structuredCounties}
        metadata={config.auditAssignCounty.table}
        onSelect={handleAssignment}
      />
    );
  }, [auditees, handleAssignment, shouldFilter]);

  const auditorTotalComponent = useMemo(() => (
    auditorTotals.filter(Boolean).map((auditorTotal = {}) => {
      const groupedGrowers = _.groupBy(auditees, 'auditorID') || {};
      const auditorsAudits = groupedGrowers[auditorTotal.auditorID] || [];
      const numberAssigned = auditorsAudits.length;
      return (
        <div className={styles.totals}>
          <span>{`${auditorTotal?.firstname} ${auditorTotal?.surname}`}</span>
          {/* FIXME: calculate number */}
          <ProgressBar number={numberAssigned} total={auditorTotal.totalAudits} />
        </div>
      );
    })
  ), [auditorTotals, auditees]);

  return (
    <>
      <PageLoadingComponent isLoading={loadingState.isLoading} message={loadingState.message} />
      {_.isEmpty(auditorTotals) ? null : (
        <div className={styles.totalsContainer}>
          {auditorTotalComponent}
        </div>
      )}
      <div className={styles.buttonContainer}>
        <SelectField className={styles.selectYear} label="Select Harvest Year" value={harvestYear} name="harvestYear" id="harvestYear" onValueChange={handleGenerateAuditList} availableValues={auditYears} />
        {!harvestYear ? (
          <Button onClick={generateNewList}>
            Generate new Audit List
          </Button>
        ) : null}
        {_.isEmpty(auditorTotals) ? null : (
          <div>
            <CheckboxField
              id="shouldOverride"
              label="Override Auditor"
              onValueChange={(value) => setShouldOverride(value)}
              value={shouldOverride}
            />
            <CheckboxField
              id="filterList"
              label="Filter list"
              onValueChange={(value) => setShouldFilter(value)}
              value={shouldFilter}
            />
            <Button onClick={() => setCountyVisible(!isCountyVisible)} variant="outlined" color="primary">
              Toggle view
            </Button>
            <Button onClick={() => handleAssignment(selectedAudits)} id="assignAudits">
              Assign
            </Button>
          </div>
        )}
      </div>
      {
        // eslint-disable-next-line no-nested-ternary
        _.isEmpty(auditees) ? <AuditorRunningTotal />
          : isCountyVisible ? countyTable : normalTable
      }
    </>
  );
}

export default AuditAssignPage;
