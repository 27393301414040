import * as z from 'zod';
import { ZAuditNote } from '../Note';

export const ZAuditResult = z.enum(['RECOMMENDED', 'MINOR', 'MAJOR', 'CRITICAL']);

export const ZAuditStage = z.enum(['INITIAL', 'ASSIGNED', 'DRAFT', 'RECORDED', 'REVIEWED', 'DEFERRED', 'FAILED']);

export const ZAuditType = z.enum(['PRODUCTIONSTORAGE', 'PRODUCTIONONLY', 'STORAGELOCATION', 'ISOMERCHANT', 'STORAGEONLY', 'SLVISUAL']);

export const ZAuditQuestionFixBy = z.enum(['MONTH', 'NEXTAUDIT']);

export const ZAuditQuestionType = z.enum(['YESNONA', 'BOOLEAN', 'DROPDOWN', 'DATE', 'MONTHYEAR', 'STRING', 'NUMBER']);

export const ZAuditQuestionSeverity = z.enum(['NONE', 'RECOMMENDED', 'MINOR', 'MAJOR', 'CRITICAL']);

export const ZAuditQuestion = z.object({
  activeQuestion: z.boolean().nullish(),
  auditType: ZAuditType.nullish(),
  category: z.string().min(1),
  defaultAnswer: z.string().nullish(),
  fixBy: ZAuditQuestionFixBy.nullish(),
  questionCode: z.string().min(1),
  questionID: z.number().optional(),
  questionOrder: z.number().nullish(),
  questionText: z.string().min(1),
  questionType: ZAuditQuestionType,
  sectionTitle: z.string().nullish(),
  severity: ZAuditQuestionSeverity,
});

export const ZAuditAnswerAttachment = z.object({
  attachmentID: z.number().nullish(),
  filename: z.string(),
  fileType: z.string(),
  s3Key: z.string(),
  contentType: z.string(),
  auditAnswerAttachmentID: z.number().nullish(),
  auditAnswerID: z.number().nullish(),
});

export const ZAuditAnswer = z.object({
  answer: z.string().or(z.boolean()).or(z.number()).nullish(),
  auditAnswerID: z.number().optional(),
  auditID: z.number().optional(),
  explanation: z.string().nullish(),
  recommendation: z.string().nullish(),
  isIncorrect: z.boolean().nullish(),
  question: ZAuditQuestion,
  attachments: z.array(ZAuditAnswerAttachment).nullish(),
}).superRefine((data, ctx) => {
  const isYesNoNa = data.question.questionType === ZAuditQuestionType.Enum.YESNONA;
  const defaultAnswerIsNo = data.question.defaultAnswer === 'No' && data.answer === 'Yes';
  const defaultAnswerIsYes = data.question.defaultAnswer === 'Yes' && data.answer === 'No';
  if (isYesNoNa && (defaultAnswerIsNo || defaultAnswerIsYes) && !data.explanation) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      path: ['explanation'],
      message: `${data.question.questionCode}: Explanation is required`,
    });
  }
});

export const ZAudit = z.object({
  applicationNumber: z.number().nullish(),
  approvalDate: z.string().nullish(),
  auditDate: z.string(),
  auditID: z.number().optional(),
  auditResult: ZAuditResult.optional(),
  auditStage: ZAuditStage,
  auditType: ZAuditType,
  auditorID: z.number().nullish(),
  harvestYear: z.number().optional().or(z.number().refine((value) => value >= 2000, {
    message: 'Harvest year should be greater than 2000',
  })),
  notes: z.array(ZAuditNote).optional(),
  resultDescription: z.string().optional(),
  score: z.number({
    required_error: 'Score is required',
  }).max(10),
});

export const ZGrowerAudit = ZAudit.extend({
  igasNumber: z.number(),
  growerAuditID: z.number().nullish(),
  tonnage: z.number().nullish(),
  barley: z.number(),
  wheat: z.number(),
  oats: z.number(),
  rye: z.number(),
  oilseedRape: z.number(),
  beansAndPeas: z.number(),
  productionOnly: ZAuditAnswer.array(),
  productionStorage: ZAuditAnswer.array().nullish(),
}).refine((data) => (
  data.auditType !== 'PRODUCTIONSTORAGE' || data.tonnage! >= 0
), {
  message: 'Tonnage is required',
  path: ['tonnage'],
});

export const ZStorageLocationAudit = ZAudit.extend({
  currentStock: z.number({
    required_error: 'Current stock is required',
  }),
  intake: z.number({
    required_error: 'Intake is required',
  }),
  auditType: z.enum(['STORAGELOCATION', 'ISOMERCHANT', 'SLVISUAL']),
  merchantAuditID: z.number(),
  merchantContactID: z.string().min(1),
  questions: ZAuditAnswer.array(),
  storageLocationAuditID: z.number().optional(),
  storageLocationDetailsID: z.number(),
});

export const ZAuditYear = z.object({
  harvestYear: z.number(),
});
export const ZCurrentAuditYear = z.object({
  currentYear: z.number(),
});
export const ZRecordedAuditCount = z.object({
  growerAudits: z.number(),
  storageLocationAudits: z.number(),
  totalAudits: z.number(),
});

export type Audit = z.infer<typeof ZAudit>;
export type GrowerAudit = z.infer<typeof ZGrowerAudit>;
export type StorageLocationAudit = z.infer<typeof ZStorageLocationAudit>;

export type AuditAnswerAttachment = z.infer<typeof ZAuditAnswerAttachment>;
export type AuditAnswer = z.infer<typeof ZAuditAnswer>;
export type AuditQuestion = z.infer<typeof ZAuditQuestion>;

export type AuditResult = z.infer<typeof ZAuditResult>;
export type AuditStage = z.infer<typeof ZAuditStage>;
export type AuditType = z.infer<typeof ZAuditType>;
export type AuditQuestionFixBy = z.infer<typeof ZAuditQuestionFixBy>;
export type AuditQuestionType = z.infer<typeof ZAuditQuestionType>;
export type AuditQuestionSeverity = z.infer<typeof ZAuditQuestionSeverity>;

export type AuditYear = z.infer<typeof ZAuditYear>;
export type CurrentAuditYear = z.infer<typeof ZCurrentAuditYear>;
export type RecordedAuditCount = z.infer<typeof ZRecordedAuditCount>;
