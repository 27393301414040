import React, { useCallback, useState } from 'react';
import { Accordion as ExpansionPanel } from '@mui/material';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

type AccordionTypes = {
  renderHeader: (isOpen: boolean) => React.ReactElement,
  isInitiallyOpen?: boolean,
  className?: string,
  containerClassName?: string,
  children: React.ReactElement,
  id?: string,
};

function Accordion(props: AccordionTypes) {
  const {
    renderHeader, children, isInitiallyOpen = false, className, id, containerClassName,
  } = props;
  const [isOpen, setOpenState] = useState<boolean>(isInitiallyOpen);

  const handleClick = useCallback((_: any, expanded: boolean) => {
    setOpenState(expanded);
  }, []);

  return (
    <ExpansionPanel
      expanded={isOpen}
      onChange={handleClick}
      id={id}
      className={containerClassName}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {renderHeader(isOpen)}
      </AccordionSummary>
      <AccordionDetails className={className}>
        {children}
      </AccordionDetails>
    </ExpansionPanel>
  );
}

Accordion.defaultProps = {
  isInitiallyOpen: false,
  className: '',
  containerClassName: '',
  id: undefined,
};

export default React.memo(Accordion);
