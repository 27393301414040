import React, { useMemo } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import RecyclingIcon from '@mui/icons-material/Recycling';
import Diversity1Icon from '@mui/icons-material/Diversity1';
import { Outlet } from 'react-router-dom';
import { TabSet } from '../../components';
import SustainabilityReport from './SustainabilityReport';
import AuthUtil from '../../util/authUtil';
import GrowerReport from './GrowerReport';

function AnalyticsPage(): React.ReactElement {
  const { authState } = useOktaAuth();

  const TABS = useMemo(() => (
    [
      {
        icon: <Diversity1Icon />,
        label: 'Growers',
        content: <GrowerReport />,
        id: 'growers',
        access: ['Admin'],
      },
      {
        icon: <RecyclingIcon />,
        label: 'Sustainability Report',
        content: <SustainabilityReport />,
        id: 'sustainabilityReport',
        access: ['Admin'],
      },
    ].filter((tab) => AuthUtil.hasAccess(tab?.access, authState!))
  ), [authState]);

  return (
    <>
      <TabSet tabs={TABS} rootPath="/analytics" />
      <Outlet />
    </>
  );
}

export default AnalyticsPage;
