import * as z from 'zod';

export const ZMerchant = z.object({
  active: z.boolean(),
  address: z.object({
    addressLine1: z.string(),
    addressLine2: z.string().optional(),
    town: z.string(),
    county: z.string(),
    eircode: z.string().optional(),
  }),
  email: z.string().email().optional(),
  landlineNumber: z.string().optional(),
  name: z.string(),
  merchantID: z.string().optional(),
});

export type Merchant = z.infer<typeof ZMerchant>;
