import React, { useCallback } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import Autocomplete, { AutocompleteType } from '../Autocomplete/Autocomplete';
import { AvailableValue } from '../../model/AvailableValues';
import { StorageLocationService } from '../../services/StorageLocation';

type StorageLocationAutocompleteTypes = Omit<AutocompleteType, 'onLoadData'> & {
  storageLocationIDs?: number[];
};

export default function StorageLocationAutocomplete(props: StorageLocationAutocompleteTypes) {
  const { onValueChange, ...rest } = props;
  const { authState } = useOktaAuth();

  const handleGrowerSearch = useCallback((searchQuery: string): Promise<AvailableValue[]> => (
    StorageLocationService.search(searchQuery, authState!)
      .then((data) => data.map((sl) => ({
        label: sl.siteName,
        code: sl.storageLocationID!,
      })))
  ), [authState]);

  return (
    <Autocomplete
      {...rest}
      onLoadData={handleGrowerSearch}
      onValueChange={onValueChange}
    />
  );
}
