import React, { useContext, useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';

export interface BreakpointContextProviderProps {
  children: React.ReactElement;
  styles: Record<string, string | number>,
}

export type BreakpointContextTypes = {
  isDesktop: boolean;
  isTablet: boolean;
  isMobile: boolean;
};

export const BreakpointContext = React.createContext<BreakpointContextTypes | null>(null);

export function BreakpointContextProvider(props: BreakpointContextProviderProps) {
  const { children, styles } = props;
  const isDesktop = useMediaQuery({ query: `(min-width: ${styles.desktopSize})` });
  const isTablet = useMediaQuery({ query: `(min-width: ${styles.tabletSize})` });
  const isMobile = useMediaQuery({ query: `(max-width: ${styles.phoneSize})` });

  const breakpointStyles = useMemo(() => ({ isDesktop, isTablet, isMobile }), [
    isDesktop, isTablet, isMobile,
  ]);

  return (
    <BreakpointContext.Provider value={breakpointStyles}>
      {children}
    </BreakpointContext.Provider>
  );
}

export function useBreakpoint() {
  return useContext(BreakpointContext);
}
