import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useOktaAuth } from '@okta/okta-react';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import {
  Table, ModalWithContent, SearchComponent,
} from '../../components';
import { MerchantService } from '../../services/Merchant';
import styles from './BranchComponent.module.scss';
import config from '../../config.json';

function BranchComponent(props) {
  const {
    branches,
    onSelect,
    onAdd,
    merchantID,
  } = props;
  const { authState } = useOktaAuth();
  const [isModalOpen, setModalState] = useState(false);
  const [modalData, setModalData] = useState([]);

  const toggleModal = useCallback(() => {
    setModalState(!isModalOpen);
  }, [isModalOpen]);

  const handleCandiateSelect = useCallback((selectedID) => {
    Promise.resolve(onAdd(selectedID)).then(() => {
      toggleModal();
      setModalData([]);
    });
  }, [onAdd, toggleModal]);

  const handleSearch = useCallback((userInput, searchType) => (
    MerchantService.search(userInput, searchType, authState)
      .then((merchants) => {
        const filteredMerchants = merchants.filter((merchant) => (
          !_.find(branches, merchant) && merchant.merchantID !== merchantID
        ));
        setModalData(filteredMerchants);
      })
  ), [authState, branches, merchantID]);

  return (
    <>
      <Typography variant="h5" component="h1" className={styles.merchantTitle}>
        Branches
        <Fab color="primary" className={styles.addButton} onClick={toggleModal} id="addNewMerchant">
          <AddIcon />
        </Fab>
      </Typography>
      <Table
        metadata={config.franchise.table}
        data={branches}
        onSelect={onSelect}
        actionProps={{ onDelete: () => {} }}
      />
      <ModalWithContent
        isOpen={isModalOpen}
        onClose={toggleModal}
      >
        <SearchComponent
          data={modalData}
          metadata={config.merchantSearch}
          onSearch={handleSearch}
          onSelect={handleCandiateSelect}
        />
      </ModalWithContent>
    </>
  );
}

BranchComponent.propTypes = {
  branches: PropTypes.arrayOf({}).isRequired,
  onAdd: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  merchantID: PropTypes.string.isRequired,
};

export default React.memo(BranchComponent);
