import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Typography from '@mui/material/Typography';
import SelectField from '../SelectField/SelectField';
import MetadataComponent from '../MetadataComponent/MetadataComponent';
import Button from '../Button/Button';
import styles from './GenerateDocuments.module.scss';
import { getFormattedDate } from '../../util/dateUtils';

function GenerateDocuments(props) {
  const {
    onClick, defaultFieldData, availableValues, name,
  } = props;
  const defaultValue = availableValues[0].code;
  const [documentValue, setDocumentValue] = useState({
    ...(defaultFieldData[defaultValue] || {}),
    selectedDocument: defaultValue,
  });

  const handleChange = useCallback((value, path) => {
    if (path === 'selectedDocument') {
      setDocumentValue({
        ...(defaultFieldData[value] || {}),
        selectedDocument: value,
      });
    } else {
      const clonedDocumentValue = _.cloneDeep(documentValue);
      _.set(clonedDocumentValue, path, path === 'file' ? value[0] : value);
      setDocumentValue((previousValues) => ({
        ...previousValues,
        ...clonedDocumentValue,
      }));
    }
  }, [defaultFieldData, documentValue]);

  const selectedValue = useMemo(() => (
    availableValues.find((value) => value.code === documentValue.selectedDocument)
  ), [availableValues, documentValue.selectedDocument]);

  const isValid = useMemo(() => {
    const fields = selectedValue?.config?.fields || [];
    return (
      _.isEmpty(fields)
      || fields.some((field) => _.get(documentValue, field.path) !== undefined)
    );
  }, [documentValue, selectedValue]);

  const handleClick = useCallback(() => {
    if (isValid) {
      const { extension } = selectedValue.config;
      const filename = name
        ? `${name} ${getFormattedDate()} ${selectedValue.label}.${extension}`
        : `${selectedValue.label} ${getFormattedDate()}.${extension}`;

      if (onClick) {
        onClick(documentValue, selectedValue, filename);
      }
    }
  }, [documentValue, isValid, name, onClick, selectedValue]);

  return (
    <div className={styles.content}>
      <Typography variant="h5" component="h1">
        Generate Documents
      </Typography>
      <div className={styles.fieldContainer}>
        <MetadataComponent
          metadata={selectedValue?.config?.fields}
          onValueChange={handleChange}
          value={documentValue}
        />
        <div className={styles.dropdownContainer}>
          <SelectField
            label="Select a document"
            className={styles.selectADocument}
            availableValues={availableValues}
            onValueChange={handleChange}
            value={documentValue.selectedDocument}
            path="selectedDocument"
            id="selectDocument"
          />
          <Button
            color="primary"
            onClick={handleClick}
            disabled={!isValid}
            id="generateDocumentButton"
          >
            Generate
          </Button>
        </div>
      </div>
    </div>
  );
}

GenerateDocuments.propTypes = {
  onClick: PropTypes.func,
  name: PropTypes.string,
  defaultFieldData: PropTypes.shape({}),
  availableValues: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
};

GenerateDocuments.defaultProps = {
  name: undefined,
  defaultFieldData: {},
  onClick: _.noop,
  availableValues: [],
};

export default GenerateDocuments;
