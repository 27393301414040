import React, { useCallback } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { Grid, Box, Typography } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import Autocomplete, { AutocompleteType } from '../Autocomplete/Autocomplete';
import { getAddressDisplayName } from '../AddressComponent/AddressComponent';
import { AvailableValue } from '../../model/AvailableValues';
import { Grower } from '../../model/Grower';
import { GrowerService } from '../../services/Grower';

function GrowerListComponet(props: React.HTMLAttributes<HTMLLIElement>, { label }: AvailableValue) {
  const [name, address] = label.split(' | ');

  return (
    <li {...props}>
      <Grid container alignItems="center">
        <Grid item sx={{ display: 'flex', width: 44 }}>
          <PersonIcon sx={{ color: 'text.secondary' }} />
        </Grid>
        <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
          <Box component="span">
            {name}
          </Box>
          <Typography variant="body2" color="text.secondary">
            {address}
          </Typography>
        </Grid>
      </Grid>
    </li>
  );
}

type GrowerAutocompleteTypes = Omit<AutocompleteType, 'onLoadData'>;

export default function GrowerAutocomplete(props: GrowerAutocompleteTypes) {
  const { onValueChange, ...rest } = props;
  const { authState } = useOktaAuth();

  const handleGrowerSearch = useCallback((searchQuery: string): Promise<AvailableValue[]> => (
    GrowerService.search(searchQuery, 'SURNAME', authState)
      .then((data) => data.map((aGrower: Grower) => ({
        label: `${aGrower.firstname} ${aGrower.surname} | ${getAddressDisplayName(aGrower.address)}`,
        code: aGrower.igasNumber,
      })))
  ), [authState]);

  return (
    <Autocomplete
      {...rest}
      onLoadData={handleGrowerSearch}
      renderOption={GrowerListComponet}
      onValueChange={onValueChange}
    />
  );
}
