import React, { useCallback, useMemo, useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useOktaAuth } from '@okta/okta-react';
import { useMerchantContacts } from '../../../../../hooks';
import { MerchantAuditService } from '../../../../../services/Merchant';
import {
  InputField,
  SelectField,
  Button,
  CheckboxField,
} from '../../../../../components';
import FormUtil from '../../../../../util/formUtil';
import styles from './MerchantInfoFields.module.scss';

const AUITOR_REQUIRED_FIELDS = [
  'isISO',
  'storageCapacity',
  'offSiteCapacity',
  'wheatIntake',
  'barleyIntake',
  'oatsIntake',
  'beansIntake',
  'oilseedRapeIntake',
  'ryeIntake',
  'currentWheatStock',
  'currentBarleyStock',
  'currentOatsStock',
  'currentBeansStock',
  'currentOilseedRapeStock',
  'currentRyeStock',
  'volumeAcidTreated',
  'aerated',
  'volumeUsedInternally',
  'volumeTraded',
  'merchantContactID',
];

function MerchantInfoFields({
  data,
  onValueChange,
  readOnly,
  onRemoveStoredValue,
}) {
  const { data: contacts, isLoading } = useMerchantContacts(data?.merchantID, 'QUALITY');
  const { authState } = useOktaAuth();
  const [isWaiting, setWaitingState] = useState(false);

  const handleChange = useCallback((value, path) => {
    onValueChange({ ...data, [path]: value });
  }, [data, onValueChange]);

  const handleSubmit = useCallback(() => {
    setWaitingState(true);
    if (data?.merchantAuditID) {
      MerchantAuditService.update(data, authState)
        .then(onRemoveStoredValue)
        .finally(() => setWaitingState(false));
    } else {
      MerchantAuditService.create(data, authState)
        .then(onValueChange)
        .then(onRemoveStoredValue)
        .finally(() => setWaitingState(false));
    }
  }, [authState, data, onValueChange, onRemoveStoredValue]);

  const merchantContacts = useMemo(() => (
    contacts?.map((contact) => ({
      label: `${contact.firstname} ${contact.surname}`,
      code: contact.merchantContactID,
    })) || []
  ), [contacts]);

  if (isLoading || _.isEmpty(data)) {
    return <span>Loading...</span>;
  }

  return (
    <div className={styles.itemsContainer}>
      <div className={styles.itemsInnerContainer}>
        <CheckboxField
          id="isISOField"
          label="Is ISO compliant?"
          value={data?.isISO}
          className={styles.items}
          onValueChange={handleChange}
          path="isISO"
          disabled={readOnly}
        />
      </div>
      <div className={styles.itemsInnerContainer}>
        <InputField
          id="storageCapacityField"
          label="Owned Storage Capacity"
          value={data?.storageCapacity}
          className={styles.items}
          onValueChange={handleChange}
          type="number"
          path="storageCapacity"
          disabled={readOnly}
        />
        <InputField
          id="offSiteCapacityField"
          label="Rented Storage Capacity"
          value={data?.offSiteCapacity}
          className={styles.items}
          onValueChange={handleChange}
          type="number"
          path="offSiteCapacity"
          disabled={readOnly}
        />
      </div>
      <div className={styles.itemsInnerContainer}>
        <InputField
          id="wheatIntakeField"
          label="Wheat Intake"
          value={data?.wheatIntake}
          className={styles.moreItems}
          onValueChange={handleChange}
          type="number"
          path="wheatIntake"
          disabled={readOnly}
        />
        <InputField
          id="barleyIntakeField"
          label="Barley Intake"
          value={data?.barleyIntake}
          className={styles.moreItems}
          onValueChange={handleChange}
          type="number"
          path="barleyIntake"
          disabled={readOnly}
        />
        <InputField
          id="oatsIntakeField"
          label="Oats Intake"
          value={data?.oatsIntake}
          className={styles.moreItems}
          onValueChange={handleChange}
          type="number"
          path="oatsIntake"
          disabled={readOnly}
        />
        <InputField
          id="beansIntakeField"
          label="Beans Intake"
          value={data?.beansIntake}
          className={styles.moreItems}
          onValueChange={handleChange}
          type="number"
          path="beansIntake"
          disabled={readOnly}
        />
        <InputField
          id="oilseedRapeIntakeField"
          label="Oilseed Rape Intake"
          value={data?.oilseedRapeIntake}
          className={styles.moreItems}
          onValueChange={handleChange}
          type="number"
          path="oilseedRapeIntake"
          disabled={readOnly}
        />
        <InputField
          id="ryeIntakeField"
          label="Rye Intake"
          value={data?.ryeIntake}
          className={styles.moreItems}
          onValueChange={handleChange}
          type="number"
          path="ryeIntake"
          disabled={readOnly}
        />
      </div>
      <div className={styles.itemsInnerContainer}>
        <InputField
          id="currentWheatStockField"
          label="Current Wheat Stock"
          value={data?.currentWheatStock}
          className={styles.moreItems}
          onValueChange={handleChange}
          type="number"
          path="currentWheatStock"
          disabled={readOnly}
        />
        <InputField
          id="currentBarleyStockField"
          label="Current Barley Stock"
          value={data?.currentBarleyStock}
          className={styles.moreItems}
          onValueChange={handleChange}
          type="number"
          path="currentBarleyStock"
          disabled={readOnly}
        />
        <InputField
          id="currentOatsStockField"
          label="Current Oats Stock"
          value={data?.currentOatsStock}
          className={styles.moreItems}
          onValueChange={handleChange}
          type="number"
          path="currentOatsStock"
          disabled={readOnly}
        />
        <InputField
          id="currentBeansStockField"
          label="Current Beans Stock"
          value={data?.currentBeansStock}
          className={styles.moreItems}
          onValueChange={handleChange}
          type="number"
          path="currentBeansStock"
          disabled={readOnly}
        />
        <InputField
          id="currentOilseedRapeStockField"
          label="Current Oilseed Rape Stock"
          value={data?.currentOilseedRapeStock}
          className={styles.moreItems}
          onValueChange={handleChange}
          type="number"
          path="currentOilseedRapeStock"
          disabled={readOnly}
        />
        <InputField
          id="currentRyeStockField"
          label="Current Rye Stock"
          value={data?.currentRyeStock}
          className={styles.moreItems}
          onValueChange={handleChange}
          type="number"
          path="currentRyeStock"
          disabled={readOnly}
        />
      </div>
      <div className={styles.itemsInnerContainer}>
        <InputField
          id="volumeAcidTreatedField"
          label="Volume Acid Treated"
          value={data?.volumeAcidTreated}
          className={styles.items}
          onValueChange={handleChange}
          type="number"
          path="volumeAcidTreated"
          disabled={readOnly}
        />
        <InputField
          id="aeratedField"
          label="Aerated"
          value={data?.aerated}
          className={styles.items}
          onValueChange={handleChange}
          type="number"
          path="aerated"
          disabled={readOnly}
        />
      </div>
      <div className={styles.itemsInnerContainer}>
        <InputField
          id="volumeUsedInternallyField"
          label="Volume Used Internally"
          value={data?.volumeUsedInternally}
          className={styles.items}
          onValueChange={handleChange}
          type="number"
          path="volumeUsedInternally"
          disabled={readOnly}
        />
        <InputField
          id="volumeTradedField"
          label="Volume Traded"
          value={data?.volumeTraded}
          className={styles.items}
          onValueChange={handleChange}
          type="number"
          path="volumeTraded"
          disabled={readOnly}
        />
      </div>
      <div className={styles.itemsInnerContainer}>
        <SelectField
          label="Select Contact"
          className={styles.items}
          availableValues={merchantContacts}
          onValueChange={handleChange}
          value={data?.merchantContactID.includes('null') ? '' : data?.merchantContactID}
          path="merchantContactID"
          id="selectContact"
          disabled={readOnly}
        />
      </div>
      {!readOnly ? (
        <div className={styles.buttonContainer}>
          <Button
            id="saveButton"
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={FormUtil.isSaveDisabled(data, AUITOR_REQUIRED_FIELDS) || isWaiting || data?.merchantContactID.includes('null')}
          >
            {data?.merchantAuditID ? 'Update' : 'Save'}
          </Button>
        </div>
      ) : null}
    </div>
  );
}

MerchantInfoFields.propTypes = {
  data: PropTypes.shape({
    merchantAuditID: PropTypes.number,
    isISO: PropTypes.bool,
    storageCapacity: PropTypes.number,
    offSiteCapacity: PropTypes.number,
    wheatIntake: PropTypes.number,
    barleyIntake: PropTypes.number,
    oatsIntake: PropTypes.number,
    beansIntake: PropTypes.number,
    oilseedRapeIntake: PropTypes.number,
    ryeIntake: PropTypes.number,
    currentWheatStock: PropTypes.number,
    currentBarleyStock: PropTypes.number,
    currentOatsStock: PropTypes.number,
    currentBeansStock: PropTypes.number,
    currentOilseedRapeStock: PropTypes.number,
    currentRyeStock: PropTypes.number,
    volumeAcidTreated: PropTypes.number,
    aerated: PropTypes.number,
    volumeUsedInternally: PropTypes.number,
    volumeTraded: PropTypes.number,
    merchantContactID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    merchantID: PropTypes.number,
  }),
  onValueChange: PropTypes.func.isRequired,
  onRemoveStoredValue: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
};

MerchantInfoFields.defaultProps = {
  data: undefined,
  readOnly: false,
};

export default MerchantInfoFields;
