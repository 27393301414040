import { AuthState } from '@okta/okta-auth-js';
import { BaseService, DocumentService } from '../TransportService';
import { Auditor, AuditorTotal } from '../../model/Auditor';
import { GrowerType } from '../../model/Grower';

const SERVICE_NAME = 'auditor';

export default {
  create(auditor: Auditor, auth: AuthState) {
    return BaseService.post(SERVICE_NAME, auditor, auth);
  },
  get(auth: AuthState): Promise<Auditor[]> {
    return BaseService.get<Auditor[]>(SERVICE_NAME, { auth });
  },
  update(auditor: Auditor, auth: AuthState) {
    return BaseService.put(SERVICE_NAME, auditor, auth);
  },
  getActive(auth: AuthState, auditType?: GrowerType): Promise<Auditor[]> {
    const params: { auditType?: string } = {};
    if (auditType) {
      params.auditType = auditType;
    }
    return BaseService.get<Auditor[]>(`${SERVICE_NAME}/getActive`, { params, auth });
  },
  getRunningTotals(auth: AuthState): Promise<AuditorTotal[]> {
    return BaseService.get<AuditorTotal[]>(`${SERVICE_NAME}/getRunningTotals`, { auth });
  },
  getTotalsForHarvestYear(auth: AuthState) {
    return BaseService.get(`${SERVICE_NAME}/getTotalsForHarvestYear`, { auth });
  },

  getAuditListForAuditors(filename: string, auth: AuthState) {
    return DocumentService.get('Auditors/getAuditListForAuditors', {}, filename, auth);
  },
  getAuditNotificationForLetterAuditors(filename: string, auth: AuthState) {
    return DocumentService.get('Auditors/getAuditNotificationForLetterAuditors', {}, filename, auth);
  },
};
