import React, { useCallback, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import { useMaintainedState } from '../../contexts/MaintainedState/MaintainedStateContext';
import { GrowerService } from '../../services/Grower';
import GrowerSearch from '../../components/SearchComponent';
import config from '../../config.json';

const GROWER_SEARCH_MS_PATH = 'growerSearch';

function GrowerSearchPage() {
  const navigate = useNavigate();
  const [growers, setGrowers] = useState([]);
  const { setMaintainedState, getMaintainedState } = useMaintainedState();
  const previousSearchResults = getMaintainedState(GROWER_SEARCH_MS_PATH);
  const { authState } = useOktaAuth();

  useEffect(() => {
    if (previousSearchResults) {
      setGrowers(previousSearchResults.growers);
    }
  // Only run once
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = useCallback((userInput, searchType) => (
    GrowerService.search(userInput, searchType, authState)
      .then((growerResponse) => {
        setGrowers(growerResponse);
        const growerObject = { growers: growerResponse };
        setMaintainedState(growerObject, GROWER_SEARCH_MS_PATH);
      })
  ), [authState, setMaintainedState]);

  const handleSelect = useCallback((selectedID, selectedRowIndex) => {
    previousSearchResults.selectedGrower = selectedRowIndex;
    setMaintainedState(previousSearchResults, GROWER_SEARCH_MS_PATH);
    navigate('/growerDetail', {
      state: {
        igasNumber: selectedID,
      },
    });
  }, [navigate, previousSearchResults, setMaintainedState]);

  return (
    <GrowerSearch
      metadata={config.growerSearch}
      data={growers}
      onSearch={handleSearch}
      onSelect={handleSelect}
      selectedRowIndex={previousSearchResults?.selectedGrower}
    />
  );
}

export default GrowerSearchPage;
