/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import InputField from '../InputField/InputField';
import SelectField from '../SelectField/SelectField';
import styles from './ModalComponent.module.scss';

function PaperComponent(props) {
  return <Paper {...props} className={styles.modal} />;
}

function ModalComponent(props) {
  const [value, setValue] = useState(null);
  const {
    title,
    description,
    continueLabel,
    cancelLabel,
    isOpen,
    onClose,
    onSubmit,
    hasInput,
    inputProps,
    hasSelect,
    selectProps,
  } = props;

  const handleSubmit = useCallback((event) => {
    if (event) {
      event.preventDefault();
    }
    if (hasInput || hasSelect) {
      onSubmit(value);
      setValue(null);
    }
    onSubmit();
  }, [hasInput, hasSelect, onSubmit, value]);

  const inputComponent = useMemo(() => (
    hasInput ? (
      <InputField
        value={value}
        onValueChange={(changedValue) => setValue(changedValue)}
        fullWidth
        autoFocus
        {...inputProps}
      />
    ) : null
  ), [hasInput, inputProps, value]);

  const selectComponent = useMemo(() => (
    hasSelect ? (
      <SelectField
        value={value}
        onValueChange={(changedValue) => setValue(changedValue)}
        fullWidth
        autoFocus
        className={styles.fullWidth}
        {...selectProps}
      />
    ) : null
  ), [hasSelect, selectProps, value]);

  return (
    <Dialog
      open={isOpen}
      className={styles.modalContainer}
      onClose={onClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {description}
          </DialogContentText>
          {inputComponent}
          {selectComponent}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={onClose} color="primary">
            {cancelLabel}
          </Button>
          <Button type="submit" color="primary">
            {continueLabel}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

ModalComponent.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  cancelLabel: PropTypes.string,
  continueLabel: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  hasInput: PropTypes.bool,
  hasSelect: PropTypes.bool,
  inputProps: PropTypes.shape({
    defaultValue: PropTypes.string,
  }),
  selectProps: PropTypes.shape({
    availableValues: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        code: PropTypes.string,
      }),
    ),
  }),
};

ModalComponent.defaultProps = {
  title: undefined,
  description: undefined,
  cancelLabel: 'Cancel',
  continueLabel: 'Ok',
  isOpen: false,
  onClose: _.noop,
  onSubmit: _.noop,
  hasInput: false,
  inputProps: {
    defaultValue: '',
  },
  hasSelect: false,
  selectProps: {},
};

export default ModalComponent;
