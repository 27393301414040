import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useOktaAuth } from '@okta/okta-react';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { MerchantService } from '../../../services/Merchant';
import { GrowerMerchantService } from '../../../services/Grower';
import { useMaintainedState } from '../../../contexts/MaintainedState/MaintainedStateContext';
import MerchantSearch from '../../../components/SearchComponent';
import { PageLoadingComponent, ModalWithContent } from '../../../components';
import { GrowerMerchantInfo } from '../../../growerComponents';
import WizardButtons from '../components/WizardButtons/WizardButtons';
import config from '../../../config.json';
import styles from './AddMerchantsToGrower.module.scss';

const ADD_MERCHANT_MS_PATH = 'addMerchant';

const findMerchant = (choosenMerchants, merchantID) => (
  choosenMerchants.find(
    ({ merchant }) => merchant.merchantID === merchantID,
  )
);

function AddMerchantsToGrower(props) {
  const {
    wizardData, onNext, onCancel,
  } = props;
  const { authState } = useOktaAuth();
  const [choosenMerchants, setChoosenMerchants] = useState([]);
  const [merchants, setMerchants] = useState([]);
  const [isLoading, setLoadingState] = useState(false);
  const [isModalOpen, setModalState] = useState(false);
  const { setMaintainedState, getMaintainedState } = useMaintainedState();

  useEffect(() => {
    const previouslyAddedMerchants = getMaintainedState(ADD_MERCHANT_MS_PATH);
    setMerchants(previouslyAddedMerchants);
    setLoadingState(true);

    if (wizardData?.igasNumber) {
      GrowerMerchantService.get(wizardData.igasNumber, authState).then((currentMerchants) => {
        setChoosenMerchants(currentMerchants);
        setLoadingState(false);
      });
    }
  }, [getMaintainedState, wizardData.igasNumber, authState]);

  const handleSearch = useCallback((userInput, searchType) => (
    MerchantService.search(userInput, searchType, authState)
      .then((response) => {
        setMerchants(response);
      })
  ), [authState]);

  const toggleModal = useCallback(() => {
    setModalState(!isModalOpen);
    if (!isModalOpen) {
      const previouslyAddedMerchants = getMaintainedState(ADD_MERCHANT_MS_PATH);
      setMerchants(previouslyAddedMerchants);
    } else {
      setMerchants([]);
    }
  }, [getMaintainedState, isModalOpen]);

  const handleMerchantSelect = useCallback((selectedID) => {
    const selectedMerchant = findMerchant(choosenMerchants, selectedID);

    if (selectedMerchant) {
      toggleModal();
      return;
    }

    setLoadingState(true);
    const igasNumber = wizardData?.igasNumber;
    GrowerMerchantService.create(igasNumber, selectedID, false, authState)
      .then((selectedGrowerMerchant) => {
        // maintain the state
        const merchantState = getMaintainedState(ADD_MERCHANT_MS_PATH) || [];
        const newMerchantState = _.uniqBy([selectedGrowerMerchant.merchant, ...merchantState], 'merchantID');
        setMaintainedState(newMerchantState, ADD_MERCHANT_MS_PATH);

        setChoosenMerchants((previousMerchants) => [...previousMerchants, selectedGrowerMerchant]);

        toggleModal();
        setLoadingState(false);
      });
  }, [
    choosenMerchants,
    getMaintainedState,
    setMaintainedState,
    authState,
    toggleModal,
    wizardData,
  ]);

  const deleteGrowersMerchant = useCallback(({ growerMerchantID }) => {
    setLoadingState(true);
    GrowerMerchantService.delete(growerMerchantID, authState).then(() => {
      setLoadingState(false);
      setChoosenMerchants((previousMerchants) => (
        previousMerchants.filter(({ growerMerchantID: id }) => (
          id !== growerMerchantID
        ))
      ));
    });
  }, [authState]);

  const merchantInfo = useMemo(() => {
    if (!choosenMerchants) {
      return null;
    }

    return choosenMerchants.map((info) => (
      <GrowerMerchantInfo
        info={info}
        deleteGrowersMerchant={deleteGrowersMerchant}
        key={info.merchant.merchantID}
      />
    ));
  }, [choosenMerchants, deleteGrowersMerchant]);

  return (
    <div>
      <PageLoadingComponent isLoading={isLoading} />
      <Typography variant="h5" component="h1" className={styles.merchantTitle}>
        Merchant Info
        <Fab color="primary" className={styles.addButton} onClick={toggleModal} id="addNewMerchant">
          <AddIcon />
        </Fab>
      </Typography>
      {merchantInfo || <div className={styles.noMerchants}>No Merchants Added</div>}
      <WizardButtons
        nextLabel="Add Merchants"
        onNext={onNext}
        onCancel={onCancel}
      />
      <ModalWithContent
        isOpen={isModalOpen}
        onClose={toggleModal}
      >
        <MerchantSearch
          data={merchants}
          metadata={config.merchantSearch}
          onSearch={handleSearch}
          onSelect={handleMerchantSelect}
        />
      </ModalWithContent>
    </div>
  );
}

AddMerchantsToGrower.propTypes = {
  metadata: PropTypes.shape({}),
  wizardData: PropTypes.shape({
    igasNumber: PropTypes.string,
  }),
  onNext: PropTypes.func,
  onCancel: PropTypes.func,
};

AddMerchantsToGrower.defaultProps = {
  metadata: undefined,
  wizardData: undefined,
  onNext: undefined,
  onCancel: undefined,
};

export default AddMerchantsToGrower;
