import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ErrorBoundry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
    };
  }

  componentDidMount() {
    if (!window.onunhandledrejection) {
      window.onunhandledrejection = (event) => {
        event.preventDefault();
        this.handleError(event.reason);
      };
    }
  }

  componentDidUpdate() {
    const { error, renderError } = this.state;

    if (error === null && renderError) {
      // ESlint doesn't like setting state in componentDidUpdate
      // incase of infinite loops, all good here, its in an if statement
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ renderError: null });
    }
  }

  componentDidCatch(previousProps, previousState) {
    const { error } = this.state;

    if (error !== previousState.error) {
      this.handleError(error);
    }
  }

  handleError = (error) => {
    const { onError } = this.props;

    Promise.resolve(onError(error)).then((renderError) => {
      this.setState({ renderError, error: null });
    });
  };

  render() {
    const { children } = this.props;
    const { renderError } = this.state;

    return (
      <div>
        {renderError || null}
        {children}
      </div>
    );
  }
}

ErrorBoundry.getDerivedStateFromError = (error) => ({ error });

ErrorBoundry.propTypes = {
  onError: PropTypes.func.isRequired,
  children: PropTypes.node,
};

ErrorBoundry.defaultProps = {
  children: undefined,
};

export default ErrorBoundry;
