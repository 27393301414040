import { AuthState } from '@okta/okta-auth-js';

export interface Response {
  status: number;
  statusText: string;
}

const VALID_STATUS = [200, 201, 204];

function objToQueryString(obj: Record<string, string | number | boolean>) {
  const keyValuePairs = Object.entries(obj)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`);

  return keyValuePairs.join('&');
}

function getUrl({ endpoint, params }: {
  endpoint: string;
  params?: Record<string, string | number | boolean>;
}) {
  const url = `/api/${endpoint}`;

  if (!params) {
    return url;
  }

  return `${url}?${objToQueryString(params)}`;
}

function getHeaders(auth: AuthState = {}): Headers {
  const requestHeaders = new Headers();
  requestHeaders.set('Content-Type', 'application/json');
  requestHeaders.set('Accept', 'application/json,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');

  if (auth.isAuthenticated) {
    requestHeaders.set('Authorization', `Bearer ${auth.accessToken?.accessToken}`);
  }

  return requestHeaders;
}

function checkStatus(response: Response) {
  if (!VALID_STATUS.includes(response.status)) {
    return Promise.reject(response.statusText);
  }
  return Promise.resolve(response);
}

export {
  getUrl,
  getHeaders,
  checkStatus,
};
