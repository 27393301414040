import * as z from 'zod';

export const ZStorageLocationDetails = z.object({
  active: z.boolean(),
  isDropOff: z.boolean(),
  lastActive: z.string().nullish(),
  merchantID: z.number().optional().or(z.string()),
  linkedTo: z.number().nullish(),
  storageCapacity: z.number(),
  storageLocationDetailsID: z.number().nullish(),
});

export const ZStorageLocation = ZStorageLocationDetails.extend({
  siteName: z.string(),
  storageLocationID: z.number().nullish(),
});

export const ZGrowerLocation = ZStorageLocationDetails.extend({
  igasNumber: z.number().optional().or(z.string()),
});

export type StorageLocation = z.infer<typeof ZStorageLocation>;
export type GrowerLocation = z.infer<typeof ZGrowerLocation>;
export type StorageLocationDetails = z.infer<typeof ZStorageLocationDetails>;
