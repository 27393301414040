import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '../../../../components';
import styles from './WizardButtons.module.scss';

function WizardButtons(props) {
  const {
    nextLabel,
    cancelLabel,
    onCancel,
    onNext,
    onFinish,
    disableNext,
    disableCancel,
  } = props;

  return (
    <div className={styles.buttonsContainer}>
      {onCancel ? (
        <Button
          id="cancel"
          onClick={onCancel}
          disabled={disableCancel}
        >
          {cancelLabel}
        </Button>
      ) : null}
      <Button
        id="next"
        color="primary"
        variant="contained"
        onClick={onNext || onFinish}
        disabled={disableNext}
      >
        {nextLabel}
      </Button>
    </div>
  );
}

WizardButtons.propTypes = {
  nextLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  onCancel: PropTypes.func,
  onNext: PropTypes.func,
  onFinish: PropTypes.func,
  disableNext: PropTypes.bool,
  disableCancel: PropTypes.bool,
};

WizardButtons.defaultProps = {
  nextLabel: undefined,
  cancelLabel: 'Save and Exit',
  onCancel: undefined,
  onNext: undefined,
  onFinish: undefined,
  disableNext: false,
  disableCancel: false,
};

export default WizardButtons;
