import React, {
  useState,
  useCallback,
} from 'react';
import { useOktaAuth } from '@okta/okta-react';
import Typography from '@mui/material/Typography';
import { Divider, Stack } from '@mui/material';
import { CheckboxField, Button, PageLoadingComponent } from '../../../components';
import { InvoiceMerchantService } from '../../../services/Invoice';

type MerchantInvoiceTypes = {
  merchantID: string;
};

const MerchantInvoices: React.FC<MerchantInvoiceTypes> = (props) => {
  const { merchantID } = props;
  const [shouldSave, setShouldSave] = useState(true);
  const { authState } = useOktaAuth();
  const [isLoading, setLoadingState] = useState(false);

  const handleGenerateInvoice = useCallback(() => {
    setLoadingState(true);
    return InvoiceMerchantService.generateOne(merchantID, shouldSave, authState).finally(() => {
      setShouldSave(false);
    });
  }, [merchantID, shouldSave, authState]);

  return (
    <PageLoadingComponent isLoading={isLoading}>
      <Stack direction="column" justifyContent="center" width="100%" p={1} divider={<Divider orientation="vertical" flexItem />}>
        <Typography variant="h5" component="h1">
          Invoice
        </Typography>
        <CheckboxField
          id="shouldSaveInvoice"
          label="Should save Invoice"
          onValueChange={(value: boolean) => setShouldSave(value)}
          value={shouldSave}
        />
        <Button
          variant="contained"
          color="primary"
          id="generateInvoice"
          onClick={handleGenerateInvoice}
        >
          Generate
        </Button>
      </Stack>
    </PageLoadingComponent>
  );
};

export default MerchantInvoices;
