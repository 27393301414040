import { AuthState } from '@okta/okta-auth-js';

const getGroups = (auth: AuthState): string[] => (auth?.accessToken?.claims as any)?.groups;

const hasAccess = (requiredAccess: string | string[], auth: AuthState): boolean => {
  const accessArray = Array.isArray(requiredAccess) ? requiredAccess : [requiredAccess];
  const userGroups = getGroups(auth);
  return accessArray.some((access) => userGroups?.includes(access));
};

const getOktaID = (auth: AuthState): string => (auth?.accessToken?.claims as any)?.uid;

const getGroup = (auth: AuthState): string | undefined => {
  const groups = getGroups(auth);
  return groups?.find((group: string) => group !== 'Everyone');
};

export default {
  getGroups,
  hasAccess,
  getOktaID,
  getGroup,
};
