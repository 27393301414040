import React, { useState, useCallback } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import { Button } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import {
  Input,
  Select,
  Checkbox,
  ModalWithContent,
  PageLoadingComponent,
} from '../../../../components';
import { AuditQuestionService } from '../../../../services/Audit';
import { AuditQuestion, ZAuditQuestion, ZAuditQuestionSeverity } from '../../../../model/Audit';
import styles from '../../AuditQuestionsPage.module.scss';
import config from '../../../../config.json';

export const DEFAULT_QUESTION: Partial<AuditQuestion> = {
  questionType: 'YESNONA',
  activeQuestion: true,
  defaultAnswer: 'Yes',
  severity: 'RECOMMENDED',
};

type NewAuditQuestionTypes = {
  question?: AuditQuestion | Partial<AuditQuestion>;
  onClose: () => void,
  onSubmit: () => void,
};

function NewAuditQuestion({ question, onClose, onSubmit: onSuccess }: NewAuditQuestionTypes) {
  const methods = useForm<AuditQuestion>({
    mode: 'onChange',
    resolver: zodResolver(ZAuditQuestion),
    defaultValues: question,
  });
  const {
    control, getValues, handleSubmit, formState: { isValid },
  } = methods;
  const [isLoading, setLoadingState] = useState<boolean>(false);
  const { authState } = useOktaAuth();

  const onSubmit: SubmitHandler<AuditQuestion> = useCallback((data: AuditQuestion) => {
    let promise: Promise<any>;
    setLoadingState(true);
    if (question?.questionID) {
      promise = AuditQuestionService.update(data, authState!);
    } else {
      promise = AuditQuestionService.create(data, authState!);
    }
    return promise.finally(() => {
      setLoadingState(false);
      onSuccess();
    });
  }, [authState, onSuccess, question?.questionID]);

  return (
    <ModalWithContent
      modalClassName={styles.modal}
      contentClassName={styles.modalContent}
      isOpen
      onClose={onClose}
    >
      <PageLoadingComponent isLoading={isLoading}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Typography variant="h5" component="h1">Audit Question</Typography>
            <List>
              <ListItem>
                <Input label="Question Code" required name="questionCode" control={control} className={styles.items} />
              </ListItem>
              <ListItem>
                <Input label="Question" required name="questionText" multiline control={control} className={styles.items} />
              </ListItem>
              <ListItem>
                <Select label="Question Severity" required name="severity" control={control} className={styles.items} availableValues={config.typelist.severity} />
              </ListItem>
              <ListItem>
                <Select label="Question Type" required name="questionType" control={control} className={styles.items} availableValues={config.typelist.questionType} />
              </ListItem>
              <ListItem>
                <Input label="Category" required name="category" control={control} className={styles.items} />
              </ListItem>
              <ListItem>
                <Input label="Section Title" name="sectionTitle" control={control} className={styles.items} />
              </ListItem>
              <ListItem>
                <Input label="Default Answer" name="defaultAnswer" control={control} className={styles.items} />
              </ListItem>
              <ListItem>
                <Select label="Audit Type" required name="auditType" control={control} className={styles.items} availableValues={config.typelist.auditType} />
              </ListItem>
              <ListItem>
                <Select label="Fix By" name="fixBy" control={control} className={styles.items} availableValues={config.typelist.fixBy} required={getValues('severity') !== ZAuditQuestionSeverity.Enum.NONE} />
              </ListItem>
              <ListItem>
                <Checkbox name="activeQuestion" required label="Active" control={control} className={styles.items} />
              </ListItem>
            </List>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              id="saveButton"
              disabled={!isValid}
            >
              {question?.questionID ? 'Update Question' : 'Add Question'}
            </Button>
          </form>
        </FormProvider>
      </PageLoadingComponent>
    </ModalWithContent>
  );
}

NewAuditQuestion.defaultProps = {
  question: DEFAULT_QUESTION,
};

export default NewAuditQuestion;
