import { useQuery, UseQueryResult } from 'react-query';
import { useOktaAuth } from '@okta/okta-react';
import { AuditService, AuditQuestionService } from '../../services/Audit';
import CACHE_QUERIES from './cacheQueries.constant';
import { AuditQuestion, AuditType } from '../../model/Audit';

export const useAuditYear = () => {
  const { authState } = useOktaAuth();

  return useQuery([CACHE_QUERIES.AUDIT_YEAR], () => AuditService.getYear(authState!));
};

export const useAuditYears = (): UseQueryResult<{ harvestYear: number }> => {
  const { authState } = useOktaAuth();

  return useQuery(
    [CACHE_QUERIES.PREVIOUS_AUDIT_YEAR],
    () => AuditService.getAuditYears(authState!),
  );
};

export const useAuditQuestions = (
  type: AuditType,
  active = true,
  automaticallyFetch = true,
): UseQueryResult<AuditQuestion[]> => {
  const { authState } = useOktaAuth();

  return useQuery(
    [CACHE_QUERIES.AUDIT_QUESTION, type, CACHE_QUERIES.IS_ACTIVE, active],
    () => AuditQuestionService.get(type, active, authState!),
    { refetchOnWindowFocus: automaticallyFetch, enabled: automaticallyFetch },
  );
};
