import React, { HTMLInputTypeAttribute, useCallback, useEffect } from 'react';
import classNames from 'classnames';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import _ from 'lodash';
import { InputLabelClassKey } from '@mui/material/InputLabel';
import { ClassNameMap } from '@mui/styles/withStyles';

export type ValueType = string | number | undefined;
export type ValueChangeType = (value: ValueType, path?: string) => void;

type InputFieldPropTypes = TextFieldProps & {
  name?: string,
  path?: string,
  label: string,
  value?: ValueType,
  onValueChange?: ValueChangeType,
  disabled?: boolean,
  className?: string,
  inputFieldClass?: string,
  labelClassName?: Partial<ClassNameMap<InputLabelClassKey>>,
  type?: HTMLInputTypeAttribute,
  autoFocus?: boolean,
  defaultValue?: ValueType,
  required?: boolean,
};

function InputField(props: InputFieldPropTypes) {
  const {
    type = 'text',
    path,
    value,
    onValueChange,
    className,
    inputFieldClass,
    labelClassName,
    defaultValue,
    ...rest
  } = props;

  useEffect(() => {
    if (defaultValue !== undefined && onValueChange) {
      onValueChange(defaultValue, path);
    }
  // Disabling to stop re-renders
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue, path]);

  const handleChange: (event: any) => void = useCallback((event: any) => {
    const newValue = event.target.value;

    if (onValueChange) {
      if (type === 'number') {
        const parsedValue = _.isEmpty(newValue) || _.isNil(newValue)
          ? undefined : _.toInteger(newValue);
        onValueChange(parsedValue, path);
      } else {
        onValueChange(newValue, path);
      }
    }
  }, [onValueChange, path, type]);

  return (
    <TextField
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      className={classNames(inputFieldClass, className)}
      variant="standard"
      onChange={rest.onChange || handleChange}
      value={value ?? ''}
      type={type}
      InputLabelProps={{
        classes: labelClassName,
      }}
    />
  );
}

InputField.defaultProps = {
  name: undefined,
  path: undefined,
  disabled: false,
  className: undefined,
  inputFieldClass: undefined,
  labelClassName: undefined,
  value: undefined,
  onValueChange: undefined,
  type: 'text',
  autoFocus: false,
  defaultValue: undefined,
  required: false,
};

export default React.memo(InputField);
