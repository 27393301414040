import React, { useState, useCallback } from 'react';
import classNames from 'classnames';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import styles from '../NoteComponent.module.scss';
import { Note, ZNoteType } from '../../../model/Note';

type NoteSectionType = {
  note: Note,
  path: string,
  onDeleteNote: (path: string, note: Note) => void,
};

function NoteSection(props: NoteSectionType) {
  const {
    note,
    path,
    onDeleteNote,
  } = props;
  const [anchorEl, setAnchorEl] = useState();

  const toggleMenu = useCallback((event?: any) => {
    if (anchorEl || !event) {
      setAnchorEl(undefined);
    } else {
      setAnchorEl(event.currentTarget);
    }
  }, [anchorEl]);

  const handleDeleteNote = useCallback(() => {
    toggleMenu();
    onDeleteNote(path, note);
  }, [note, onDeleteNote, path, toggleMenu]);

  return (
    <React.Fragment key={note.content}>
      <ListItem>
        <ListItemText
          inset={!note.createdDate}
          primary={note.content}
          secondary={note.createdDate}
          className={classNames(styles.note, 'noteContent')}
        />
        {note.noteType !== ZNoteType.Enum.AUTOMATED ? (
          <>
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={toggleMenu}
              className="noteMoreInfoButton"
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="long-menu"
              anchorEl={anchorEl}
              keepMounted
              open={!!anchorEl}
              onClose={toggleMenu}
            >
              <MenuItem onClick={handleDeleteNote}>
                Delete
              </MenuItem>
            </Menu>
          </>
        ) : null}
      </ListItem>
      <Divider />
    </React.Fragment>
  );
}

export default React.memo(NoteSection);
