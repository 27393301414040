import { BaseService } from '../TransportService';

const SERVICE_NAME = 'grower';

export default {
  create(growerDTO, auth) {
    return BaseService.post(SERVICE_NAME, growerDTO, auth);
  },
  get(igasNumber, auth) {
    const params = { igasNumber };
    return BaseService.get(SERVICE_NAME, { params, auth });
  },
  update(growerDTO, auth) {
    return BaseService.put(SERVICE_NAME, growerDTO, auth);
  },

  search(grower, searchType, auth) {
    const params = { searchType, query: grower };
    return BaseService.get(`${SERVICE_NAME}/search`, { params, auth });
  },
};
