import React from 'react';
import { useFormContext, FieldErrorsImpl } from 'react-hook-form';

type ErrorMessage = Partial<FieldErrorsImpl<{ [x: string]: any; }>>;

function findErrorMessage(errors: ErrorMessage): string[] | string {
  return errors!.message! as unknown as string
    || Object.values(errors).flatMap((error: any): string | string[] => {
      if (Array.isArray(error)) {
        return error.flatMap(findErrorMessage);
      }
      return error!.message! as string;
    });
}

function ValidationErrors() {
  const { formState: { errors } } = useFormContext();

  const errorMessages = React.useMemo(() => findErrorMessage(errors) as string[], [errors]);

  return (
    <>
      {
        (errorMessages || []).map(
          // eslint-disable-next-line react/no-array-index-key
          (message, index) => <p key={`auditError${index}`} style={{ color: 'red' }}>{message}</p>,
        )
      }
    </>
  );
}

export default React.memo(ValidationErrors);
