import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import {
  ModalWithContent,
  InputField,
  Button,
  SelectField,
  CheckboxField,
} from '../../../../../components';
import config from '../../../../../config.json';
import FormUtil from '../../../../../util/formUtil';
import styles from './MerchantContactFields.module.scss';
import useUserAccess from '../../../../../hooks/useUserAccess/useUserAccess';

const REQUIRED_AUDITOR_FIELDS = ['firstname', 'surname', 'email'];
const REQUIRED_FIELDS = [...REQUIRED_AUDITOR_FIELDS, 'companyRole'];

function MerchantContactFields(props) {
  const { contact, updateContact, onSave } = props;

  const { isAdmin } = useUserAccess();

  const isEdititing = useMemo(() => !!contact?.merchantContactID, [contact]);

  const handleClose = useCallback(() => {
    updateContact();
  }, [updateContact]);

  const handleChange = useCallback((value, path) => {
    updateContact({
      ...contact,
      [path]: value,
    });
  }, [updateContact, contact]);

  return !contact ? null : (
    <ModalWithContent
      isOpen
      onClose={handleClose}
      modalClassName={styles.merchantModal}
      contentClassName={styles.modalContent}
    >
      <Typography variant="h5" component="h1" className={styles.title}>
        {isEdititing ? 'Edit ' : 'Add '}
        Merchant Contact
      </Typography>
      <ListItem>
        <InputField label="Firstname" value={contact?.firstname} className={styles.items} onValueChange={handleChange} path="firstname" id="firstname" required />
      </ListItem>
      <ListItem>
        <InputField label="Surname" value={contact?.surname} className={styles.items} onValueChange={handleChange} path="surname" id="surname" required />
      </ListItem>
      <ListItem>
        <InputField label="Phone Number" value={contact?.phoneNumber} className={styles.items} onValueChange={handleChange} id="phoneNumber" path="phoneNumber" />
      </ListItem>
      <ListItem>
        <InputField label="Landline" value={contact?.landlineNumber} className={styles.items} onValueChange={handleChange} id="landline" path="landlineNumber" />
      </ListItem>
      <ListItem>
        <InputField label="Email" value={contact?.email} className={styles.items} onValueChange={handleChange} id="email" path="email" required />
      </ListItem>
      {isAdmin ? (
        <ListItem>
          <SelectField id="companyRole" label="Role" value={contact?.companyRole} className={styles.items} onValueChange={handleChange} path="companyRole" availableValues={config.typelist.merchantContactTypes} required />
        </ListItem>
      ) : null}
      <ListItem>
        <CheckboxField id="activeField" label="Active" value={contact?.active} className={styles.items} onValueChange={handleChange} path="active" required />
      </ListItem>
      <Button
        variant="contained"
        color="primary"
        type="submit"
        id="save"
        onClick={onSave}
        disabled={FormUtil.isSaveDisabled(contact, isAdmin
          ? REQUIRED_FIELDS : REQUIRED_AUDITOR_FIELDS)}
      >
        {isEdititing ? 'Update ' : 'Create '}
        Contact
      </Button>
    </ModalWithContent>
  );
}

MerchantContactFields.propTypes = {
  contact: PropTypes.shape({
    merchantContactID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    firstname: PropTypes.string,
    surname: PropTypes.string,
    phoneNumber: PropTypes.string,
    landlineNumber: PropTypes.string,
    companyRole: PropTypes.string,
    email: PropTypes.string,
    active: PropTypes.bool,
  }),
  onSave: PropTypes.func.isRequired,
  updateContact: PropTypes.func.isRequired,
};

MerchantContactFields.defaultProps = {
  contact: undefined,
};

export default MerchantContactFields;
