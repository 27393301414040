/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Typography, Button } from '@mui/material';
import styles from './NotAuditable.module.scss';

function NotAuditable({ onClick }: { onClick: () => void }) {
  return (
    <div className={styles.container}>
      <Typography variant="body1" component="p">
        This grower isn't currently scheduled to be audited.
      </Typography>
      <Typography variant="body1" component="p">
        You can still do an unscheduled audit.
      </Typography>
      <Button color="primary" onClick={onClick} id="createUnscheduledAudit">
        Create Audit
      </Button>
    </div>
  );
}

export default NotAuditable;
