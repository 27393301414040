import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useOktaAuth } from '@okta/okta-react';
import Typography from '@mui/material/Typography';
import { GrowerNoteService, GrowerDocumentService } from '../../../services/Grower';
import { GenerateDocuments, NotesComponent, PageLoadingComponent } from '../../../components';
import WizardButtons from '../components/WizardButtons/WizardButtons';
import config from '../../../config.json';

function AddNotesToGrower({ wizardData, onFinish }) {
  const [notes, updateNotes] = useState([]);
  const { authState } = useOktaAuth();
  const [isLoading, setLoadingState] = useState(false);

  const getNotes = useCallback(() => {
    setLoadingState(true);
    GrowerNoteService.get(wizardData.igasNumber, authState)
      .then(updateNotes)
      .finally(() => setLoadingState(false));
  }, [authState, wizardData.igasNumber]);

  useEffect(() => {
    getNotes();
  // only execute once
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNewNote = useCallback((newNote) => {
    const note = {
      ...newNote,
      igasNumber: wizardData.igasNumber,
    };
    setLoadingState(true);
    return GrowerNoteService.create(note, authState)
      .then(getNotes);
  }, [authState, getNotes, wizardData.igasNumber]);

  const handleNoteDelete = useCallback((newNotes, noteToDelete) => {
    setLoadingState(true);
    return GrowerNoteService.delete(noteToDelete.noteID, authState).then(() => {
      updateNotes((previousNotes) => (
        previousNotes.filter(
          ({ noteID }) => noteID !== noteToDelete.noteID,
        )
      ));
      setLoadingState(false);
    });
  }, [authState]);

  const handleGenerateDocument = useCallback((valuesForDocument) => {
    setLoadingState(true);
    const igasNumber = wizardData?.igasNumber;
    const documentPromise = valuesForDocument === 'growerFormalLetter'
      ? GrowerDocumentService.generateFormalLetter(igasNumber, 'GrowerFormalLetter.docx', authState)
      : GrowerDocumentService.generateRegistrationLetter(igasNumber, 'GrowerRegistrationLetter.docx', authState);

    return documentPromise.finally(() => setLoadingState(false));
  }, [authState, wizardData]);

  return (
    <div>
      <PageLoadingComponent isLoading={isLoading} />
      <Typography variant="h5" component="h1">
        Notes
      </Typography>
      <NotesComponent
        notes={notes}
        onNewNote={handleNewNote}
        onDeleteNote={handleNoteDelete}
      />
      <GenerateDocuments
        onClick={handleGenerateDocument}
        availableValues={config?.typelist?.generateGrowerLetters}
        name={wizardData?.igasNumber}
      />
      <WizardButtons
        nextLabel="Finish adding Grower"
        onFinish={onFinish}
      />
    </div>
  );
}

AddNotesToGrower.propTypes = {
  metadata: PropTypes.shape({}),
  wizardData: PropTypes.shape({
    igasNumber: PropTypes.string,
  }),
  onFinish: PropTypes.func,
};

AddNotesToGrower.defaultProps = {
  metadata: undefined,
  wizardData: undefined,
  onFinish: undefined,
};

export default AddNotesToGrower;
