import React, {
  useState,
  useRef,
  useMemo,
  useCallback,
  useContext,
} from 'react';
import Modal from '../ModalComponent';

export interface ModalObjectTypes {
  title: string;
  description: string;
  cancelLabel?: string;
  continueLabel?: string;
  hasInput?: boolean,
  hasSelect?: boolean,
  catchOnCancel?: boolean,
  inputProps?: {
    defaultValue: string,
    label?: string,
  },
  selectProps?: {
    availableValues: {
      label: string,
      code: string,
    }[],
  },
}

export interface ModalContextTypes {
  openModal: (options: ModalObjectTypes) => Promise<any>;
}

const ModalContext = React.createContext<ModalContextTypes | null>(null);

export const ModalProvider: React.FC<{ children: React.ReactElement }> = ({ children }) => {
  const [modalState, updateModalState] = useState<ModalObjectTypes | undefined>();
  const awaitingPromiseRef = useRef<{
    resolve:(value: string | undefined) => void;
    reject: (reason?: any) => void;
  } | undefined>();

  const handleOpenModal = useCallback((options: ModalObjectTypes) => {
    updateModalState(options);
    return new Promise((resolve, reject) => {
      awaitingPromiseRef.current = {
        resolve,
        reject,
      };
    });
  }, []);

  const handleClose = useCallback(() => {
    if (modalState?.catchOnCancel && awaitingPromiseRef.current) {
      awaitingPromiseRef.current.reject();
    }

    updateModalState(undefined);
  }, [modalState]);

  const handleSubmit = useCallback((valueToReturn: string | undefined) => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve(valueToReturn);
    }

    updateModalState(undefined);
  }, []);

  const ModalService = useMemo(() => ({
    openModal: handleOpenModal,
  }), [handleOpenModal]);

  return (
    <>
      <ModalContext.Provider value={ModalService}>
        {children}
      </ModalContext.Provider>
      <Modal
        onSubmit={handleSubmit}
        onClose={handleClose}
        isOpen={!!modalState}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...modalState}
      />
    </>
  );
};

export const useModal = () => useContext(ModalContext);
