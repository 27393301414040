import React, {
  useCallback, useState, useEffect,
} from 'react';
import { useOktaAuth } from '@okta/okta-react';
import Done from '@mui/icons-material/Done';
import Clear from '@mui/icons-material/Clear';
import { Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { GrowerAutocomplete, PageLoadingComponent } from '../../../../../components';
import { GrowerService } from '../../../../../services/Grower';
import styles from './GrowerFields.module.scss';
import config from '../../../../../config.json';
import { Grower } from '../../../../../model/Grower';
import { ZGrowerLocation, GrowerLocation } from '../../../../../model/Merchant';
import { AvailableValue } from '../../../../../model/AvailableValues';
import { StorageLocationService } from '../../../../../services/StorageLocation';
import StorageLocationDetails, { StorageLocationDetailsProps } from '../StorageLocationDetails/StorageLocationDetails';

type GrowerFieldsTypes = Pick<StorageLocationDetailsProps, 'linkableLocations'> & {
  onClose: () => void,
  location: Partial<GrowerLocation>,
};

function GrowerFields({ onClose, linkableLocations, location }: GrowerFieldsTypes) {
  const [grower, setGrower] = useState<Grower | undefined>();
  const [isLoading, setLoadingState] = useState<boolean>(false);
  const { authState } = useOktaAuth();
  const methods = useForm<GrowerLocation>({
    mode: 'onBlur',
    defaultValues: location,
    resolver: zodResolver(ZGrowerLocation),
  });
  const { watch, setValue } = methods;
  const igasNumber = watch('igasNumber');

  const handleCheckGrower = useCallback((searchValue: string) => {
    setLoadingState(true);

    GrowerService.get(searchValue, authState)
      .then(setGrower)
      // eslint-disable-next-line no-console
      .catch((e) => console.log(e))
      .finally(() => setLoadingState(false));
  }, [authState]);

  useEffect(() => {
    if (igasNumber) {
      handleCheckGrower(igasNumber as string);
    }
  }, [handleCheckGrower, igasNumber]);

  const handleSubmit: SubmitHandler<GrowerLocation> = useCallback((data) => {
    setLoadingState(true);
    if (!data?.storageLocationDetailsID) {
      StorageLocationService.createGrower(data, authState!)
        .finally(onClose)
        .finally(() => setLoadingState(false));
    } else {
      StorageLocationService.update(data, authState!)
        .finally(onClose)
        .finally(() => setLoadingState(false));
    }
  }, [authState, onClose]);

  return (
    <FormProvider {...methods}>
      <PageLoadingComponent isLoading={isLoading} />
      <form>
        <div className={styles.nameContainer}>
          {grower ? (
            <div className={styles.grower} id="growerDetails">
              <Typography variant="body2" color="text.secondary">
                Grower details
              </Typography>
              <div className={styles.growerDetails}>
                <span>{`${grower.igasNumber} ${grower.firstname} ${grower.surname}`}</span>
                <span>
                  {config.typelist.growerType.find((type) => (
                    type.code === grower.growerType
                  ))?.label}
                </span>
                <Tooltip title="Is grower active?" arrow placement="top">
                  {grower.active ? <Done color="primary" /> : <Clear color="error" />}
                </Tooltip>
              </div>
            </div>
          ) : (
            <GrowerAutocomplete
              onValueChange={(value?: AvailableValue | null) => {
                setValue('igasNumber', value?.code as string, { shouldValidate: true });
              }}
              label="Select a Grower"
              noOptionsText="No growers"
              name="surname"
            />
          )}
        </div>
        <StorageLocationDetails linkableLocations={linkableLocations} onSubmit={handleSubmit} />
      </form>
    </FormProvider>
  );
}

export default GrowerFields;
