import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { useOktaAuth } from '@okta/okta-react';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { StorageLocationService } from '../../../services/StorageLocation';
import { Table, PageLoadingComponent } from '../../../components';
import { LocationDetails } from './components';
import { StorageLocation, GrowerLocation } from '../../../model/Merchant';
import config from '../../../config.json';
import styles from './StorageLocationDetails.module.scss';

function StorageLocationDetails(props: { merchantID: number }) {
  const { merchantID } = props;
  const [storageLocations, setStorageLocations] = useState<StorageLocation[]>([]);
  const [growerLocations, setGrowerLocations] = useState<GrowerLocation[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<
  StorageLocation | GrowerLocation | undefined
  >();
  const { authState } = useOktaAuth();
  const [isLoading, setLoadingState] = useState<boolean>(true);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleGetLocations = useCallback(() => {
    setLoadingState(true);
    Promise.all([
      StorageLocationService.get(merchantID, authState!, false),
      StorageLocationService.getGrowers(merchantID, authState!),
    ]).then(([locations, growers]) => {
      setStorageLocations(locations);
      setGrowerLocations(growers);
    }).finally(() => setLoadingState(false));
  }, [authState, merchantID]);

  useEffect(() => {
    if (!selectedLocation) {
      handleGetLocations();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelect = useCallback((selectedID: number) => {
    const location = [...storageLocations, ...growerLocations].find(
      ({ storageLocationDetailsID }) => storageLocationDetailsID === selectedID,
    );
    if (location) {
      setSelectedLocation(location);
      setOpen(true);
    }
  }, [growerLocations, storageLocations]);

  const handleModalToggle = useCallback(() => {
    setOpen(!isOpen);
    if (isOpen) {
      handleGetLocations();
    }
    if (selectedLocation) {
      setSelectedLocation(undefined);
    }
  }, [isOpen, handleGetLocations, selectedLocation]);

  const linkableStorageLocations = useMemo(() => (
    storageLocations.filter((location) => !location.isDropOff || !location.linkedTo)
  ), [storageLocations]);

  return (
    <>
      <PageLoadingComponent isLoading={isLoading} />
      <Typography variant="h5" component="h1" className={styles.container}>
        Storage Locations
        <Fab color="primary" className={styles.addButton} onClick={handleModalToggle} id="addNewStorageLocation">
          <AddIcon />
        </Fab>
      </Typography>
      <Table
        metadata={config.storageLocations.table}
        data={storageLocations}
        onSelect={handleSelect}
      />
      <Typography variant="h5" component="h1" className={styles.container}>
        Grower Locations
      </Typography>
      <Table
        metadata={config.growerLocations.table}
        data={growerLocations}
        onSelect={handleSelect}
      />
      {isOpen && (
        <LocationDetails
          location={selectedLocation}
          linkableLocations={linkableStorageLocations}
          onClose={handleModalToggle}
          isOpen={isOpen}
        />
      )}
    </>
  );
}

export default StorageLocationDetails;
