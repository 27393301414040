import React, { useMemo, useEffect, useState } from 'react';
import { useLocation, Outlet, useSearchParams } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import NotesIcon from '@mui/icons-material/Notes';
import DescriptionIcon from '@mui/icons-material/Description';
import SmsIcon from '@mui/icons-material/Sms';
import MuseumIcon from '@mui/icons-material/Museum';
import { useOktaAuth } from '@okta/okta-react';
import { TabSet } from '../../components';
import { Grower } from '../../model/Grower';
import { GrowerService } from '../../services/Grower';
import GrowerDetails from './GrowerDetails/GrowerDetails';
import GrowerMerchantDetails from './GrowerMerchantDetails/GrowerMerchantDetails';
import GrowerMerchantStores from './GrowerMerchantStores/GrowerMerchantStores';
import GrowerAudit from './GrowerAudit/GrowerAudit';
import GrowerDocuments from './GrowerDocuments/GrowerDocuments';
import GrowerContact from './GrowerContact/GrowerContact';
import GrowerNotes from './GrowerNotes/GrowerNotes';
import AuthUtil from '../../util/authUtil';

type StateType = {
  igasNumber: string;
};

function GrowerDetailsPage() {
  const { state } = useLocation();
  const { authState } = useOktaAuth();
  const [searchParams] = useSearchParams();
  const [grower, setGrowerData] = useState<Grower | undefined>();

  useEffect(() => {
    if ((state as StateType)?.igasNumber || searchParams.get('igasNumber')) {
      GrowerService.get((state as StateType)?.igasNumber || searchParams.get('igasNumber'), authState).then(setGrowerData);
    }
  }, [authState, searchParams, state]);

  const TABS = useMemo(
    () => [
      {
        icon: <AccountCircleIcon />,
        label: 'Details',
        content: <GrowerDetails igasNumber={(state as StateType)?.igasNumber || searchParams.get('igasNumber')!} />,
        access: ['Admin', 'Auditor', 'GrowerAuditEnterer'],
        state: ['igasNumber'],
        id: 'details',
      },
      {
        icon: <BusinessCenterIcon />,
        label: 'Merchants',
        content: <GrowerMerchantDetails igasNumber={(state as StateType)?.igasNumber || searchParams.get('igasNumber')!} />,
        access: ['Admin', 'Auditor'],
        state: ['igasNumber'],
        id: 'merchants',
      },
      {
        icon: <MuseumIcon />,
        label: 'Stores',
        content: <GrowerMerchantStores igasNumber={(state as StateType)?.igasNumber || searchParams.get('igasNumber')!} />,
        access: ['Admin', 'Auditor'],
        state: ['igasNumber'],
        id: 'stores',
      },
      {
        icon: <VerifiedUserIcon />,
        label: 'Audit',
        content: <GrowerAudit grower={grower} />,
        access: ['Admin', 'Auditor', 'GrowerAuditEnterer'],
        state: ['igasNumber', 'auditID'],
        id: 'audit',
      },
      {
        icon: <NotesIcon />,
        label: 'Notes',
        content: <GrowerNotes igasNumber={(state as StateType)?.igasNumber || searchParams.get('igasNumber')!} />,
        access: ['Admin', 'Auditor'],
        state: ['igasNumber'],
        id: 'notes',
      },
      {
        icon: <DescriptionIcon />,
        label: 'Document',
        content: <GrowerDocuments igasNumber={(state as StateType)?.igasNumber || searchParams.get('igasNumber')!} />,
        access: ['Admin', 'Auditor'],
        state: ['igasNumber'],
        id: 'documents',
      },
      {
        icon: <SmsIcon />,
        label: 'Contact',
        content: <GrowerContact igasNumber={(state as StateType)?.igasNumber || searchParams.get('igasNumber')!} />,
        access: ['Admin', 'Auditor'],
        state: ['igasNumber'],
        id: 'contact',
      },
    ].filter((tab) => AuthUtil.hasAccess(tab?.access, authState!)),
    [state, searchParams, grower, authState],
  );

  return (
    <>
      <TabSet tabs={TABS} rootPath="/growerDetail" />
      <Outlet />
    </>
  );
}

export default GrowerDetailsPage;
