import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import {
  AuditQuestionsSection,
  AuditNotes,
  useAuditData,
} from '../../auditComponents';
import {
  Select,
  Date,
  Input,
  InlineLoading,
} from '../../components';
import { useMerchantContacts } from '../../hooks';
import styles from './StorageLocationAudit.module.scss';

function StorageLocationAuditForm() {
  const { state } = useLocation();
  const { isAuditReadOnly } = useAuditData();
  const { data: contacts, isLoading } = useMerchantContacts(state?.merchantAudit?.merchantID, 'QUALITY');

  const merchantContacts = useMemo(() => (
    contacts?.map((contact) => ({
      label: `${contact.firstname} ${contact.surname}`,
      code: contact.merchantContactID!,
    })) || []
  ), [contacts]);

  return (
    <>
      <div className={styles.itemsContainer}>
        <div className={styles.itemsInnerContainer}>
          <InlineLoading isLoading={isLoading}>
            <Select
              label="Select Contact"
              className={styles.items}
              name="merchantContactID"
              availableValues={merchantContacts}
              id="selectContact"
              disabled={isAuditReadOnly}
            />
          </InlineLoading>
          <Date
            id="auditDateField"
            label="Audit Date"
            className={styles.items}
            name="auditDate"
            disabled={isAuditReadOnly}
          />
          <Input
            id="currentStock"
            label="Current Stock"
            className={styles.items}
            type="number"
            name="currentStock"
            disabled={isAuditReadOnly}
          />
          <Input
            id="intake"
            label="Intake"
            className={styles.items}
            type="number"
            name="intake"
            disabled={isAuditReadOnly}
          />
        </div>
      </div>
      <AuditQuestionsSection names={['questions']} readOnly={isAuditReadOnly} />
      <AuditNotes />
    </>
  );
}

export default React.memo(StorageLocationAuditForm);
