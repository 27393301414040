import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Input from '@mui/material/Input';
import InputField from '../InputField/InputField';

function SelectField(props) {
  const {
    labelClassName,
    availableValues,
    defaultValue,
    className,
    dataType,
    onValueChange,
    disabled,
    helpText,
    required,
    label,
    value,
    path,
    name,
    id,
    ...rest
  } = props;

  const handleChange = useCallback((event) => {
    const changedValue = event.target.value;

    if (_.isNil(changedValue)) {
      return;
    }

    const object = availableValues.find((option) => option.code === changedValue);

    if (dataType === 'object') {
      onValueChange(object, path);
    } else {
      onValueChange(object.code, path);
    }
  }, [availableValues, dataType, onValueChange, path]);

  const options = useMemo(() => {
    const availableValueOptions = [{ code: undefined, label: 'Please Select' }, ...availableValues].map((option) => (
      <MenuItem
        value={option.code}
        key={`${id}_${option.code}`}
      >
        {option.label}
      </MenuItem>
    ));

    return availableValueOptions;
  }, [id, availableValues]);

  if (disabled) {
    const chosenAV = availableValues.find((availableValue) => availableValue.code === value);
    return (
      <InputField
        label={label}
        id={id}
        value={chosenAV ? chosenAV.label : null}
        className={className}
        disabled
      />
    );
  }

  return (
    <FormControl className={className} required={required}>
      <InputLabel htmlFor={name} classes={labelClassName}>{label}</InputLabel>
      <Select
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
        id={id}
        value={value}
        defaultValue={defaultValue}
        onChange={rest.onChange || handleChange}
        input={<Input />}
        inputProps={{
          name,
        }}
      >
        {options}
      </Select>
      {helpText && <FormHelperText>{helpText}</FormHelperText>}
    </FormControl>
  );
}

SelectField.propTypes = {
  ...Select.propTypes,
  availableValues: PropTypes.arrayOf(PropTypes.shape({})),
  labelClassName: PropTypes.shape({}),
  className: PropTypes.string,
  dataType: PropTypes.string,
  onValueChange: PropTypes.func,
  required: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  path: PropTypes.string,
  id: PropTypes.string,
};

SelectField.defaultProps = {
  ...Select.defaultProps,
  availableValues: [],
  dataType: 'string',
  className: '',
  required: false,
  onValueChange: _.noop,
  labelClassName: undefined,
  label: undefined,
  value: '',
  name: undefined,
  path: undefined,
};

export default React.memo(SelectField);
