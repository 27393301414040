import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { useFormContext } from 'react-hook-form';
import {
  InlineLoading,
  Input,
  Select,
  Date,
} from '../../../../../components';
import { AuditorService } from '../../../../../services/Auditor';
import AuthUtil from '../../../../../util/authUtil';
import styles from './AuditInfoFields.module.scss';
import { Auditor } from '../../../../../model/Auditor';
import { AvailableValuesType } from '../../../../../components/Select/Select';

type AuditInfoFieldsTypes = {
  readOnly?: boolean;
};

function AuditInfoFields({ readOnly = false }: AuditInfoFieldsTypes) {
  const [auditors, setAuditors] = useState<Auditor[]>();
  const { authState } = useOktaAuth();
  const { getValues } = useFormContext();

  const auditType = getValues('auditType');

  const isAuditor = useMemo(() => AuthUtil.hasAccess('Auditor', authState!), [authState]);

  useEffect(() => {
    if (!auditors && !isAuditor) {
      AuditorService.getActive(authState!).then(setAuditors);
    }
  }, [auditors, authState, isAuditor]);

  const availableAuditors: AvailableValuesType[] | undefined = useMemo(() => (
    auditors?.map((auditor) => ({
      label: `${auditor.firstname} ${auditor.surname}`,
      code: auditor.auditorID!,
    })) || []
  ), [auditors]);

  return (
    <InlineLoading isLoading={(!auditors || auditors.length === 0) && !isAuditor}>
      <>
        <div className={styles.itemsContainer}>
          <Input
            id="applicationNumberField"
            label="Application Number"
            className={styles.items}
            type="number"
            name="applicationNumber"
            disabled={readOnly}
          />
          {auditType === 'PRODUCTIONSTORAGE' ? (
            <Input
              id="tonnageField"
              label="Tonnage"
              className={styles.items}
              type="number"
              name="tonnage"
              required
              disabled={readOnly}
            />
          ) : null}
          {isAuditor ? null : (
            <>
              <Select
                id="auditorField"
                label="Auditor"
                className={styles.items}
                availableValues={availableAuditors}
                name="auditorID"
                disabled={readOnly}
              />
              <Input
                id="harvestYearField"
                label="Harvest Year"
                className={styles.items}
                type="number"
                name="harvestYear"
                disabled={readOnly}
              />
            </>
          )}
          <Date
            id="auditDateField"
            label="Audit Date"
            className={styles.items}
            name="auditDate"
            disabled={readOnly}
          />
        </div>
        <div className={styles.itemsContainer}>
          <Input
            id="barleyField"
            label="Barley"
            className={styles.items}
            type="number"
            name="barley"
            required
            helperText="Number of Hectares grown"
            disabled={readOnly}
          />
          <Input
            id="wheatField"
            label="Wheat"
            className={styles.items}
            type="number"
            name="wheat"
            required
            helperText="Number of Hectares grown"
            disabled={readOnly}
          />
          <Input
            id="oatsField"
            label="Oats"
            className={styles.items}
            type="number"
            name="oats"
            required
            helperText="Number of Hectares grown"
            disabled={readOnly}
          />
        </div>
        <div className={styles.itemsContainer}>
          <Input
            id="ryeField"
            label="Rye"
            className={styles.items}
            type="number"
            name="rye"
            required
            helperText="Number of Hectares grown"
            disabled={readOnly}
          />
          <Input
            id="oilseedRapeField"
            label="Oilseed rape"
            className={styles.items}
            type="number"
            name="oilseedRape"
            required
            helperText="Number of Hectares grown"
            disabled={readOnly}
          />
          <Input
            id="beansAndPeasField"
            label="Beans and peas"
            className={styles.items}
            type="number"
            name="beansAndPeas"
            required
            helperText="Number of Hectares grown"
            disabled={readOnly}
          />
        </div>
      </>
    </InlineLoading>
  );
}

export default React.memo(AuditInfoFields);
