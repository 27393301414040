import React, {
  useCallback, useState, useMemo, useEffect,
} from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {
  Routes, Route, Navigate, RouteProps,
} from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import classNames from 'classnames';
import { QueryClient, QueryClientProvider } from 'react-query';
import { LoginCallback, useOktaAuth } from '@okta/okta-react';
import Nav from '../components/NavBar';
import Menu from '../components/Menu';
import SecureRoute from '../components/SecureRoute/SecureRoute';
import withAuthContext from '../contexts/AuthContext/WithAuthContext';
import GrowerSearchPage from '../pages/GrowerSearchPage';
import MerchantSearchPage from '../pages/MerchantSearchPage';
import {
  ErrorPage,
  AuditorPage,
  AddGrowerWizard,
  AddMerchantPage,
  GrowerNumberPage,
  AuditAssignPage,
  GenerateDocumentPage,
  AuditQuestionsPage,
  GrowerDetailsPage,
  AuditorReviewPage,
  MerchantDetailsPage,
  GrowerAuditSearchPage,
  RecordedAuditPage,
  StorageLocationAudit,
  EmployeePage,
  EmployeesListPage,
  LoginPage,
  SavedAudits,
  ClearStorage,
  GrowerAuditListPage,
  MerchantAuditDraftPage,
  MerchantAuditFollowUpPage,
  AnalyticsPage,
} from '../pages';

import { ErrorBoundary } from '../components';
import AuthUtil from '../util/authUtil';

import { BreakpointContextProvider } from '../contexts/BreakpointContext/BreakpointContext';
import { BadgeContextProvider } from '../contexts/BadgeContext/BadgeContext';

import appStyles from './App.module.scss';

const handlePendo = (user: { id?: number; email?: string; full_name?: string; role?: string; }) => {
  // This function creates visitors and accounts in Pendo
  (window as any).pendo.initialize({
    visitor: user,
  });
};

const GROWER_AUDIT_ENTERER_ROUTES: RouteProps[] = [
  {
    path: '/',
    element: <GrowerNumberPage />,
  },
  {
    path: '/growerDetail/*',
    element: <GrowerDetailsPage />,
  },
];

const GROWER_AUDIT_ROUTES: RouteProps[] = [
  {
    path: '/',
    element: <GrowerAuditSearchPage />,
  },
  {
    path: '/review',
    element: <AuditorReviewPage />,
  },
  {
    path: '/growerDetail/*',
    element: <GrowerDetailsPage />,
  },
  {
    path: '/savedAudits',
    element: <SavedAudits />,
  },
  {
    path: '/draft',
    element: <GrowerAuditListPage type="draft" />,
  },
  {
    path: '/follow-up',
    element: <GrowerAuditListPage type="follow-up" />,
  },
];

const MERCHANT_AUDIT_ROUTES: RouteProps[] = [
  {
    path: '/',
    element: <MerchantSearchPage />,
  },
  {
    path: '/merchantDetail/*',
    element: <MerchantDetailsPage />,
  },
  {
    path: '/review',
    element: <AuditorReviewPage />,
  },
  {
    path: '/storageLocationAudit',
    element: <StorageLocationAudit />,
  },
  {
    path: '/clear',
    element: <ClearStorage />,
  },
  {
    path: '/draft',
    element: <MerchantAuditDraftPage />,
  },
  {
    path: '/follow-up',
    element: <MerchantAuditFollowUpPage />,
  },
];

const ADMIN_ROUTES: RouteProps[] = [
  {
    path: '/',
    element: <GrowerSearchPage />,
  },
  {
    path: '/merchantSearch',
    element: <MerchantSearchPage />,
  },
  {
    path: '/growerDetail/*',
    element: <GrowerDetailsPage />,
  },
  {
    path: '/addGrowerWizard/*',
    element: <AddGrowerWizard />,
  },
  {
    path: '/merchantDetail/*',
    element: <MerchantDetailsPage />,
  },
  {
    path: '/addMerchant',
    element: <AddMerchantPage />,
  },
  {
    path: '/auditors',
    element: <AuditorPage />,
  },
  {
    path: '/auditAssign',
    element: <AuditAssignPage />,
  },
  {
    path: '/generateDocuments',
    element: <GenerateDocumentPage />,
  },
  {
    path: '/auditQuestions',
    element: <AuditQuestionsPage />,
  },
  {
    path: '/recorded/audits',
    element: <RecordedAuditPage />,
  },
  {
    path: '/storageLocationAudit',
    element: <StorageLocationAudit />,
  },
  {
    path: '/employee',
    element: <EmployeePage />,
  },
  {
    path: '/employees',
    element: <EmployeesListPage />,
  },
  {
    path: '/analytics/*',
    element: <AnalyticsPage />,
  },
];

const DEVELOPER_ROUTES: RouteProps[] = [
  ...ADMIN_ROUTES,
];

const ROUTES: Record<string, RouteProps[]> = {
  Developer: DEVELOPER_ROUTES,
  Admin: ADMIN_ROUTES,
  Auditor: GROWER_AUDIT_ROUTES,
  MerchantAuditor: MERCHANT_AUDIT_ROUTES,
  GrowerAuditEnterer: GROWER_AUDIT_ENTERER_ROUTES,
};

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  const { authState } = useOktaAuth();
  const [isMenuOpen, setMenuState] = useState(false);

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const theme = React.useMemo(
    () => createTheme({
      palette: {
        mode: prefersDarkMode ? 'dark' : 'light',
      },
    }),
    [prefersDarkMode],
  );

  const handleMenu = useCallback(
    (newState: any) => {
      setMenuState(typeof newState === 'boolean' ? newState : !isMenuOpen);
    },
    [isMenuOpen],
  );

  useEffect(() => {
    if (authState?.isAuthenticated) {
      const pendoUserInfo = {
        id: authState.accessToken?.claims.employeeID,
        email: authState.idToken?.claims.email,
        full_name: authState.idToken?.claims.name,
        role: (authState.accessToken?.claims as any).groups[1],
      };
      handlePendo(pendoUserInfo as any);
    }
  }, [authState]);

  const handleError = useCallback(() => <Navigate to="/errorPage" />, []);

  const routes = useMemo(() => {
    if (!authState) {
      return null;
    }
    const employeeGroup = AuthUtil.getGroup(authState);
    if (!employeeGroup) {
      return null;
    }
    const groupsRoutes = ROUTES[employeeGroup];
    return groupsRoutes?.map((route: RouteProps) => (
      <Route key={route.path} {...route} />
    ));
  }, [authState]);

  return (
    <BreakpointContextProvider styles={appStyles}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BadgeContextProvider>
          <div className={appStyles.app}>
            <ErrorBoundary onError={handleError}>
              <QueryClientProvider client={queryClient}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <>
                    {authState?.isAuthenticated && (
                    <>
                      <Nav menuCallback={handleMenu} isOpen={isMenuOpen} />
                      <Menu menuCallback={handleMenu} isOpen={isMenuOpen} />
                    </>
                    )}
                    <div
                      className={classNames(appStyles.appContainer, {
                        [appStyles.appContainerMenuOpen]: isMenuOpen,
                        [appStyles.appContainerMenuClosed]: !isMenuOpen,
                      })}
                    >
                      <div className={appStyles.pageWidth}>
                        <Routes>
                          <Route
                            path="/login/callback"
                            element={<LoginCallback />}
                          />
                          <Route
                            path="/login"
                            element={<LoginPage />}
                          />
                          <Route path="/" element={<SecureRoute />}>
                            {routes}
                            <Route path="/errorPage" element={<ErrorPage />} />
                            <Route path="/*" element={<ErrorPage />} />
                          </Route>
                        </Routes>
                      </div>
                    </div>
                  </>
                </LocalizationProvider>
              </QueryClientProvider>
            </ErrorBoundary>
          </div>
        </BadgeContextProvider>
      </ThemeProvider>
    </BreakpointContextProvider>
  );
}

export default withAuthContext(App);
