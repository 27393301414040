import { AuthState } from '@okta/okta-auth-js';
import { DocumentService, BaseService } from '../TransportService';
import { AuditorReview } from '../../model/Auditor';
import { GrowerAudit } from '../../model/Audit';
import { MerchantAudit } from '../../model/Merchant';

const SERVICE_NAME = 'auditor/audits';

export default {
  getExcelList(auth: AuthState, filename: string = 'AuditList.xlsx') {
    return DocumentService.get(`${SERVICE_NAME}/getList`, {}, filename, auth);
  },
  getReview(fromDate: string, toDate: string, auth: AuthState): Promise<AuditorReview[]> {
    const params = { fromDate, toDate };
    return BaseService.get<AuditorReview[]>(`${SERVICE_NAME}/review`, { params, auth });
  },
  followUp(auth: AuthState): Promise<GrowerAudit[] | MerchantAudit[]> {
    return BaseService.get<GrowerAudit[] | MerchantAudit[]>(`${SERVICE_NAME}/followUp`, { auth });
  },
  exportReview(fromDate: string, toDate: string, auth: AuthState, filename: string = 'AuditorReview.xlsx') {
    const params = { fromDate, toDate };
    return DocumentService.get(`${SERVICE_NAME}/review/export`, params, filename, auth);
  },
};
