import * as z from 'zod';

export const ZAuditorTotal = z.object({
  active: z.boolean(),
  auditorID: z.number(),
  completedAudits: z.number(),
  deactivatedAudits: z.number(),
  draftAudits: z.number(),
  failedAudits: z.number(),
  email: z.string().optional(),
  firstname: z.string(),
  surname: z.string(),
  toBeReviewed: z.number(),
  totalAudits: z.number(),
});

export type AuditorTotal = z.infer<typeof ZAuditorTotal>;
