import React, { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  Checkbox, Button, Select, Input,
} from '../../../../../components';
import { AvailableValue } from '../../../../../model/AvailableValues';
import styles from './StorageLocationDetails.module.scss';

export type StorageLocationDetailsProps = {
  linkableLocations: AvailableValue[];
  onSubmit: (data: any) => void;
};

export default function StorageLocationDetails(
  { linkableLocations, onSubmit }: StorageLocationDetailsProps,
) {
  const { handleSubmit, setValue } = useFormContext();

  const handleClear = useCallback(() => {
    setValue('linkedTo', null, { shouldValidate: true });
  }, [setValue]);

  return (
    <div className={styles.container}>
      <Input label="Capacity" className={styles.item} name="storageCapacity" type="number" />
      <div className={styles.nameContainer}>
        <Select label="Linked to Storage Location" availableValues={linkableLocations} className={styles.item} name="linkedTo" />
        <Button
          id="clearLinkedLocation"
          variant="contained"
          color="primary"
          onClick={handleClear}
          className={styles.clear}
        >
          Clear
        </Button>
      </div>
      <Checkbox label="Active" name="active" />
      <Checkbox label="Is a drop off only location" name="isDropOff" />
      <Button
        id="save"
        variant="contained"
        color="primary"
        type="submit"
        onClick={handleSubmit(onSubmit)}
      >
        Save
      </Button>
    </div>
  );
}
