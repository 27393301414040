import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { Employee } from '../../model/Employee';
import { EmployeeService } from '../../services/Employee';
import { Table, PageLoadingComponent } from '../../components';
import config from '../../config.json';
import styles from './EmployeesListPage.module.scss';

function EmployeesListPage() {
  const [employees, setEmployees] = useState<Employee[]>([]);
  const navigate = useNavigate();
  const [isLoading, setLoadingState] = useState(true);
  const { authState } = useOktaAuth();

  useEffect(() => {
    setLoadingState(true);
    EmployeeService.getAll(authState!)
      .then((data) => setEmployees(data))
      .finally(() => setLoadingState(false));
  }, [authState]);

  const handleSelect = useCallback((selectedEmployeeID: string) => {
    navigate('/employee', { state: { employeeID: selectedEmployeeID } });
  }, [navigate]);

  const handleClick = useCallback(() => {
    navigate('/employee');
  }, [navigate]);

  return (
    <PageLoadingComponent isLoading={isLoading}>
      <Typography variant="h5" component="h1" className={styles.pageTitle}>
        Employees
        <Fab color="primary" className={styles.addButton} onClick={handleClick} id="addEmployee">
          <AddIcon />
        </Fab>
      </Typography>
      <Table
        metadata={config.employeesInfo.table}
        data={employees}
        isLoading={isLoading}
        onSelect={handleSelect}
      />
    </PageLoadingComponent>
  );
}

export default EmployeesListPage;
