import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Button from '@mui/material/Button';
import styles from './SearchComponent.module.scss';
import SearchTable from '../Table';
import InputField from '../InputField/InputField';
import SelectField from '../SelectField/SelectField';

function SearchComponent(props) {
  const {
    onSearch, metadata, data, onSelect, selectedRowIndex, shouldInitiallySearch,
  } = props;
  const [isLoading, setLoadingState] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedSearchType, setSelectedSearchType] = useState(_.first(metadata?.searchTypes));

  const handleSearch = useCallback((event) => {
    event?.preventDefault();

    setLoadingState(true);

    onSearch(searchTerm, selectedSearchType.code).then(() => {
      setLoadingState(false);
    });
  }, [onSearch, searchTerm, selectedSearchType]);

  useEffect(() => {
    if (shouldInitiallySearch) {
      handleSearch();
    }
  // Execute once
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearchTypeChange = useCallback((value) => {
    setSelectedSearchType(value);
    setSearchTerm(undefined);
  }, [setSearchTerm]);

  return (
    <div>
      <form autoComplete="off" className={styles.form} onSubmit={handleSearch}>
        {metadata.searchTypes.length <= 1 ? null : (
          <SelectField
            label="Search Type"
            name="searchType"
            dataType="object"
            id="searchType"
            onValueChange={handleSearchTypeChange}
            value={selectedSearchType.code}
            availableValues={metadata.searchTypes}
            className={styles.searchType}
          />
        )}
        <InputField
          label={selectedSearchType.placeholder}
          name="searchComponent"
          id="searchField"
          onValueChange={setSearchTerm}
          inputFieldClass={styles.inputField}
          className={styles.inputContainer}
          value={searchTerm}
          autoFocus
        />
        <div className={styles.buttonContainer}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            id="searchButton"
            onClick={handleSearch}
            className={styles.button}
          >
            Search
          </Button>
        </div>
      </form>
      <SearchTable
        metadata={metadata.table}
        data={data}
        isLoading={isLoading}
        onSelect={onSelect}
        selectedRowIndex={selectedRowIndex}
      />
    </div>
  );
}

SearchComponent.propTypes = {
  metadata: PropTypes.shape({
    table: PropTypes.shape({}),
    rowsPerPage: PropTypes.number,
    searchTypes: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  onSearch: PropTypes.func,
  data: PropTypes.arrayOf(PropTypes.shape({})),
  onSelect: PropTypes.func,
  selectedRowIndex: PropTypes.number,
  shouldInitiallySearch: PropTypes.bool,
};

SearchComponent.defaultProps = {
  metadata: undefined,
  onSearch: _.noop,
  data: [],
  onSelect: _.noop,
  selectedRowIndex: undefined,
  shouldInitiallySearch: false,
};

export default SearchComponent;
