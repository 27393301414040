import React, { useMemo } from 'react';
import { useLocation, Outlet, useSearchParams } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PeopleIcon from '@mui/icons-material/People';
import DescriptionIcon from '@mui/icons-material/Description';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import RoomIcon from '@mui/icons-material/Room';
import NotesIcon from '@mui/icons-material/Notes';
import { TabSet } from '../../components';
import MerchantDetails from './MerchantDetails/MerchantDetails';
import MerchantDocuments from './MerchantDocuments/MerchantDocuments';
import MerchantBranch from './MerchantBranch/MerchantBranch';
import MerchantContact from './MerchantContact/MerchantContact';
import MerchantNotes from './MerchantNotes/MerchantNotes';
import StorageLocationDetails from './StorageLocationDetails/StorageLocationDetails';
import MerchantInvoices from './MerchantInvoice/MerchantInvoice';
import MerchantAudit from './MerchantAudit/MerchantAudit';
import AuthUtil from '../../util/authUtil';

function MerchantDetailsPage() {
  const [searchParams] = useSearchParams();
  const { state } = useLocation();
  const { authState } = useOktaAuth();

  const TABS = useMemo(() => (
    [
      {
        icon: <AccountCircleIcon />,
        label: 'Details',
        content: <MerchantDetails merchantID={state?.merchantID || searchParams.get('merchantID')} />,
        id: 'home',
        state: ['merchantID'],
        access: ['Admin', 'MerchantAuditor'],
      },
      {
        icon: <DescriptionIcon />,
        label: 'Document',
        content: <MerchantDocuments merchantID={state?.merchantID || searchParams.get('merchantID')} />,
        id: 'documents',
        state: ['merchantID'],
        access: ['Admin'],
      },
      {
        icon: <PeopleIcon />,
        label: 'Contacts',
        content: <MerchantContact merchantID={state?.merchantID || searchParams.get('merchantID')} />,
        id: 'contacts',
        state: ['merchantID'],
        access: ['Admin', 'MerchantAuditor'],
      },
      {
        icon: <RoomIcon />,
        label: 'Storage Locations',
        content: <StorageLocationDetails merchantID={state?.merchantID || searchParams.get('merchantID')} />,
        id: 'storage-location',
        state: ['merchantID'],
        access: ['Admin', 'MerchantAuditor'],
      },
      {
        icon: <VerifiedUserIcon />,
        label: 'Audit',
        content: <MerchantAudit merchantID={state?.merchantID || searchParams.get('merchantID')} />,
        id: 'audit',
        state: ['merchantID', 'auditID'],
        access: ['Admin', 'MerchantAuditor'],
      },
      {
        icon: <LocationCityIcon />,
        label: 'Branch Info',
        content: <MerchantBranch merchantID={state?.merchantID || searchParams.get('merchantID')} />,
        id: 'branch',
        state: ['merchantID'],
        access: ['Admin'],
      },
      {
        icon: <LocationCityIcon />,
        label: 'Invoice',
        content: <MerchantInvoices merchantID={state?.merchantID || searchParams.get('merchantID')} />,
        id: 'invoice',
        state: ['merchantID'],
        access: ['Admin'],
      },
      {
        icon: <NotesIcon />,
        label: 'Notes',
        content: <MerchantNotes merchantID={state?.merchantID || searchParams.get('merchantID')} />,
        id: 'notes',
        state: ['merchantID'],
        access: ['Admin', 'MerchantAuditor'],
      },
    ].filter((tab) => AuthUtil.hasAccess(tab?.access, authState!))
  ), [authState, searchParams, state?.merchantID]);

  return (
    <>
      <TabSet tabs={TABS} rootPath="/merchantDetail" />
      <Outlet />
    </>
  );
}

export default MerchantDetailsPage;
