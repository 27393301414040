import React, { useMemo, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import { getAuditList, removeFromList, addToList } from '../../auditComponents/AuditDataProvider/util/AuditStorageUtil';
import { Table } from '../../components';
import AuthUtil from '../../util/authUtil';
import config from '../../config.json';

export default function SavedAudits() {
  const navigate = useNavigate();
  const { authState } = useOktaAuth();

  const isGrowerAuditor = useMemo(() => AuthUtil.getGroup(authState!) === 'Auditor', [authState]);
  const isMerchantAuditor = useMemo(() => AuthUtil.getGroup(authState!) === 'MerchantAuditor', [authState]);

  const list = useMemo(() => (
    getAuditList().filter((audit: any) => (
      (isGrowerAuditor && audit.igasNumber)
      || (isMerchantAuditor && audit.storageLocationID)
    )).map((item: any) => ({ ...item, uuid: uuidv4() }))
  ), [isGrowerAuditor, isMerchantAuditor]);

  const handleSelect = useCallback((selectedAudit: string) => {
    const { uuid, ...audit } = list.find((item) => item.uuid === selectedAudit);
    removeFromList(audit);
    addToList(audit);

    if (isGrowerAuditor) {
      navigate('/growerDetail/audit', {
        state: {
          igasNumber: audit.igasNumber,
        },
      });
    }
  }, [isGrowerAuditor, list, navigate]);

  return (
    <>
      {
        isGrowerAuditor ? (
          <Table
            metadata={config.storedLocalGrowerAudit.table}
            data={list || []}
            onSelect={handleSelect}
          />
        ) : null
      }
      {/* {
        isMerchantAuditor && (
          <Table
            metadata={config.storedLocalMerchantAudit.table}
            data={list || []}
            // onSelect={handleSelect}
          />
        )
      } */}
    </>
  );
}
