import React, { useCallback, useEffect } from 'react';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import { getFormattedDate, getDateFromString } from '../../util/dateUtils';

interface DateFieldTypes<TDate> extends Omit<DatePickerProps<TDate>, 'value' | 'onValueChange' | 'onChange'> {
  // eslint-disable-next-line react/require-default-props
  path?: string;
  // eslint-disable-next-line react/require-default-props
  onValueChange?: (value: string, path: string) => void,
  // eslint-disable-next-line react/require-default-props
  onChange?: (event: any) => void,
  value: Date | string | undefined,
}

function DateField(props: DateFieldTypes<Date>): React.ReactElement {
  const {
    path,
    onValueChange = undefined,
    value,
    format = 'dd MM yyyy',
  } = props;

  const handleChange = useCallback((date: any) => {
    const newDate = new Date(date);
    const formattedDate = getFormattedDate(newDate);
    if (onValueChange) {
      onValueChange(formattedDate, path!);
    }
  }, [onValueChange, path]);

  const getValue = useCallback(() => {
    if (typeof value === 'string') {
      return getDateFromString(value);
    }
    return value || null;
  }, [value]);

  useEffect(() => {
    if (value === undefined || value === '') {
      handleChange(new Date());
    }
  // Set value when first entering
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DatePicker
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      format={format}
      value={getValue()}
      // eslint-disable-next-line react/destructuring-assignment
      onChange={props.onChange || handleChange}
      slotProps={{ textField: { variant: 'standard' } }}
    />
  );
}

export default React.memo(DateField);
