import React, {
  useCallback, useMemo, useState,
} from 'react';
import {
  Routes,
  Route,
  useLocation,
  useNavigate,
  Navigate,
  useSearchParams,
} from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from './TabPanel';
import styles from './TabSet.module.scss';

function a11yProps(index: number | string): { id: string; 'aria-controls': string } {
  return {
    id: `scrollable-prevent-tab-${index}`,
    'aria-controls': `scrollable-prevent-tabpanel-${index}`,
  };
}

type TabTypes = {
  label: string;
  content: React.ReactElement;
  id: string;
  icon: React.ReactElement;
  state?: string[];
  access?: string[];
};

type TabSetTypes = {
  tabs: TabTypes[];
  rootPath?: string;
  contentClassName?: string;
  activeID?: string;
  hideTabs?: boolean;
};

function TabSet(props: TabSetTypes) {
  const [searchParams] = useSearchParams();
  const { pathname, state } = useLocation();
  const navigate = useNavigate();
  const {
    tabs, rootPath, contentClassName, activeID, hideTabs,
  } = props;
  const [value, setValue] = useState(activeID || tabs[0].id);

  const handleChange = useCallback((event: any, newValue: string) => {
    if (rootPath) {
      const selectedObj = tabs.find((tab) => `${rootPath}/${tab.id}` === newValue);
      const newState = (selectedObj?.state || [])
        .reduce((acc, path) => (
          { ...acc, [path]: (state || {})[path] || searchParams.get(path) }
        ), {});
      navigate(newValue, { state: newState });
    } else {
      setValue(newValue);
    }
  }, [navigate, rootPath, searchParams, state, tabs]);

  const content = useMemo(() => (
    tabs.filter((tab) => rootPath || tab.id === value)
      .map((tab) => {
        const Component = (
          <TabPanel key={`${tab.label}TabTitle`} value={tab.id} className={contentClassName}>
            {tab.content!}
          </TabPanel>
        );
        return rootPath ? (
          <Route key={tab.id} path={tab.id} element={Component} />
        ) : Component;
      })
  ), [contentClassName, rootPath, tabs, value]);

  if (rootPath && pathname === rootPath) {
    return <Navigate to={`${rootPath}/${tabs[0].id}`} replace state={state} />;
  }

  return (
    <>
      {!hideTabs && (
      <AppBar position="static" color="default">
        <Tabs
          value={rootPath ? pathname : value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable prevent tabs example"
        >
          {tabs.map((tab) => (
            <Tab
              key={`${tab.label}Tab`}
              label={tab.label}
              icon={tab.icon}
              aria-label={tab.label}
              value={rootPath ? `${rootPath}/${tab.id}` : tab.id}
              className={styles.tabHeader}
              {...a11yProps(tab.id)}
            />
          ))}
        </Tabs>
      </AppBar>
      )}
      {rootPath ? (
        <Routes>
          {content}
        </Routes>
      ) : content}
    </>
  );
}

export default TabSet;
