import React from 'react';
import { Input } from '../../components';
import AuditCategoryQuestions from '../AuditCategoryQuestions/AuditCategoryQuestions';
import styles from './AuditQuestionsSection.module.scss';

type AuditQuestionsSectionTypes = {
  names: string[];
  readOnly?: boolean;
  // isQuestionsLoading?: boolean;
};

function AuditQuestionsSection({ names, readOnly }: AuditQuestionsSectionTypes) {
  return (
    <>
      <Input
        label="Reference Number"
        className={styles.score}
        id="referenceNumber"
        type="number"
        required
        disabled={readOnly}
        name="score"
      />
      <div id="auditQuestionContainer">
        {
          names.map((name) => (
            <AuditCategoryQuestions
              name={name}
              key={name}
              readOnly={!!readOnly}
            />
          ))
        }
      </div>
    </>
  );
}

AuditQuestionsSection.defaultProps = {
  readOnly: false,
  // isQuestionsLoading: false,
};

export default AuditQuestionsSection;
