import React, { useCallback } from 'react';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

type StoredAuditNotificationTypes = {
  isOpen: boolean;
  onNewAudit: () => void;
  onClose: () => void;
};

function StoredAuditNotification(props: StoredAuditNotificationTypes) {
  const { isOpen = true, onNewAudit, onClose } = props;

  const handleNewAudit = useCallback(() => {
    onNewAudit();
    onClose();
  }, [onNewAudit, onClose]);

  return (
    <Snackbar open={isOpen} id="newAuditNotification">
      <Alert
        elevation={6}
        variant="filled"
        severity="success"
        action={(
          <div>
            <Button color="inherit" size="small" onClick={handleNewAudit} id="newAudit">
              New Audit
            </Button>
            <IconButton size="small" aria-label="close" color="inherit" onClick={onClose} id="closeNewAudit">
              <CloseIcon fontSize="small" />
            </IconButton>
          </div>
      )}
      >
        <span id="newAuditDescription">Retrieved an unsaved audit</span>
      </Alert>
    </Snackbar>
  );
}

export default React.memo(StoredAuditNotification);
