import React, { HTMLInputTypeAttribute, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { InputLabelClassKey } from '@mui/material/InputLabel';
import { ClassNameMap } from '@mui/styles/withStyles';
import { useController } from 'react-hook-form';

type ValueType = string | number | undefined;

type InputFieldPropTypes = TextFieldProps & {
  name: string,
  disabled?: boolean,
  className?: string,
  inputFieldClass?: string,
  onValueChange?: (event: any) => void,
  labelClassName?: Partial<ClassNameMap<InputLabelClassKey>>,
  type?: HTMLInputTypeAttribute,
  autoFocus?: boolean,
  defaultValue?: ValueType,
  required?: boolean,
  control?: any,
};

const getValue = (event: any, type?: string) => {
  if (type === 'number') {
    const isNull = event.target.valueAsNumber === undefined || event.target.valueAsNumber === null;
    return isNull ? null : event.target.valueAsNumber;
  }
  return event.target.value;
};

function InputField(props: InputFieldPropTypes) {
  const {
    type,
    name,
    control,
    className,
    inputFieldClass,
    labelClassName,
    onValueChange,
    defaultValue,
    required,
    ...rest
  } = props;

  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: { required },
    defaultValue,
  });

  const handleChange = useCallback((event: any) => {
    const newEvent = {
      ...event,
      target: {
        ...event.target,
        value: getValue(event, type),
      },
    };
    field.onChange(newEvent);
    if (onValueChange) {
      onValueChange(newEvent);
    }
    // }
  }, [field, onValueChange, type]);

  const errorMessage = useMemo(() => {
    if (error?.message === 'Expected number, received null') {
      return 'Required';
    }
    return error?.message;
  }, [error?.message]);

  return (
    <TextField
      {...field}
      {...rest}
      className={classNames(inputFieldClass, className)}
      variant="standard"
      onChange={handleChange}
      value={field.value}
      type={type}
      error={!!error}
      aria-invalid={!!error}
      required={required}
      helperText={errorMessage || rest.helperText}
      id={rest.id || name}
      inputRef={field.ref}
      InputLabelProps={{
        classes: labelClassName,
      }}
    />
  );
}

InputField.defaultProps = {
  disabled: false,
  className: undefined,
  inputFieldClass: undefined,
  labelClassName: undefined,
  type: 'text',
  control: undefined,
  autoFocus: false,
  onValueChange: undefined,
  defaultValue: undefined,
  required: false,
};

export default React.memo(InputField);
