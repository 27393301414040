import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useOktaAuth } from '@okta/okta-react';
import CircularProgress from '@mui/material/CircularProgress';
import { getFormattedDate } from '../../../util/dateUtils';
import {
  GrowerService,
  GrowerNoteService,
  GrowerAuditService,
  GrowerMerchantService,
} from '../../../services/Grower';
import { PageLoadingComponent, GrowerAuditStatus, useModal } from '../../../components';
import { GrowerInfo, SelectMerchantWithSearch, GrowerActiveChip } from '../../../growerComponents';
import styles from './GrowerDetails.module.scss';

function GrowerDetails(props) {
  const { igasNumber } = props;
  const { authState } = useOktaAuth();
  const [isPrimaryMerchantBoxShown, setIsPrimaryMerchantBoxVisibility] = useState(false);
  const [auditYears, setAuditYears] = useState([]);
  const [initialGrowerData, setInitialGrowerData] = useState({});
  const [growerData, setGrowerData] = useState({});
  const [primaryMerchant, setPrimaryMerchant] = useState({});
  const [isLoading, setLoadingState] = useState(true);
  const ModalService = useModal();

  useEffect(() => {
    if (igasNumber) {
      setLoadingState(true);
      GrowerService.get(igasNumber, authState)
        .then((response) => {
          setInitialGrowerData(response);
          setGrowerData(response);
          setLoadingState(false);

          if (response?.paymentMethod === 'MERCHANT') {
            GrowerMerchantService.getPrimary(igasNumber, authState).then(setPrimaryMerchant);
          }
        });
      GrowerAuditService.previousYears(igasNumber, authState).then(setAuditYears);
    }
  }, [authState, igasNumber]);

  const updateGrowerData = useCallback(() => {
    setLoadingState(true);
    GrowerService.update(growerData, authState).then(() => {
      setInitialGrowerData(growerData);
      setLoadingState(false);
    });
  }, [authState, growerData]);

  const handleSaveGrower = useCallback((event) => {
    event.preventDefault();
    const oldActiveStatus = initialGrowerData?.active;
    const newActiveStatus = growerData?.active;

    const growerIsCancelled = oldActiveStatus && !newActiveStatus;
    const growerIsActive = !oldActiveStatus && newActiveStatus;

    if (growerIsCancelled || growerIsActive) {
      ModalService.openModal({
        title: growerIsActive ? 'Grower Activated' : 'Grower Cancelled',
        description: growerIsActive ? 'The grower has become active, please provide a reason:' : 'The grower has become inactive, please provide a reason:',
        hasInput: true,
        inputProps: {
          defaultValue: growerIsActive ? 'Grower Activated: Ref ' : 'Cancelled Grower: Ref ',
        },
      }).then((noteContent) => {
        const newNote = {
          content: noteContent,
          createdDate: getFormattedDate(),
          igasNumber: growerData?.igasNumber,
        };
        setLoadingState(true);
        GrowerNoteService.create(newNote, authState).then(() => {
          // updateGrowerData doesn't update notes, so we don't need to save them
          updateGrowerData();
        });
      });
    } else {
      updateGrowerData();
    }
  }, [ModalService, authState, growerData, initialGrowerData, updateGrowerData]);

  const handleChange = useCallback((value, path) => {
    if (_.includes(path, 'paymentMethod') && value === 'MERCHANT') {
      setIsPrimaryMerchantBoxVisibility(true);
    }
    const clonedData = _.cloneDeep(growerData);
    _.set(clonedData, path, value === '' ? null : value);
    setGrowerData(clonedData);
  }, [growerData]);

  const handlePrimaryMerchantSelect = useCallback((promise) => {
    promise.then(updateGrowerData).finally(() => {
      setIsPrimaryMerchantBoxVisibility(false);
    });
  }, [updateGrowerData]);

  if (_.isEmpty(growerData)) {
    return (
      <div className={styles.loadingWrapper}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <PageLoadingComponent isLoading={isLoading} />
      <div className={styles.gridContainer}>
        <div className={styles.activeStatus}>
          <div className={styles.status}>
            <GrowerActiveChip
              firstname={growerData?.firstname}
              surname={growerData?.surname}
              active={growerData?.active}
            />
          </div>
          {growerData?.active ? (
            <div className={styles.status}>
              <GrowerAuditStatus igasNumber={igasNumber} />
            </div>
          ) : null}
        </div>
        <GrowerInfo
          info={growerData}
          auditYears={auditYears}
          onValueChange={handleChange}
          onSaveGrower={handleSaveGrower}
          primaryMerchant={primaryMerchant}
          isSaveDisabled={_.isEqual(initialGrowerData, growerData)}
        />
      </div>
      {isPrimaryMerchantBoxShown && (
        <SelectMerchantWithSearch
          isOpen={isPrimaryMerchantBoxShown}
          igasNumber={igasNumber}
          onClose={handlePrimaryMerchantSelect}
        />
      )}
    </>
  );
}

GrowerDetails.propTypes = {
  igasNumber: PropTypes.string.isRequired,
};

export default GrowerDetails;
