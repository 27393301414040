import { BaseService } from '../TransportService';

const SERVICE_NAME = 'grower/merchant';

export default {
  create(igasNumber, merchantID, isPrimaryMerchant, auth) {
    const merchant = { merchantID };
    const params = { igasNumber, merchant, isPrimaryMerchant };
    return BaseService.post(SERVICE_NAME, params, auth);
  },
  get(igasNumber, auth) {
    const params = { igasNumber };
    return BaseService.get(SERVICE_NAME, { params, auth });
  },
  update(growerMerchant, auth) {
    return BaseService.put(SERVICE_NAME, growerMerchant, auth);
  },
  delete(growerMerchantID, auth) {
    const params = { growerMerchantID };
    return BaseService.delete(SERVICE_NAME, params, auth);
  },

  // Get stores
  getStores(igasNumber, auth) {
    const params = { igasNumber };
    return BaseService.get(`${SERVICE_NAME}/stores`, { params, auth });
  },

  // Primary Merchants
  getPrimary(igasNumber, auth) {
    const params = { igasNumber };
    return BaseService.get(`${SERVICE_NAME}/primary`, { params, auth });
  },
  updatePrimaryMerchant(growerMerchant, auth) {
    return BaseService.put(`${SERVICE_NAME}/primary`, { ...growerMerchant, isPrimaryMerchant: true }, auth);
  },
};
