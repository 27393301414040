import React, { useEffect, useMemo, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { Stack, Typography } from '@mui/material';
import { PieChart } from 'react-minimal-pie-chart';
import { SustainabilitySurveyResult } from '../../model/Analytics';
import { SustainabilityService } from '../../services/Analytics';
import { AuditService } from '../../services/Audit';
import { PageLoadingComponent } from '../../components';
import styles from './SustainabilityReport.module.scss';

const oneColor = '#0975C0';
const twoColor = '#95E06C';
const threeColor = '#a1cae7';
const zeroColor = '#19475f';

function SustainabilityReport(): React.ReactElement {
  const [isLoading, setLoadingState] = useState<boolean>(false);
  const [data, setData] = useState<SustainabilitySurveyResult>();
  const { authState } = useOktaAuth();

  useEffect(() => {
    setLoadingState(true);
    AuditService.getYear(authState!)
      .then(({ currentYear }) => SustainabilityService.getSurvey(currentYear, authState!))
      .then(setData)
      .finally(() => setLoadingState(false));
  }, [authState]);

  const pieChartData = useMemo(() => (data ? [{
    color: zeroColor,
    title: 'Zero of three',
    value: data!.zero,
  }, {
    color: oneColor,
    title: 'One of three',
    value: data!.one,
  }, {
    color: twoColor,
    title: 'Two of three',
    value: data!.two,
  }, {
    color: threeColor,
    title: 'Three of three',
    value: data!.three,
  }] : null), [data]);

  return (
    <PageLoadingComponent isLoading={!data || isLoading}>
      <Stack justifyContent="center">
        <Typography variant="body1">
          Total number of growers:
          {' '}
          {new Intl.NumberFormat('en-IE', { maximumSignificantDigits: 3 }).format(data?.totalAudits || 0)}
        </Typography>
        <Typography variant="h4">Number of growers using each</Typography>
        <div className={styles.legendContainer}>
          <div className={styles.legendIndividualContainer}>
            <div style={{ backgroundColor: zeroColor }} className={styles.legendColor} />
            <p>Zero of three used</p>
          </div>
          <div className={styles.legendIndividualContainer}>
            <div style={{ backgroundColor: oneColor }} className={styles.legendColor} />
            <p>One of three used</p>
          </div>
          <div className={styles.legendIndividualContainer}>
            <div style={{ backgroundColor: twoColor }} className={styles.legendColor} />
            <p>Two of three used</p>
          </div>
          <div className={styles.legendIndividualContainer}>
            <div style={{ backgroundColor: threeColor }} className={styles.legendColor} />
            <p>Three of three used</p>
          </div>
        </div>
        <div className={styles.pieChart}>
          <PieChart
            data={pieChartData!}
            totalValue={data?.totalAudits}
            animate
            label={({ dataEntry }) => dataEntry?.value}
            labelStyle={(index) => ({
              fill: pieChartData ? pieChartData[index].color : '#000',
              fontSize: '5px',
              fontFamily: 'sans-serif',
            })}
            radius={42}
            labelPosition={112}
          />
        </div>
        <Typography variant="h4">Number of hectares used</Typography>
        <Typography variant="body1">
          Total organic fertilizer used:
          {' '}
          {new Intl.NumberFormat('en-IE', { maximumSignificantDigits: 3 }).format(data?.totalOrganicFertilizer || 0)}
          {' Hectares'}
        </Typography>
        <Typography variant="body1">
          Total cover crop used:
          {' '}
          {new Intl.NumberFormat('en-IE', { maximumSignificantDigits: 3 }).format(data?.totalCoverCrop || 0)}
          {' Hectares'}
        </Typography>
        <Typography variant="body1">
          Total straw used:
          {' '}
          {new Intl.NumberFormat('en-IE', { maximumSignificantDigits: 3 }).format(data?.totalStraw || 0)}
          {' Hectares'}
        </Typography>
        <Typography variant="h4">Number of hectares grown</Typography>
        <Typography variant="body1">
          Total barley grown:
          {' '}
          {new Intl.NumberFormat('en-IE', { maximumSignificantDigits: 3 }).format(data?.totalBarley || 0)}
          {' Hectares'}
        </Typography>
        <Typography variant="body1">
          Total wheat grown:
          {' '}
          {new Intl.NumberFormat('en-IE', { maximumSignificantDigits: 3 }).format(data?.totalWheat || 0)}
          {' Hectares'}
        </Typography>
        <Typography variant="body1">
          Total oats grown:
          {' '}
          {new Intl.NumberFormat('en-IE', { maximumSignificantDigits: 3 }).format(data?.totalOats || 0)}
          {' Hectares'}
        </Typography>
      </Stack>
    </PageLoadingComponent>
  );
}

export default SustainabilityReport;
