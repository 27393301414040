import { BaseService } from '../TransportService';

const SERVICE_NAME = 'grower/note';

export default {
  create(note, auth) {
    return BaseService.post(SERVICE_NAME, note, auth);
  },
  get(igasNumber, auth) {
    const params = { igasNumber };
    return BaseService.get(SERVICE_NAME, { params, auth });
  },
  update(note, auth) {
    return BaseService.put(SERVICE_NAME, note, auth);
  },
  delete(noteID, auth) {
    const params = { noteID };
    return BaseService.delete(SERVICE_NAME, params, auth);
  },
};
