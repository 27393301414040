import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { Button } from '../../components';
import styles from './BranchComponent.module.scss';

function HeadquarterComponent({ merchant, onSelect, onDelete }) {
  const handleSelect = useCallback(() => {
    onSelect(merchant?.merchantID);
  }, [merchant, onSelect]);

  return (
    <div className={styles.headquarterContainer}>
      <Typography variant="h4" component="h1" className={styles.merchantTitle}>
        Headquarter
      </Typography>
      <Typography variant="subtitle1" component="p">
        {`${merchant?.merchantID} ${merchant?.name}`}
      </Typography>
      <div className={styles.buttonContainer}>
        <Button variant="contained" color="primary" onClick={handleSelect}>View Merchant</Button>
        <Button variant="outlined" color="primary" onClick={onDelete}>Delete</Button>
      </div>
    </div>
  );
}

HeadquarterComponent.propTypes = {
  merchant: PropTypes.shape({
    merchantID: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  onSelect: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default React.memo(HeadquarterComponent);
