import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import styles from './AffirmationComponent.module.scss';

function getAffirmations() {
  const numberOfAffirmations = 20;
  const array = _.range(numberOfAffirmations);

  return array.map(() => fetch('https://www.affirmations.dev/')
    .then((response) => response.text().then((data) => JSON.parse(data)))
    .then((data) => data.affirmation));
}

function getRandomNumber() {
  const MAX_INTEGER = 100;
  return Math.floor(Math.random() * Math.floor(MAX_INTEGER));
}

const LUCKY_NUMBER = 7;

function AffirmationComponent() {
  const [affirmations, setAffirmations] = useState([]);
  const [randomNumber, setRandomNumber] = useState();

  useEffect(() => {
    const number = getRandomNumber();
    setRandomNumber(number);
    if (number === LUCKY_NUMBER) {
      Promise.all(getAffirmations()).then(setAffirmations);
    }
  }, []);

  return randomNumber === LUCKY_NUMBER && process.env.isMac ? (
    <div className={styles.tickerWrap}>
      <div className={styles.ticker}>
        {affirmations.map((affirmation) => (
          <div className={styles.tickerItem}>{affirmation}</div>
        ))}
      </div>
    </div>
  ) : null;
}

export default AffirmationComponent;
