import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import { MerchantService } from '../../services/Merchant';
import MerchantSearch from '../../components/SearchComponent';
import config from '../../config.json';

function MerchantSearchPage() {
  const navigate = useNavigate();
  const { authState } = useOktaAuth();
  const [merchants, setMerchants] = useState([]);

  const handleSearch = useCallback((userInput, searchType) => (
    MerchantService.search(userInput, searchType, authState)
      .then(setMerchants)
  ), [authState]);

  const handleSelect = useCallback((selectedID) => {
    navigate('/merchantDetail', {
      state: {
        merchantID: selectedID,
      },
    });
  }, [navigate]);

  return (
    <MerchantSearch
      data={merchants}
      metadata={config.merchantSearch}
      onSearch={handleSearch}
      onSelect={handleSelect}
    />
  );
}

export default MerchantSearchPage;
