import { useQuery, UseQueryResult } from 'react-query';
import { useOktaAuth } from '@okta/okta-react';
import { MerchantContactService } from '../../services/Merchant';
import CACHE_QUERIES from './cacheQueries.constant';
import { Contact } from '../../model/Merchant';

// eslint-disable-next-line import/prefer-default-export
export const useMerchantContacts = (
  merchantID: string | number,
  type: string,
): UseQueryResult<Contact[]> => {
  const { authState } = useOktaAuth();

  return useQuery(
    [CACHE_QUERIES.MERCHANT, merchantID, CACHE_QUERIES.MERCHANT_CONTACT, type],
    () => MerchantContactService.get(merchantID, authState, type),
  );
};
