import React, { useCallback, useState } from 'react';
import { Button } from '@mui/material';
import { useOktaAuth } from '@okta/okta-react';
import { useNavigate } from 'react-router-dom';
import { PageLoadingComponent } from '../../components';
import { AuditService } from '../../services/Audit';
import { BadgeNames, useBadge } from '../../contexts/BadgeContext/BadgeContext';

type AuditApprovalButtonTypes = {
  auditResult: string;
  auditID: number;
  onSendEmail?: (auditID?: number) => Promise<void>;
};

export const AuditApprovalButton: React.FC<AuditApprovalButtonTypes> = (
  { auditID, auditResult, onSendEmail },
) => {
  const [isLoading, setLoadingState] = useState<boolean>(false);
  const navigate = useNavigate();
  const { authState } = useOktaAuth();
  const { onRecount } = useBadge();

  const handleApproveAudit = useCallback(() => {
    if (auditID) {
      setLoadingState(true);
      AuditService.approve(auditID, authState!).then(async () => {
        if (onSendEmail) {
          await onSendEmail(auditID);
        }
        if (onRecount) {
          onRecount(BadgeNames.recordedAuditCount);
        }
        navigate('/recorded/audits');
      }).finally(() => setLoadingState(false));
    }
  }, [authState, navigate, onRecount, onSendEmail, auditID]);

  const handleFailedAudit = useCallback(() => {
    if (auditID) {
      setLoadingState(true);
      AuditService.failed(auditID, authState!).then(async () => {
        if (onSendEmail) {
          await onSendEmail(auditID);
        }
        if (onRecount) {
          onRecount(BadgeNames.recordedAuditCount);
        }
        navigate('/recorded/audits');
      }).finally(() => setLoadingState(false));
    }
  }, [authState, navigate, onRecount, onSendEmail, auditID]);

  return (
    <PageLoadingComponent isLoading={isLoading}>
      {auditResult !== 'RECOMMENDED' ? (
        <Button
          color="primary"
          variant="contained"
          id="approveAudit"
          onClick={handleFailedAudit}
        >
          Mark as failed
        </Button>
      ) : (
        <Button
          color="primary"
          variant="contained"
          id="approveAudit"
          onClick={handleApproveAudit}
        >
          Approve
        </Button>
      )}
    </PageLoadingComponent>
  );
};

export default React.memo(AuditApprovalButton);
