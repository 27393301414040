import React, { useCallback, useState } from 'react';
import { Dropzone, ExtFile, FileMosaic } from '@files-ui/react';
import { IconButton } from '@mui/material';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Button, ModalWithContent } from '..';

type FileUploadTypes = {
  onFileUpload: (files: File[], path?: string) => Promise<any> | undefined;
  path?: string;
  id: string;
  iconOnly?: boolean;
};

export const FileUpload: React.FC<FileUploadTypes> = (props) => {
  const [isModalOpen, setModalState] = useState<boolean>(false);
  const [isLoading, setLoadingState] = useState<boolean>(false);
  const [files, setFiles] = useState<ExtFile[]>([]);
  const {
    onFileUpload,
    iconOnly,
    path,
    id,
  } = props;

  const handleFileUpload = useCallback(() => {
    if (files && files.length > 0) {
      setLoadingState(true);
      Promise.resolve(onFileUpload(files.map(({ file }) => file!), path)).finally(() => {
        setModalState(false);
        setLoadingState(false);
        setFiles([]);
      });
    }
  }, [onFileUpload, path, files]);

  const updateFiles = useCallback((incommingFiles: ExtFile[]) => {
    setFiles(incommingFiles);
  }, []);

  return (
    <>
      {iconOnly ? (
        <IconButton aria-label="upload" size="small" onClick={() => setModalState(true)} disabled={isLoading} id={id}>
          <FileUploadIcon fontSize="inherit" />
        </IconButton>
      ) : (
        <Button
          color="primary"
          variant="contained"
          id={id}
          disabled={isLoading}
          onClick={() => setModalState(true)}
        >
          Upload Files
        </Button>
      )}
      <ModalWithContent
        isOpen={isModalOpen}
        onClose={() => setModalState(!isModalOpen)}
      >
        <>
          <Dropzone onChange={updateFiles} value={files} disabled={isLoading}>
            {files.map((file) => (
              <FileMosaic {...file} preview />
            ))}
          </Dropzone>
          <Button
            color="primary"
            variant="contained"
            id={`${id}-save`}
            onClick={handleFileUpload}
          >
            Upload
          </Button>
        </>
      </ModalWithContent>
    </>
  );
};

export default FileUpload;
