import * as z from 'zod';
import { ZAuditType } from './Audit';

export const ZAuditeeAssign = z.object({
  address: z.object({
    addressLine1: z.string().min(1),
    town: z.string().min(1),
    county: z.string().min(1),
    eircode: z.string().optional(),
  }),
  auditID: z.number(),
  auditorID: z.number(),
  companyName: z.string().min(1),
  email: z.string().min(1),
  firstname: z.string().min(1),
  growerType: ZAuditType,
  harvestYear: z.number(),
  igasNumber: z.string().min(1),
  phoneNumber: z.string().min(1),
  surname: z.string().min(1),
});

export type AuditeeAssign = z.infer<typeof ZAuditeeAssign>;
