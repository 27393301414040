import React, { useMemo } from 'react';
import { Button, Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { ZodType } from 'zod';
import useUserAccess from '../../hooks/useUserAccess/useUserAccess';
import { AuditApprovalButton } from './AuditApprovalButton';
import { AuditSendForApprovalButton } from './AuditSendForApprovalButton';
import { AuditRevertToDraftButton } from './AuditRevertToDraftButton';
import { PersistAuditButton } from './PersistAuditButton';
import { Audit } from '../../model/Audit';

type AuditButtonsTypes<T> = {
  schema: ZodType;
  onSendEmail?: (auditID?: number) => Promise<void>;
  onGet: (auditID: number) => Promise<T>;
  onCreate: (data: T) => Promise<T>;
  onUpdate: (data: T) => Promise<void>;
};

export const EDITABLE_STAGES = ['ASSIGNED', 'DRAFT', 'FAILED'];

export function AuditButtons<T extends Audit>({
  onSendEmail, onGet, schema, onCreate, onUpdate,
}: AuditButtonsTypes<T>) {
  const { getValues } = useFormContext();
  const { isAdmin } = useUserAccess();
  const {
    auditID,
    auditStage,
    resultDescription,
    auditResult,
    approvalDate,
  } = getValues();

  const showApprovalButton = useMemo(() => (
    auditStage === 'RECORDED' && isAdmin
  ), [auditStage, isAdmin]);

  const showPersistButton = useMemo(() => (
    ['ASSIGNED', 'DRAFT'].includes(auditStage) || !auditResult
  ), [auditResult, auditStage]);

  const showEmailButton = useMemo(() => (
    auditStage === 'REVIEWED' && isAdmin && approvalDate
  ), [approvalDate, auditStage, isAdmin]);

  const showSendForApprovalButton = useMemo(() => (
    auditStage === 'DRAFT' && auditResult
  ), [auditResult, auditStage]);

  const showRevertToDraftButton = useMemo(() => (
    auditResult && ['RECORDED', 'REVIEWED', 'FAILED'].includes(auditStage)
  ), [auditResult, auditStage]);

  return (
    <Stack direction="row" justifyContent="space-between">
      {showApprovalButton ? (
        <AuditApprovalButton
          auditResult={auditResult}
          auditID={auditID}
          onSendEmail={onSendEmail}
        />
      ) : null}
      {showPersistButton ? (
        <PersistAuditButton
          auditID={auditID}
          auditResult={auditResult}
          resultDescription={resultDescription}
          schema={schema}
          onGet={onGet}
          onCreate={onCreate}
          onUpdate={onUpdate}
        />
      ) : null}
      {showEmailButton ? (
        <Button color="primary" variant="contained" id="sendEmailAudit" onClick={() => onSendEmail!(auditID)}>
          Send Email
        </Button>
      ) : null}
      {showSendForApprovalButton ? (
        <AuditSendForApprovalButton onGet={onGet} auditID={auditID} />
      ) : null}
      {showRevertToDraftButton ? (
        <AuditRevertToDraftButton onGet={onGet} auditID={auditID} />
      ) : null}
    </Stack>
  );
}
