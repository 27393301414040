import { AuthState } from '@okta/okta-auth-js';
import { AuditCount } from '../../model/Audit';
import { BaseService } from '../TransportService';

const SERVICE_NAME = 'audit/analysis';

export default {
  count(auth: AuthState) {
    return BaseService.get<AuditCount>(`${SERVICE_NAME}/count`, { auth });
  },
};
