import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useLocation, useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { useOktaAuth } from '@okta/okta-react';
import { AuditDataProvider, setAuditQuestions } from '../../auditComponents';
import { ZStorageLocationAudit, StorageLocationAudit as StorageLocationAuditType } from '../../model/Audit';
import { StorageLocationAuditService } from '../../services/StorageLocation';
import { PageLoadingComponent } from '../../components';
import { useAuditQuestions } from '../../hooks';
import StorageLocationAuditForm from './StorageLocationAuditForm';
import styles from './StorageLocationAudit.module.scss';
import { MerchantAuditService } from '../../services/Merchant';

function StorageLocationAudit() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [isLoading, setLoadingState] = useState(false);
  const [initialData, setInitialData] = useState<Partial<StorageLocationAuditType>>({});
  const { data: questions, isLoading: questionLoading } = useAuditQuestions(state?.merchantAudit?.isISO ? 'ISOMERCHANT' : 'STORAGELOCATION');
  const { authState } = useOktaAuth();

  useEffect(() => {
    window.scroll(0, 0);
    if (state?.storageLocationAuditID) {
      setLoadingState(true);
      StorageLocationAuditService.get(state?.storageLocationAuditID, authState!)
        .then((data) => setInitialData(data))
        .finally(() => setLoadingState(false));
    } else {
      setInitialData((previousData) => ({
        ...previousData,
        auditType: state?.merchantAudit?.isISO ? 'ISOMERCHANT' : 'STORAGELOCATION',
        storageLocationDetailsID: state?.storageLocationDetailsID,
        merchantAuditID: state?.merchantAudit?.merchantAuditID,
        merchantContactID: state?.merchantAudit?.merchantContactID,
      }));
    }
  // Onlt execute once
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (questions && !_.isEmpty(initialData) && !initialData.questions) {
      setInitialData((previousData) => ({
        ...previousData,
        questions: setAuditQuestions(questions),
      }));
    }
  }, [initialData, questions]);

  const handleSaveAudit = useCallback((
    data: StorageLocationAuditType,
  ): Promise<StorageLocationAuditType> => (
    StorageLocationAuditService.create(data, authState!)
  ), [authState]);

  const handleUpdateAudit = useCallback((
    data: StorageLocationAuditType,
  ): Promise<void> => (
    StorageLocationAuditService.update(data, authState!)
  ), [authState]);

  const handleGetAudit = useCallback((
    auditID: number,
  ): Promise<StorageLocationAuditType> => (
    StorageLocationAuditService.getByAuditID(auditID, authState!)
  ), [authState]);

  const handleSendEmail = useCallback((): Promise<void> => (
    MerchantAuditService.sendAuditEmail(initialData.merchantAuditID!, authState!)
      .catch((e) => {
        const error = e as Error;
        // eslint-disable-next-line no-console
        console.error('No email sent. Audits not in a valid state', error);
      })), [authState, initialData?.merchantAuditID]);

  const handleBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <PageLoadingComponent
      isLoading={isLoading || questionLoading || !initialData.questions}
    >
      <Typography variant="body1" component="span">
        <Link onClick={handleBack} className={styles.backLink}>
          <KeyboardBackspaceIcon />
          Back
        </Link>
      </Typography>
      <AuditDataProvider
        initialData={initialData}
        schema={ZStorageLocationAudit}
        onCreate={handleSaveAudit}
        onGet={handleGetAudit}
        onSendEmail={handleSendEmail}
        onUpdate={handleUpdateAudit}
      >
        <StorageLocationAuditForm />
      </AuditDataProvider>
    </PageLoadingComponent>
  );
}

export default React.memo(StorageLocationAudit);
