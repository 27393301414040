import React, { useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { Stack, Typography } from '@mui/material';
import { IrelandMap, PageLoadingComponent } from '../../components';
import { GrowerAnalysisService } from '../../services/Grower';
import { GrowerCountyCount } from '../../model/Grower';

const HEADERS = [{
  title: 'County',
  accessor: 'county',
}, {
  title: 'Amount',
  accessor: 'amount',
}];

function GrowerReport(): React.ReactElement {
  const [isLoading, setLoadingState] = useState<boolean>(false);
  const [data, setData] = useState<GrowerCountyCount[]>();
  const { authState } = useOktaAuth();

  useEffect(() => {
    setLoadingState(true);
    GrowerAnalysisService.countyCount(authState!)
      .then(setData)
      .finally(() => setLoadingState(false));
  }, [authState]);

  return (
    <PageLoadingComponent isLoading={!data || isLoading}>
      <Stack justifyContent="center">
        <Typography variant="body1">
          Where are all the growers
        </Typography>
        <IrelandMap headers={HEADERS} data={data!} />
      </Stack>
    </PageLoadingComponent>
  );
}

export default GrowerReport;
