import _ from 'lodash';

function isSaveDisabled<T>(data: T, requiredFields: string[]): boolean {
  return requiredFields.some((fieldPath: string) => {
    const value = _.get(data, fieldPath);

    if (typeof value === 'number') {
      return _.isNull(value);
    }
    if (typeof value === 'boolean') {
      return _.isNull(value);
    }
    return _.isNull(value) || _.isEmpty(value);
  });
}

export default {
  isSaveDisabled,
};
