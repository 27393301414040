import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { useOktaAuth } from '@okta/okta-react';
import CircularProgress from '@mui/material/CircularProgress';
import { getFormattedDate } from '../../../util/dateUtils';
import { useAuditYear } from '../../../hooks';
import {
  PageLoadingComponent,
  GenerateDocuments,
  useModal,
} from '../../../components';
import styles from './MerchantDocuments.module.scss';
import { MerchantService, MerchantDocumentService } from '../../../services/Merchant';
import config from '../../../config.json';

function MerchantDocuments({ merchantID }) {
  const { authState } = useOktaAuth();
  const { data: harvestYear } = useAuditYear();
  const [documentData, setDocumentData] = useState({});
  const [merchantData, setMerchantData] = useState(undefined);
  const [isLoading, setLoadingState] = useState(true);
  const ModalService = useModal();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (harvestYear) {
      setDocumentData((previousData) => ({
        ...previousData,
        growersAuditList: {
          harvestYear: harvestYear.currentYear,
        },
        sustainabilitySurvey: {
          harvestYear: harvestYear.currentYear,
        },
      }));
    }
  }, [harvestYear]);

  const handleError = useCallback(() => {
    setLoadingState(false);
    ModalService.openModal({
      title: 'Error Occur',
      description: 'An Error occurred. Please try again.',
    });
  }, [ModalService]);

  useEffect(() => {
    if (merchantID) {
      setLoadingState(true);
      MerchantService.get(merchantID, authState)
        .then(setMerchantData)
        .catch(handleError)
        .finally(() => setLoadingState(false));
      MerchantDocumentService.getTagAuditMetadata(merchantID, authState).then((data) => {
        const addressColumnNumber = data?.addressColumnNumber || [];
        setDocumentData((previousData) => ({
          ...previousData,
          merchantTagAudit: {
            ...data,
            addressColumnNumber: addressColumnNumber.join(', '),
          },
        }));
      });
    }
  }, [handleError, merchantID, authState]);

  const handleTagAudit = useCallback((valuesForDocument, selectedValue) => {
    const { file, ...rest } = valuesForDocument;
    const name = merchantData?.name;
    const metadata = {
      ...rest,
      merchantID: merchantData?.merchantID,
      filename: `${name} ${getFormattedDate()} ${selectedValue.label}.${selectedValue.config.extension}`,
    };
    return MerchantDocumentService.tagAudit(file, metadata, authState)
      .then(() => enqueueSnackbar('Tag Audit complete!', { variant: 'success' }))
      .catch(handleError)
      .finally(() => setLoadingState(false));
  }, [authState, enqueueSnackbar, handleError, merchantData?.merchantID, merchantData?.name]);

  const handleGenerateDocument = useCallback((valuesForDocument, selectedValue, filename) => {
    setLoadingState(true);
    const { selectedDocument } = valuesForDocument;
    if (selectedDocument === 'merchantTagAudit') {
      return handleTagAudit(valuesForDocument, selectedValue);
    }

    return MerchantDocumentService.generate(merchantID, valuesForDocument, filename, authState)
      .catch(handleError).finally(() => setLoadingState(false));
  }, [merchantID, authState, handleError, handleTagAudit]);

  if (!merchantID) {
    return <Navigate to="/" />;
  }

  if (isLoading || _.isEmpty(merchantData) || !documentData?.growersAuditList?.harvestYear) {
    return (
      <div className={styles.loadingWrapper}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <PageLoadingComponent isLoading={isLoading}>
      <GenerateDocuments
        onClick={handleGenerateDocument}
        availableValues={config.typelist.generateMerchantList}
        name={merchantData.name}
        defaultFieldData={documentData}
      />
    </PageLoadingComponent>
  );
}

MerchantDocuments.propTypes = {
  merchantID: PropTypes.string.isRequired,
};

export default MerchantDocuments;
