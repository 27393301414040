import React, {
  useEffect,
  useCallback,
  useState,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useOktaAuth } from '@okta/okta-react';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { MerchantService } from '../../../services/Merchant';
import { GrowerService, GrowerMerchantService } from '../../../services/Grower';
import {
  SearchComponent as MerchantSearch,
  PageLoadingComponent,
  ModalWithContent,
} from '../../../components';
import { useMaintainedState } from '../../../contexts/MaintainedState/MaintainedStateContext';
import { GrowerMerchantInfo } from '../../../growerComponents';
import config from '../../../config.json';
import styles from './GrowerMerchantDetails.module.scss';

const ADD_MERCHANT_MS_PATH = 'addMerchant';

function getMerchantByID(merchants, merchantID) {
  return merchants.find(
    ({ merchant }) => merchant.merchantID === merchantID,
  );
}

function GrowerMerchantDetails(props) {
  const { igasNumber } = props;
  const [merchants, setMerchants] = useState([]);
  const [newMerchants, setNewMerchants] = useState([]);
  const [isLoading, setLoadingState] = useState(true);
  const [growerData, setGrowerData] = useState({});
  const [isModalOpen, setModalState] = useState(false);
  const { authState } = useOktaAuth();
  const { setMaintainedState, getMaintainedState } = useMaintainedState();

  const getMerchants = useCallback(() => {
    setLoadingState(true);
    GrowerMerchantService.get(igasNumber, authState)
      .then(setMerchants)
      .then(() => {
        setLoadingState(false);
      });
  }, [authState, igasNumber]);

  useEffect(() => {
    getMerchants();
  }, [getMerchants]);

  useEffect(() => {
    if (igasNumber) {
      GrowerService.get(igasNumber, authState)
        .then((response) => {
          setGrowerData(response);
        });
    }
  }, [authState, igasNumber]);

  const handleSearch = useCallback((userInput, searchType) => (
    MerchantService.search(userInput, searchType, authState)
      .then(setNewMerchants)
  ), [authState]);

  const toggleModal = useCallback(() => {
    setModalState(!isModalOpen);
    if (!isModalOpen) {
      const previouslyAddedMerchants = getMaintainedState(ADD_MERCHANT_MS_PATH);
      setNewMerchants(previouslyAddedMerchants);
    } else {
      setNewMerchants([]);
    }
  }, [getMaintainedState, isModalOpen]);

  const handleMerchantSelect = useCallback((selectedID) => {
    setLoadingState(true);
    GrowerMerchantService.create(igasNumber, selectedID, false, authState).then(async () => {
      const latestMerchants = await GrowerMerchantService.get(igasNumber, authState);
      const selectedMerchant = latestMerchants.find(
        ({ merchant }) => merchant.merchantID === selectedID,
      );
      const merchantState = getMaintainedState(ADD_MERCHANT_MS_PATH) || [];
      const newMerchantState = _.uniqBy([selectedMerchant.merchant, ...merchantState], 'merchantID');
      setMaintainedState(newMerchantState, ADD_MERCHANT_MS_PATH);

      setMerchants(latestMerchants);
      setLoadingState(false);
    });
    toggleModal();
  }, [authState, getMaintainedState, igasNumber, setMaintainedState, toggleModal]);

  const deleteGrowersMerchant = useCallback((growerMerchant) => {
    setLoadingState(true);
    GrowerMerchantService.delete(growerMerchant.growerMerchantID, authState).then(getMerchants);
  }, [authState, getMerchants]);

  const handleChange = useCallback((value, path) => {
    const clonedData = _.cloneDeep(merchants);
    _.set(clonedData, path, value === '' ? null : value);
    setMerchants(clonedData);
  }, [merchants]);

  const handleMerchantAccountNumber = useCallback((merchantID) => {
    setLoadingState(true);
    const changedMerchant = getMerchantByID(merchants, merchantID);
    GrowerMerchantService.update(changedMerchant, authState).finally(() => {
      setLoadingState(false);
    });
  }, [authState, merchants]);

  const handleMakePrimaryMerchant = useCallback((merchantID) => {
    setLoadingState(true);
    const primaryMerchant = getMerchantByID(merchants, merchantID);
    return GrowerMerchantService.updatePrimaryMerchant(primaryMerchant, authState)
      .then(getMerchants);
  }, [getMerchants, merchants, authState]);

  const merchantInfo = useMemo(() => {
    if (!merchants) {
      return null;
    }

    const hasPrimaryMerchant = merchants.some((merchant) => merchant.isPrimaryMerchant);

    return merchants.map((info, index) => (
      <GrowerMerchantInfo
        info={info}
        path={`[${index}]`}
        deleteGrowersMerchant={deleteGrowersMerchant}
        key={info.merchant.merchantID}
        onValueChange={handleChange}
        onMerchantAccountNumber={handleMerchantAccountNumber}
        onMakePrimaryMerchant={handleMakePrimaryMerchant}
        hasPrimaryMerchant={hasPrimaryMerchant || growerData.paymentMethod === 'MERCHANT'}
      />
    ));
  }, [
    deleteGrowersMerchant,
    growerData,
    handleChange,
    handleMakePrimaryMerchant,
    handleMerchantAccountNumber,
    merchants,
  ]);

  return (
    <div>
      <PageLoadingComponent isLoading={isLoading} />

      <div className={styles.headerContainer}>
        <Typography variant="h5" component="h1" className={styles.merchantTitle}>
          Merchant Info
        </Typography>
        <Fab color="primary" className={styles.addButton} onClick={toggleModal} id="addNewMerchant">
          <AddIcon />
        </Fab>
      </div>
      {merchantInfo}

      <ModalWithContent
        isOpen={isModalOpen}
        onClose={toggleModal}
      >
        <MerchantSearch
          data={newMerchants}
          metadata={config.merchantSearch}
          onSearch={handleSearch}
          onSelect={handleMerchantSelect}
        />
      </ModalWithContent>
    </div>
  );
}

GrowerMerchantDetails.propTypes = {
  igasNumber: PropTypes.string.isRequired,
};

export default GrowerMerchantDetails;
