import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import hoistNonReactStatic from 'hoist-non-react-statics';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';

// const DEFAULT_SCOPES = ['openid', 'profile', 'email'];

const oktaAuth = new OktaAuth({
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
  issuer: process.env.REACT_APP_OKTA_ISSUER,
  redirectUri: process.env.REACT_APP_OKTA_REDIRECT_URI,
  scopes: ['openid', 'profile', 'email'], // process.env.REACT_APP_OKTA_SCOPES ? [...DEFAULT_SCOPES, process.env.REACT_APP_OKTA_SCOPES] : DEFAULT_SCOPES,
  pkce: Boolean(process.env.REACT_APP_OKTA_PKCE),
  tokenManager: {
    autoRenew: true,
  },
});

function getDisplayName(WrappedComponent: React.FC) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export default function withAuthContext(WrappedComponent: React.FC) {
  function WithAuthContext(props: any) {
    const navigate = useNavigate();

    const restoreOriginalUri = useCallback((_oktaAuth: OktaAuth, originalUri: string) => {
      navigate(toRelativeUrl(originalUri || '/', window.location.origin));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        <WrappedComponent
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Security>
    );
  }
  hoistNonReactStatic(WithAuthContext, WrappedComponent as any);
  WithAuthContext.displayName = `WithAuthContext(${getDisplayName(WrappedComponent)})`;
  return WithAuthContext;
}
