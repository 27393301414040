import React, { useCallback } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { useFormContext } from 'react-hook-form';
import { AuditNoteService } from '../../services/Audit';
import { NotesComponent } from '../../components';
import { Note, AuditNote } from '../../model/Note';

function AuditNotes() {
  const { authState } = useOktaAuth();
  const { getValues, setValue } = useFormContext();
  const data = getValues();

  const setNotes = useCallback((notes: AuditNote[]) => {
    setValue('notes', notes, { shouldValidate: true });
  }, [setValue]);

  const handleNewNoteForExistingAudit = useCallback((newNoteWithID: AuditNote) => {
    const notes = [newNoteWithID, ...(data.notes || [])];
    setNotes(notes);
  }, [data.notes, setNotes]);

  const handleNewNoteForNewAudit = useCallback((newNoteWithID: AuditNote) => {
    AuditNoteService.create(newNoteWithID, authState!).then(setNotes);
  }, [authState, setNotes]);

  const handleNewNote = useCallback((newNote: Note) => {
    const newNoteWithID = {
      ...newNote,
      auditID: data?.auditID,
    };

    if (!data?.auditResult) {
      handleNewNoteForExistingAudit(newNoteWithID);
    } else {
      handleNewNoteForNewAudit(newNoteWithID);
    }
  }, [data, handleNewNoteForExistingAudit, handleNewNoteForNewAudit]);

  const handleNoteDelete = useCallback((newNotes: AuditNote[], noteToDelete: AuditNote) => {
    if (data.auditID) {
      AuditNoteService.delete(noteToDelete.noteID!, authState!);
    }
    setNotes(newNotes);
  }, [authState, data.auditID, setNotes]);

  return (
    <NotesComponent
      notes={data?.notes}
      onNewNote={handleNewNote}
      onDeleteNote={handleNoteDelete}
    />
  );
}

export default AuditNotes;
