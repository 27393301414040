import React, { useState, useCallback, useEffect } from 'react';
import _ from 'lodash';
import { useOktaAuth } from '@okta/okta-react';
import ButtonGroup from '@mui/material/ButtonGroup';
import Typography from '@mui/material/Typography';
import InputField from '../InputField/InputField';
import { ContactService } from '../../services/Contact';
import Button from '../Button/Button';
import styles from './ContactComponent.module.scss';

const MESSAGE_TYPES = {
  email: 'email',
  sms: 'sms',
};

type ContactComponentTypes = {
  email?: string;
  phoneNumber?: string;
};

function ContactComponent(props: ContactComponentTypes) {
  const [view, setView] = useState<string>();
  const [message, setMessage] = useState<string>();
  const [subject, setSubject] = useState<string>();
  const { authState } = useOktaAuth();
  const { email, phoneNumber } = props;
  const hasEmail = email !== undefined;
  const hasPhoneNumber = phoneNumber !== undefined;
  const hasBoth = hasEmail && hasPhoneNumber;

  useEffect(() => {
    if (hasEmail) {
      setView(MESSAGE_TYPES.email);
    } else if (hasPhoneNumber) {
      setView(MESSAGE_TYPES.sms);
    }
  // Only execute once
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMessageType = useCallback((messageType: string) => {
    if (view !== messageType) {
      setMessage('');
      setView(messageType);
    }
  }, [view]);

  const handleSendMessage = useCallback(() => {
    if (view === MESSAGE_TYPES.email) {
      ContactService.sendEmail({
        subject,
        content: message,
        to: email,
      }, authState);
    } else if (view === MESSAGE_TYPES.sms) {
      ContactService.sendSMS({
        content: message,
        to: phoneNumber,
      }, authState);
    }
  }, [email, message, phoneNumber, subject, view, authState]);

  if (view === undefined || (_.isEmpty(phoneNumber) && _.isEmpty(email))) {
    return null;
  }

  return (
    <>
      <Typography variant="h5" component="h1" className={styles.title}>
        Contact
      </Typography>
      <div className={styles.container}>
        {hasBoth ? (
          <ButtonGroup color="primary" aria-label="outlined primary button group" className={styles.toggle}>
            <Button id="emailSection" onClick={() => handleMessageType(MESSAGE_TYPES.email)}>Email</Button>
            <Button id="smsSection" onClick={() => handleMessageType(MESSAGE_TYPES.sms)}>SMS</Button>
          </ButtonGroup>
        ) : null}

        {view === MESSAGE_TYPES.email ? (
          <InputField
            className={styles.subject}
            label="Subject"
            id="emailSubject"
            onValueChange={(value) => setSubject(value as string)}
            value={subject}
          />
        ) : null}

        <InputField
          multiline
          rows="10"
          className={styles.message}
          label={view === 'email' ? 'Email Message' : 'SMS Message'}
          variant="outlined"
          id="contactMessage"
          onValueChange={(value) => setMessage(value as string)}
          value={message}
        />

        <div className={styles.sendButton}>
          <Button
            variant="contained"
            color="primary"
            id="sendButton"
            onClick={handleSendMessage}
          >
            Send
          </Button>
        </div>
      </div>
    </>
  );
}

ContactComponent.defaultProps = {
  phoneNumber: undefined,
  email: undefined,
};

export default ContactComponent;
