import React from 'react';
import { SnackbarProvider } from 'notistack';
import { BrowserRouter as Router } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { ModalProvider } from './components';
import { MaintainedStateContextProvider } from './contexts/MaintainedState/MaintainedStateContext';
import App from './app/App';

const rootElement = document.getElementById('root');

if (rootElement) {
  const root = createRoot(rootElement);

  root.render(
    <ModalProvider>
      <SnackbarProvider maxSnack={5}>
        <MaintainedStateContextProvider>
          <Router>
            <App />
          </Router>
        </MaintainedStateContextProvider>
      </SnackbarProvider>
    </ModalProvider>,
  );
}
