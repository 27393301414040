import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import { AuditorAuditsService } from '../../services/Auditor';
import { GrowerAuditService } from '../../services/Grower';
import { Button, PageLoadingComponent } from '../../components';
import GrowerSearch from '../../components/SearchComponent';
import config from '../../config.json';

function GrowerAuditSearchPage() {
  const navigate = useNavigate();
  const [isLoading, setLoadingState] = useState(false);
  const [growers, setGrowers] = useState([]);
  const { authState } = useOktaAuth();

  const handleSearch = useCallback((userInput, searchType) => (
    GrowerAuditService.search(userInput, searchType, authState)
      .then(setGrowers)
  ), [authState]);

  const handleDownload = useCallback(() => {
    setLoadingState(true);
    AuditorAuditsService.getExcelList(authState).finally(() => setLoadingState(false));
  }, [authState]);

  const handleSelect = useCallback((selectedID) => {
    navigate('/growerDetail', {
      state: {
        igasNumber: selectedID,
      },
    });
  }, [navigate]);

  return (
    <div>
      <PageLoadingComponent isLoading={isLoading} />
      <Button
        color="primary"
        variant="contained"
        style={{
          marginBottom: '25px',
        }}
        onClick={handleDownload}
      >
        Export List
      </Button>
      <GrowerSearch
        metadata={config.auditorGrowerSearch}
        data={growers}
        onSearch={handleSearch}
        onSelect={handleSelect}
        shouldInitiallySearch
      />
    </div>
  );
}

export default GrowerAuditSearchPage;
