import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useOktaAuth } from '@okta/okta-react';
import { useMaintainedState } from '../../contexts/MaintainedState/MaintainedStateContext';
import SelectPrimaryMerchant, { ADD_NEW_MERCHANT } from '../SelectPrimaryMerchant/SelectPrimaryMerchant';
import MerchantSearch from '../../components/SearchComponent';
import { GrowerMerchantService } from '../../services/Grower';
import { MerchantService } from '../../services/Merchant';
import { ModalWithContent } from '../../components';
import config from '../../config.json';

const ADD_MERCHANT_MS_PATH = 'addMerchant';

function SelectMerchantWithSearch(props) {
  const { isOpen, onClose, igasNumber } = props;
  const { authState } = useOktaAuth();
  const [merchants, setMerchants] = useState([]);
  const [isModalOpen, setModalState] = useState(false);
  const { setMaintainedState, getMaintainedState } = useMaintainedState();

  const toggleModal = useCallback(() => {
    setModalState(!isModalOpen);
    if (!isModalOpen) {
      const previouslyAddedMerchants = getMaintainedState(ADD_MERCHANT_MS_PATH);
      setMerchants(previouslyAddedMerchants || []);
    } else {
      setMerchants([]);
    }
  }, [getMaintainedState, isModalOpen]);

  const handlePrimaryMerchantChange = useCallback((selectedValue) => {
    if (_.isNil(selectedValue)) {
      onClose(Promise.reject());
    } else if (selectedValue === ADD_NEW_MERCHANT) {
      toggleModal();
    } else {
      const newPrimaryMerchant = selectedValue;
      newPrimaryMerchant.igasNumber = igasNumber;
      GrowerMerchantService.updatePrimaryMerchant(newPrimaryMerchant, authState)
        .then(() => {
          onClose(Promise.resolve());
        });
    }
  }, [onClose, toggleModal, igasNumber, authState]);

  const handleSearch = useCallback((userInput, searchType) => (
    MerchantService.search(userInput, searchType, authState).then(setMerchants)
  ), [authState]);

  const handleMerchantSelect = useCallback((selectedID) => {
    const merchant = { merchantID: selectedID };
    const growerMerchant = { igasNumber, merchant };
    return GrowerMerchantService.updatePrimaryMerchant(growerMerchant, authState)
      .then(async () => {
        const selectedMerchant = await GrowerMerchantService.getPrimary(igasNumber, authState);
        const merchantState = getMaintainedState(ADD_MERCHANT_MS_PATH) || [];
        const newMerchantState = _.uniqBy([selectedMerchant.merchant, ...merchantState], 'merchantID');
        setMaintainedState(newMerchantState, ADD_MERCHANT_MS_PATH);
      }).finally(() => {
        onClose(Promise.resolve());
      });
  }, [igasNumber, authState, getMaintainedState, setMaintainedState, onClose]);

  return (
    <div>
      <SelectPrimaryMerchant
        open={isOpen}
        onClose={handlePrimaryMerchantChange}
        igasNumber={igasNumber}
      />
      <ModalWithContent
        isOpen={isModalOpen}
        onClose={toggleModal}
      >
        <MerchantSearch
          data={merchants}
          metadata={config.merchantSearch}
          onSearch={handleSearch}
          onSelect={handleMerchantSelect}
        />
      </ModalWithContent>
    </div>
  );
}

SelectMerchantWithSearch.propTypes = {
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
  igasNumber: PropTypes.string,
};

SelectMerchantWithSearch.defaultProps = {
  onClose: _.noop,
  isOpen: false,
  igasNumber: '',
};

export default SelectMerchantWithSearch;
