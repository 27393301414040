import { AuthState } from '@okta/okta-auth-js';
import { StorageLocation, GrowerLocation } from '../../model/Merchant';
import { BaseService } from '../TransportService';

const SERVICE_NAME = 'storage/location';

export default {
  create(data: StorageLocation, auth: AuthState) {
    return BaseService.post<StorageLocation>(SERVICE_NAME, data, auth);
  },
  createGrower(data: GrowerLocation, auth: AuthState) {
    return BaseService.post<GrowerLocation>(`${SERVICE_NAME}/grower`, data, auth);
  },
  get(merchantID: number | string, auth: AuthState, onlyActive = true) {
    const params = { merchantID, onlyActive };
    return BaseService.get<StorageLocation[]>(SERVICE_NAME, { params, auth });
  },
  getGrowers(merchantID: number | string, auth: AuthState) {
    const params = { merchantID };
    return BaseService.get<GrowerLocation[]>(`${SERVICE_NAME}/growers`, { params, auth });
  },
  update(data: StorageLocation | GrowerLocation, auth: AuthState) {
    return BaseService.put<StorageLocation | GrowerLocation, void>(SERVICE_NAME, data, auth);
  },
  search(query: string, auth: AuthState) {
    return BaseService.get<StorageLocation[]>(`${SERVICE_NAME}/search`, { params: { query }, auth });
  },
};
