import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import InputField from '../../components/InputField/InputField';
import CheckboxField from '../../components/CheckboxField/CheckboxField';
import Accordion from '../../components/Accordion/Accordion';
import { ModalWithContent, Button } from '../../components';
import AddressComponent from '../../components/AddressComponent/AddressComponent';
import styles from './PersonalInfo.module.scss';

function getContactField(path, contact, onValueChange) {
  const newPath = path ? `${path}.` : '';
  return (
    <div>
      <ListItem>
        <InputField label="Firstname" value={contact?.firstname} className={styles.items} onValueChange={onValueChange} path={`${newPath}firstname`} />
      </ListItem>
      <ListItem>
        <InputField label="Surname" value={contact?.surname} className={styles.items} onValueChange={onValueChange} path={`${newPath}surname`} />
      </ListItem>
      <ListItem>
        <InputField label="Phone Number" value={contact?.phoneNumber} className={styles.items} onValueChange={onValueChange} path={`${newPath}phoneNumber`} />
      </ListItem>
      <ListItem>
        <InputField label="Landline" value={contact?.landlineNumber} className={styles.items} onValueChange={onValueChange} path={`${newPath}landlineNumber`} />
      </ListItem>
      <ListItem>
        <InputField label="Email" value={contact?.email} className={styles.items} onValueChange={onValueChange} path={`${newPath}email`} />
      </ListItem>
    </div>
  );
}

function PersonalInfo(props) {
  const {
    info, onValueChange, onSaveMerchant, onDeleteMerchantContact, isAdding, title,
    onCreateMerchantContact,
  } = props;
  const [newContact, updateNewContact] = useState({});
  const [isContactModalOpen, setContactModalStatus] = useState(false);

  const toggleModal = useCallback(() => {
    setContactModalStatus(!isContactModalOpen);
  }, [isContactModalOpen]);

  const handleSaveContact = useCallback(() => {
    newContact.merchantID = info.merchantID;
    onCreateMerchantContact(newContact).finally(() => {
      setContactModalStatus(false);
    });
  }, [info, newContact, onCreateMerchantContact]);

  const handleNewContactChange = useCallback((value, changedPath) => {
    updateNewContact({ ...newContact, [changedPath]: value });
  }, [newContact]);

  const handleDeleteMerchantContact = useCallback((merchantContactID) => () => {
    onDeleteMerchantContact(merchantContactID);
  }, [onDeleteMerchantContact]);

  const handleRenderHeader = useCallback((contact) => () => (
    `${contact?.firstname} ${contact?.surname}`
  ), []);

  const isValid = useMemo(() => {
    const name = info?.name;
    const addressLine1 = info?.address?.addressLine1;
    const county = info?.address?.county;

    return name && addressLine1 && county;
  }, [info]);

  const contactsContent = useMemo(() => {
    const { contacts } = info;

    if (_.isNil(contacts)) {
      return null;
    }

    return contacts.map((contact, index) => {
      const contactPath = `contacts[${index}]`;
      return (
        <Accordion
          renderHeader={handleRenderHeader(contact)}
          isInitiallyOpen={index === 0}
          className={styles.accordion}
          key={contact.merchantContactID}
        >
          {getContactField(contactPath, contact, onValueChange)}
          <div className={styles.buttonContainer}>
            <Fab
              color="primary"
              className={styles.removeButton}
              onClick={handleDeleteMerchantContact(contact.merchantContactID)}
            >
              <DeleteIcon />
            </Fab>
          </div>
        </Accordion>
      );
    });
  }, [onValueChange, handleDeleteMerchantContact, handleRenderHeader, info]);

  return (
    <Paper className={styles.container}>
      <List className={classNames({ [styles.columns]: !isAdding, [styles.fullWidth]: isAdding })}>
        <ListItem>
          <Typography variant="h5" component="h1" className={styles.title}>
            {title}
          </Typography>
        </ListItem>
        <ListItem>
          <CheckboxField
            value={info.active}
            onValueChange={onValueChange}
            path="active"
            className={styles.items}
            label="Active"
            id="active"
          />
        </ListItem>
        {!isAdding ? (
          <ListItem>
            <InputField id="merchantID" label="Merchant ID" value={info.merchantID} className={styles.items} onValueChange={onValueChange} path="merchantID" disabled />
          </ListItem>
        ) : null}
        <ListItem>
          <InputField id="merchantNameField" label="Merchant Name" required value={info.name} className={styles.items} onValueChange={onValueChange} path="name" />
        </ListItem>
        <ListItem>
          <InputField id="emailField" label="Email" value={info.email} className={styles.items} onValueChange={onValueChange} path="email" />
        </ListItem>
        <ListItem>
          <InputField id="landlineNumberField" label="Landline Number" value={info.landlineNumber} className={styles.items} onValueChange={onValueChange} path="landlineNumber" />
        </ListItem>
        <AddressComponent
          address={info.address}
          seperatorComponent={ListItem}
          className={styles.items}
          onValueChange={onValueChange}
          path="address"
        />
        <ListItem>
          <Button
            id="saveMerchant"
            variant="contained"
            color="primary"
            type="submit"
            className={styles.button}
            onClick={onSaveMerchant}
            disabled={!isValid}
          >
            Save Merchant
          </Button>
        </ListItem>
      </List>
      {!isAdding ? (
        <>
          <List className={styles.columns}>
            <ListItem>
              <Typography variant="h5" component="h1" className={styles.title}>
                Merchant Contact Info
                <Fab color="primary" className={styles.addButton} onClick={toggleModal} id="addMerchantContact">
                  <AddIcon />
                </Fab>
              </Typography>
            </ListItem>
            {contactsContent}
          </List>
          <ModalWithContent
            isOpen={isContactModalOpen}
            onClose={toggleModal}
            modalClassName={styles.merchantModal}
            contentClassName={styles.modalContent}
          >
            <Typography variant="h5" component="h1" className={styles.title}>
              Add Merchant Contact
            </Typography>
            {getContactField(null, newContact, handleNewContactChange)}
            <Button
              variant="contained"
              color="primary"
              type="submit"
              className={styles.button}
              onClick={handleSaveContact}
              disabled={_.isEmpty(newContact)}
            >
              Add Contact
            </Button>
          </ModalWithContent>
        </>
      ) : null}
    </Paper>
  );
}

PersonalInfo.propTypes = {
  info: PropTypes.shape({
    contacts: PropTypes.arrayOf(PropTypes.shape({
      firstname: PropTypes.string,
      surname: PropTypes.string,
      phoneNumber: PropTypes.string,
      email: PropTypes.string,
    })),
    name: PropTypes.string,
    landlineNumber: PropTypes.string,
    merchantID: PropTypes.string,
    email: PropTypes.string,
    capacity: PropTypes.number,
    active: PropTypes.bool,
    address: PropTypes.shape({
      addressLine1: PropTypes.string,
      county: PropTypes.string,
    }),
  }).isRequired,
  onValueChange: PropTypes.func,
  onSaveMerchant: PropTypes.func,
  onCreateMerchantContact: PropTypes.func,
  onDeleteMerchantContact: PropTypes.func,
  title: PropTypes.string,
  isAdding: PropTypes.bool,
};

PersonalInfo.defaultProps = {
  onValueChange: _.noop,
  onSaveMerchant: _.noop,
  onCreateMerchantContact: _.noop,
  onDeleteMerchantContact: _.noop,
  title: 'Merchant Info',
  isAdding: false,
};

export default PersonalInfo;
