import React, {
  useCallback,
  useContext,
  useMemo,
  useEffect,
  useState,
} from 'react';
import { useOktaAuth } from '@okta/okta-react';
import AuthUtil from '../../util/authUtil';
import { AuditService } from '../../services/Audit';
import { AuditorAuditsService } from '../../services/Auditor';
import { GrowerAudit, RecordedAuditCount } from '../../model/Audit';
import { MerchantAudit } from '../../model/Merchant';

export interface BadgeContextProviderProps {
  children: React.ReactElement;
}

export enum BadgeNames {
  'recordedAuditCount',
  'auditorFollowUp',
}

export type BadgeContextTypes = {
  auditFollowUpCount?: number;
  recordedAuditCount?: number;
  onRecount?: (name: BadgeNames) => void;
};

export const BadgeContext = React.createContext<BadgeContextTypes>({});

export function BadgeContextProvider(props: BadgeContextProviderProps) {
  const { children } = props;
  const { authState } = useOktaAuth();
  const [recordedAuditCount, setRecordedAuditCount] = useState<RecordedAuditCount | undefined>();
  const [auditFollowUp, setAuditFollowUp] = useState<GrowerAudit[] | MerchantAudit[] | undefined>();

  const handleRecount = useCallback((name: BadgeNames) => {
    switch (name) {
      case BadgeNames.recordedAuditCount:
        if (AuthUtil.hasAccess('Admin', authState!)) {
          AuditService.countRecordedAudits(authState!).then(setRecordedAuditCount);
        }
        break;
      case BadgeNames.auditorFollowUp:
        if (AuthUtil.hasAccess(['Auditor', 'MerchantAuditor'], authState!)) {
          AuditorAuditsService.followUp(authState!).then(setAuditFollowUp);
        }
        break;
    }
  }, [authState]);

  useEffect(() => {
    if (AuthUtil.hasAccess('Admin', authState!)) {
      AuditService.countRecordedAudits(authState!).then(setRecordedAuditCount);
    }
    if (AuthUtil.hasAccess(['Auditor', 'MerchantAuditor'], authState!)) {
      AuditorAuditsService.followUp(authState!).then(setAuditFollowUp);
    }
  }, [authState]);

  const badges = useMemo(() => ({
    auditFollowUpCount: auditFollowUp?.length,
    recordedAuditCount: recordedAuditCount?.totalAudits,
    onRecount: handleRecount,
  }), [auditFollowUp, handleRecount, recordedAuditCount]);

  return (
    <BadgeContext.Provider value={badges}>
      {children}
    </BadgeContext.Provider>
  );
}

export function useBadge() {
  return useContext(BadgeContext);
}
