import { AuthState } from '@okta/okta-auth-js';
import { BaseService } from '../TransportService';
import { SustainabilitySurveyResult } from '../../model/Analytics';

const SERVICE_NAME = 'sustainability/survey';

export default {
  getSurvey(harvestYear: number, auth: AuthState) {
    const params = { harvestYear };
    return BaseService.get<SustainabilitySurveyResult>(SERVICE_NAME, { params, auth });
  },
};
