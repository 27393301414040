import React, {
  useCallback, useState, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { useOktaAuth } from '@okta/okta-react';
import CircularProgress from '@mui/material/CircularProgress';
import _ from 'lodash';
import { useMaintainedState } from '../../../contexts/MaintainedState/MaintainedStateContext';
import { GrowerMerchantService, GrowerService } from '../../../services/Grower';
import { GrowerInfo } from '../../../growerComponents';
import MerchantSearch from '../../../components/SearchComponent';
import { getFormattedDate } from '../../../util/dateUtils';
import { PageLoadingComponent, ModalWithContent } from '../../../components';
import { MerchantService } from '../../../services/Merchant';
import WizardButtons from '../components/WizardButtons/WizardButtons';
import config from '../../../config.json';

const ADD_MERCHANT_MS_PATH = 'addMerchant';
const INITIAL_GROWER_DATA = {
  firstYearRegistered: new Date().getFullYear() - 1,
  joinDate: getFormattedDate(),
  active: true,
  growerType: 'PRODUCTIONONLY',
  paymentMethod: 'DIRECTDEBIT',
  sizeCategory: 1,
};

function AddGrowerPage(props) {
  const {
    wizardData,
    updateWizardData,
    onNext,
    onCancel,
  } = props;
  const { authState } = useOktaAuth();
  const [growerData, setGrowerData] = useState(INITIAL_GROWER_DATA);
  const [isLoading, setLoadingState] = useState(false);
  const [isPrimaryMerchantBoxShown, setIsPrimaryMerchantBoxVisibility] = useState(false);
  const [choosenMerchant, setChoosenMerchant] = useState();
  const [merchants, setMerchants] = useState([]);
  const { setMaintainedState, getMaintainedState } = useMaintainedState();

  useEffect(() => {
    if (wizardData.igasNumber) {
      setLoadingState(true);
      GrowerService.get(wizardData.igasNumber, authState)
        .then(setGrowerData)
        .finally(() => {
          setLoadingState(false);
        });
    }
  }, [authState, wizardData]);

  const handleSearch = useCallback((userInput, searchType) => (
    MerchantService.search(userInput, searchType, authState)
      .then(setMerchants)
  ), [authState]);

  const toggleModal = useCallback(() => {
    setIsPrimaryMerchantBoxVisibility(!isPrimaryMerchantBoxShown);
    if (!isPrimaryMerchantBoxShown) {
      const previouslyAddedMerchants = getMaintainedState(ADD_MERCHANT_MS_PATH);
      setMerchants(previouslyAddedMerchants);
    } else {
      setMerchants([]);
    }
  }, [getMaintainedState, isPrimaryMerchantBoxShown]);

  const handleMerchantSelect = useCallback((selectedID) => {
    setLoadingState(true);
    const selectedMerchant = merchants.find(
      (merchant) => merchant.merchantID === selectedID,
    );
    const merchantState = getMaintainedState(ADD_MERCHANT_MS_PATH) || [];
    const newMerchantState = _.uniqBy([selectedMerchant, ...merchantState], 'merchantID');
    setMaintainedState(newMerchantState, ADD_MERCHANT_MS_PATH);

    setChoosenMerchant(selectedID);
    toggleModal();
    setLoadingState(false);
  }, [getMaintainedState, merchants, setMaintainedState, toggleModal]);

  const handleChange = useCallback((value, path) => {
    if (_.includes(path, 'paymentMethod') && value === 'MERCHANT') {
      setIsPrimaryMerchantBoxVisibility(true);
    }
    const clonedData = _.cloneDeep(growerData);
    _.set(clonedData, path, value === '' ? null : value);
    setGrowerData(clonedData);
  }, [growerData]);

  const handleSaveGrower = useCallback((event) => {
    event.preventDefault();
    setLoadingState(true);
    GrowerService.update(growerData, authState).then((response) => {
      updateWizardData({ igasNumber: response.igasNumber });
      setLoadingState(false);
    });
  }, [authState, growerData, updateWizardData]);

  const isValid = useCallback(() => {
    const firstname = growerData?.firstname;
    const surname = growerData?.surname;
    const addressLine1 = growerData?.address?.addressLine1;
    const county = growerData?.address?.county;
    const growerType = growerData?.growerType;
    const sizeCategory = growerData?.sizeCategory;
    const paymentMethod = growerData?.paymentMethod;

    return (
      firstname && surname
      && addressLine1 && county
      && paymentMethod && growerType && sizeCategory
    );
  }, [growerData]);

  const createGrower = useCallback(async () => {
    setLoadingState(true);
    const updatedData = await GrowerService.create(growerData, authState);
    if (choosenMerchant) {
      await GrowerMerchantService.create(updatedData.igasNumber, choosenMerchant, true, authState);
    }
    setLoadingState(false);
    updateWizardData({ igasNumber: updatedData.igasNumber });
    return updatedData;
  }, [authState, choosenMerchant, growerData, updateWizardData]);

  const handleNext = useCallback(async () => {
    if (isValid()) {
      await createGrower();
      onNext();
    }
  }, [createGrower, isValid, onNext]);

  const handleCancel = useCallback(async () => {
    if (isValid()) {
      const grower = await createGrower();
      onCancel(grower.igasNumber);
    }
  }, [createGrower, isValid, onCancel]);

  if (_.isEmpty(growerData)) {
    return (
      <div className="loadingWrapper">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div>
      <PageLoadingComponent isLoading={isLoading} />
      <GrowerInfo
        info={growerData}
        onValueChange={handleChange}
        onSaveGrower={handleSaveGrower}
        isSaveDisabled={_.isEqual(growerData, growerData)}
        isAdding
      />
      <ModalWithContent
        isOpen={isPrimaryMerchantBoxShown}
        onClose={toggleModal}
      >
        <MerchantSearch
          data={merchants}
          metadata={config.merchantSearch}
          onSearch={handleSearch}
          onSelect={handleMerchantSelect}
        />
      </ModalWithContent>
      <WizardButtons
        nextLabel="Save Grower"
        onNext={handleNext}
        onCancel={handleCancel}
        disableNext={!isValid()}
        disableCancel={!isValid()}
      />
    </div>
  );
}

AddGrowerPage.propTypes = {
  metadata: PropTypes.shape({}),
  wizardData: PropTypes.shape({
    igasNumber: PropTypes.string,
  }),
  updateWizardData: PropTypes.func,
  onNext: PropTypes.func,
  onCancel: PropTypes.func,
};

AddGrowerPage.defaultProps = {
  metadata: undefined,
  wizardData: undefined,
  updateWizardData: _.noop,
  onNext: _.noop,
  onCancel: _.noop,
};

export default AddGrowerPage;
