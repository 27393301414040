import { BaseService } from '../TransportService';

const SERVICE_NAME = 'contact';

export default {
  sendSMS(sms, auth) {
    return BaseService.post(`${SERVICE_NAME}/sms`, sms, auth);
  },
  sendEmail(email, auth) {
    return BaseService.post(`${SERVICE_NAME}/email`, email, auth);
  },
};
