import { AuthState } from '@okta/okta-auth-js';
import { getUrl, getHeaders, checkStatus } from './util/TransferUtil';

export interface GetParams {
  params?: Record<string, string | number | boolean>;
  auth?: AuthState;
}

export function handleResponse<T>(response: any): Promise<T | T[]> {
  return response.text()
    .then((text: string): Promise<T | T[]> => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
        if (response.status === 401) {
          // auto logout if 401 response returned from api
          window.location.reload();
        }

        const error = (data && (data as Error).message) || response.statusText;
        return Promise.reject(error);
      }

      return Promise.resolve(data as T | T[]);
    });
}

export default {
  post: <T>(endpoint: string, params: T, auth: AuthState): Promise<T> => {
    const requestOptions = {
      method: 'POST',
      headers: getHeaders(auth),
      body: JSON.stringify(params),
    };

    const composedURL = getUrl({ endpoint });

    return fetch(composedURL, requestOptions)
      .then(checkStatus)
      .then(handleResponse) as Promise<T>;
  },

  get: <T>(endpoint: string, rest: GetParams = {}): Promise<T> => {
    const { auth, params = {} } = rest;
    const requestOptions = {
      method: 'GET',
      headers: getHeaders(auth),
    };

    const composedURL = getUrl({ endpoint, params });

    return fetch(composedURL, requestOptions)
      .then(checkStatus)
      .then(handleResponse) as Promise<T>;
  },

  put: <R, T>(
    endpoint: string,
    params: R,
    auth: AuthState,
    queryParams: Record<string, string | number | boolean> = {},
  ): Promise<T> => {
    const requestOptions = {
      method: 'PUT',
      headers: getHeaders(auth),
      body: JSON.stringify(params),
    };

    const composedURL = getUrl({ endpoint, params: queryParams });

    return fetch(composedURL, requestOptions)
      .then(checkStatus)
      .then(handleResponse) as Promise<T>;
  },

  delete: <T>(
    endpoint: string,
    params : Record<string, string | number>,
    auth: AuthState,
  ): Promise<T> => {
    const requestOptions = {
      method: 'DELETE',
      headers: getHeaders(auth),
    };

    const composedURL = getUrl({ endpoint, params });

    return fetch(composedURL, requestOptions)
      .then(checkStatus)
      .then(handleResponse) as Promise<T>;
  },
};
