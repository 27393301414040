import { AuthState } from '@okta/okta-auth-js';
import { Employee } from '../../model/Employee';
import { BaseService } from '../TransportService';

const SERVICE_NAME = 'employee';

export default {
  create(employee: Employee, auth: AuthState): Promise<Employee> {
    return BaseService.post<Employee>(SERVICE_NAME, employee, auth);
  },
  get(employeeID: string, auth: AuthState): Promise<Employee> {
    return BaseService.get<Employee>(`${SERVICE_NAME}/single`, { auth, params: { id: employeeID } });
  },
  getAll(auth: AuthState): Promise<Employee[]> {
    return BaseService.get<Employee[]>(SERVICE_NAME, { auth });
  },
  update(employee: Employee, auth: AuthState): Promise<Employee> {
    return BaseService.put<Employee, Employee>(SERVICE_NAME, employee, auth);
  },
};
