import * as z from 'zod';

export const ZRole = z.enum(['Admin', 'MerchantAuditor', 'Auditor', 'GrowerAuditEnterer']);

export const ZEmployee = z.object({
  id: z.number().nullish(),
  firstname: z.string().min(1),
  surname: z.string().min(1),
  phoneNumber: z.string().nullish(),
  createdDate: z.string().nullish(),
  lastModifiedDate: z.string().nullish(),
  role: ZRole,
  active: z.boolean(),
  email: z.string().email(),
  igasEmail: z.string().email().nullish(),
});

export type Employee = z.infer<typeof ZEmployee>;
export type Role = z.infer<typeof ZRole>;
