import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import _ from 'lodash';
import { useOktaAuth } from '@okta/okta-react';
import CircularProgress from '@mui/material/CircularProgress';
import {
  PageLoadingComponent,
  GenerateDocuments,
  useModal,
} from '../../../components';
import styles from './GrowerDocuments.module.scss';
import { GrowerService, GrowerDocumentService } from '../../../services/Grower';
import { AuditListService } from '../../../services/Audit';
import AuthUtil from '../../../util/authUtil';
import config from '../../../config.json';

function GrowerDocuments({ igasNumber }) {
  const { authState } = useOktaAuth();
  const [growerData, setGrowerData] = useState({});
  const [isLoading, setLoadingState] = useState(true);
  const ModalService = useModal();

  useEffect(() => {
    if (igasNumber) {
      setLoadingState(true);
      GrowerService.get(igasNumber, authState)
        .then((response) => {
          setGrowerData(response);
          setLoadingState(false);
        });
    }
  }, [authState, igasNumber]);

  const handleError = useCallback(() => {
    setLoadingState(false);
    ModalService.openModal({
      title: 'Error Occur',
      description: 'An Error occurred. Please try again.',
    });
  }, [ModalService]);

  const handleGenerateDocument = useCallback((valuesForDocument, selectedValue, filename) => {
    setLoadingState(true);
    const { selectedDocument } = valuesForDocument;

    let documentPromise;

    if (selectedDocument === 'growerFormalLetter') {
      documentPromise = GrowerDocumentService.generateFormalLetter(igasNumber, filename, authState);
    } else if (selectedDocument === 'auditNotificationLetter') {
      documentPromise = AuditListService
        .singleNotification(igasNumber, `${igasNumber} Audit List Notification.docx`, authState);
    } else {
      documentPromise = GrowerDocumentService
        .generateRegistrationLetter(igasNumber, filename, authState);
    }

    return documentPromise.catch(handleError).finally(() => setLoadingState(false));
  }, [igasNumber, authState, handleError]);

  if (!igasNumber) {
    return <Navigate to="/" />;
  }

  if (isLoading && _.isEmpty(growerData)) {
    return (
      <div className={styles.loadingWrapper}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <PageLoadingComponent isLoading={isLoading} />
      <GenerateDocuments
        onClick={handleGenerateDocument}
        availableValues={config.typelist.generateGrowerLetters.filter((av) => (
          AuthUtil.hasAccess(av.config.access, authState)
        ))}
        name={growerData.name}
      />
    </>
  );
}

GrowerDocuments.propTypes = {
  igasNumber: PropTypes.string.isRequired,
};

export default GrowerDocuments;
