import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { useOktaAuth } from '@okta/okta-react';
import { MerchantService } from '../../services/Merchant';
import PersonalInfo from '../../merchantComponents/PersonalInfo';
import { PageLoadingComponent } from '../../components';

const INITIAL_DATA = { active: true };

function AddMerchantPage() {
  const navigate = useNavigate();
  const { authState } = useOktaAuth();
  const [merchantData, setMerchantData] = useState(INITIAL_DATA);
  const [isLoading, setLoadingState] = useState(false);

  const handleChange = useCallback((value, path) => {
    const clonedData = _.cloneDeep(merchantData);
    _.set(clonedData, path, value);
    setMerchantData(clonedData);
  }, [merchantData]);

  const handleSaveMerchant = useCallback(() => {
    setLoadingState(true);
    MerchantService.create(merchantData, authState).then((response) => {
      navigate('/merchantDetail', {
        state: {
          merchantID: response.merchantID,
        },
      });
    });
  }, [navigate, merchantData, authState]);

  return (
    <>
      <PageLoadingComponent isLoading={isLoading} />
      <PersonalInfo
        title="Add Merchant"
        info={merchantData}
        onValueChange={handleChange}
        onSaveMerchant={handleSaveMerchant}
        isAdding
      />
    </>
  );
}

export default AddMerchantPage;
