import React, {
  useState,
  useEffect,
} from 'react';
import _ from 'lodash';
import { useOktaAuth } from '@okta/okta-react';
import PropTypes from 'prop-types';
import { GrowerService } from '../../../services/Grower';
import { ContactComponent, PageLoadingComponent } from '../../../components';

function GrowerContact(props) {
  const { igasNumber } = props;
  const [grower, setGrower] = useState({});
  const { authState } = useOktaAuth();
  const [isLoading, setLoadingState] = useState(true);

  useEffect(() => {
    setLoadingState(true);
    GrowerService.get(igasNumber, authState).then(setGrower).finally(() => {
      setLoadingState(false);
    });
  }, [authState, igasNumber]);

  if (_.isEmpty(grower)) {
    return null;
  }

  return (
    <div>
      <PageLoadingComponent isLoading={isLoading} />
      <ContactComponent
        phoneNumber={grower?.phoneNumber}
        email={grower?.email}
      />
    </div>
  );
}

GrowerContact.propTypes = {
  igasNumber: PropTypes.string.isRequired,
};

export default GrowerContact;
