import { AuthState } from '@okta/okta-auth-js';
import { BaseService } from '../TransportService';
import { MerchantAudit, MerchantAuditYear, Merchant } from '../../model/Merchant';

const SERVICE_NAME = 'merchant/audit';

export default {
  create(audit: MerchantAudit, auth: AuthState): Promise<MerchantAudit> {
    return BaseService.post<MerchantAudit>(SERVICE_NAME, audit, auth);
  },
  get(merchantAuditID: number, auth: AuthState): Promise<MerchantAudit> {
    const params = { merchantAuditID };
    return BaseService.get<MerchantAudit>(SERVICE_NAME, { params, auth });
  },
  getByHarvestYear(merchantID: string, auth: AuthState): Promise<MerchantAudit> {
    const params = { merchantID };
    return BaseService.get<MerchantAudit>(`${SERVICE_NAME}/harvestYear`, { params, auth });
  },
  update(audit: MerchantAudit, auth: AuthState): Promise<void> {
    return BaseService.put<MerchantAudit, void>(SERVICE_NAME, audit, auth);
  },
  getRecordedList(auth: AuthState): Promise<MerchantAudit[]> {
    return BaseService.get<MerchantAudit[]>(`${SERVICE_NAME}/recorded/list`, { auth });
  },
  getPreviousAuditYears(merchantID: number, auth: AuthState): Promise<MerchantAuditYear[]> {
    const params = { merchantID };
    return BaseService.get<MerchantAuditYear[]>(`${SERVICE_NAME}/previous`, { params, auth });
  },
  sendAuditEmail(merchantAuditID: number, auth: AuthState): Promise<void> {
    return BaseService.get(`${SERVICE_NAME}/sendAuditEmail`, { params: { merchantAuditID }, auth });
  },
  getDrafts(auth: AuthState): Promise<Merchant[]> {
    return BaseService.get<Merchant[]>(`${SERVICE_NAME}/draft`, { auth });
  },
};
