import { BaseService } from '../TransportService';

const SERVICE_NAME = 'invoice/merchant';

export default {
  generateAll(auth, shouldSave = true) {
    const params = { shouldSave };
    return BaseService.get(`${SERVICE_NAME}/generate/all`, { params, auth });
  },
  generateOne(merchantID, shouldSave, auth) {
    const params = { merchantID, shouldSave };
    return BaseService.get(`${SERVICE_NAME}/generate/one`, { params, auth });
  },
  validate(auth) {
    return BaseService.get(`${SERVICE_NAME}/validate`, { auth });
  },
};
