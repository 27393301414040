import { DocumentService } from '../TransportService';

const SERVICE_NAME = 'grower/document';

export default {
  generateLabels(fromDate, filename, auth) {
    return DocumentService.get(`${SERVICE_NAME}/labels`, { fromDate }, filename, auth);
  },
  generateFormalLetter(igasNumber, filename, auth) {
    return DocumentService.get(`${SERVICE_NAME}/formalLetter`, { igasNumber }, filename, auth);
  },
  generateRegistrationLetter(igasNumber, filename, auth) {
    return DocumentService.get(`${SERVICE_NAME}/registrationLetter`, { igasNumber }, filename, auth);
  },
  generateMultipleRegistrationLetter(fromDate, filename, auth) {
    return DocumentService.get(`${SERVICE_NAME}/registrationLetter/all`, { fromDate }, filename, auth);
  },
  notifyGrowersOfCancellation(fromDate, filename, auth) {
    return DocumentService.get(`${SERVICE_NAME}/notifyGrowersOfCancellation`, { fromDate }, filename, auth);
  },
  renewalNotice(filename, auth) {
    return DocumentService.get(`${SERVICE_NAME}/growerRenewal`, {}, filename, auth);
  },
};
