import React, { useCallback, useState } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { SubmitHandler, useFormContext } from 'react-hook-form';
import { ZodType, z } from 'zod';
import { ModalWithContent, PageLoadingComponent, useModal } from '../../components';
import { removeAudit } from '../AuditDataProvider/util/AuditStorageUtil';
import AuditStatusComponent from '../AuditStatusComponent/AuditStatusComponent';
import { getFormattedDate } from '../../util/dateUtils';
import { Audit } from '../../model/Audit';

type PersistAuditButtonTypes<T> = {
  auditID: number;
  auditResult?: string;
  resultDescription?: string;
  schema: ZodType;
  onGet: (auditID: number) => Promise<T>;
  onCreate: (data: T) => Promise<T>;
  onUpdate: (data: T) => Promise<void>;
};

export function PersistAuditButton<T extends Audit>({
  auditID, auditResult, onGet, onCreate, onUpdate, resultDescription, schema,
}: PersistAuditButtonTypes<T>) {
  const { reset, handleSubmit } = useFormContext();
  const [isModalOpen, setModalState] = useState<boolean>(false);
  const [isLoading, setLoadingState] = useState<boolean>(false);
  const ModalService = useModal();

  const toggleModal = useCallback(() => {
    setModalState((isOpen) => !isOpen);
  }, []);

  const handleCreateAudit: SubmitHandler<z.infer<typeof schema>> = useCallback((data) => {
    window.scroll(0, 0);
    setLoadingState(true);
    Promise.resolve(onCreate(data))
      .then((createdAudit) => onGet(createdAudit.auditID!))
      .then((createdAudit) => {
        reset(createdAudit);
        toggleModal();
        removeAudit(createdAudit);
      }).catch(() => {
        ModalService!.openModal({
          title: 'Something went wrong',
          description: 'Don\'t worry! This audit has been saved locally. You will be reminded to save it again when you next log in.',
        });
      })
      .finally(() => setLoadingState(false));
  }, [ModalService, onCreate, onGet, reset, toggleModal]);

  const handleUpdateAudit: SubmitHandler<z.infer<typeof schema>> = useCallback((data) => {
    window.scroll(0, 0);
    const { notes = [] } = data;
    ModalService!.openModal({
      title: 'Audit Changed',
      description: 'Audit data has changed, please provide a reason:',
      hasInput: true,
      inputProps: {
        defaultValue: 'Audit data changed: ',
        label: 'Reason for change',
      },
    }).then((noteContent: string) => {
      const newNote = {
        content: noteContent,
        createdDate: getFormattedDate(),
        auditID,
      };
      const newData = { ...data, notes: [newNote, ...(notes || [])] };
      setLoadingState(true);
      Promise.resolve(onUpdate(newData)).then(() => {
        toggleModal();
        removeAudit(newData);
      }).then(() => onGet(auditID!))
        .then((audit) => reset(audit))
        .catch(() => {
          ModalService!.openModal({
            title: 'Something went wrong',
            description: 'Don\'t worry! This audit has been saved locally. You will be reminded to save it again when you next log in.',
          });
        })
        .finally(() => setLoadingState(false));
    });
  }, [ModalService, auditID, onUpdate, onGet, reset, toggleModal]);

  return (
    <PageLoadingComponent isLoading={isLoading}>
      <Button
        color="primary"
        variant="contained"
        id="saveAudit"
        type="button"
        onClick={auditResult ? handleSubmit(handleUpdateAudit) : handleSubmit(handleCreateAudit)}
      >
        {auditResult ? 'Update Audit' : 'Save Audit'}
      </Button>
      <ModalWithContent
        isOpen={isModalOpen}
        onClose={toggleModal}
      >
        <Stack justifyContent="center" spacing={2}>
          <Typography variant="h5" component="h1" align="center">Audit Result</Typography>
          <AuditStatusComponent
            resultDescription={resultDescription}
            auditResult={auditResult}
          />
          <Button
            variant="contained"
            color="primary"
            type="submit"
            id="editAuditButton"
            onClick={toggleModal}
          >
            Edit Audit
          </Button>
        </Stack>
      </ModalWithContent>
    </PageLoadingComponent>
  );
}

export default React.memo(PersistAuditButton);
