import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import _ from 'lodash';
import { useOktaAuth } from '@okta/okta-react';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import PersonalInfo from '../../../merchantComponents/MerchantInfo';
import {
  PageLoadingComponent,
  useModal,
} from '../../../components';
import styles from './MerchantDetails.module.scss';
import { MerchantService, MerchantAuditService } from '../../../services/Merchant';

function MerchantDetails({ merchantID }) {
  const { authState } = useOktaAuth();
  const [initialMerchantData, setInitialMerchantData] = useState({});
  const [merchantData, setMerchantData] = useState(undefined);
  const [isLoading, setLoadingState] = useState(true);
  const [auditYears, setAuditYears] = useState([]);
  const ModalService = useModal();

  const handleError = useCallback(() => {
    setLoadingState(false);
    ModalService.openModal({
      title: 'Error Occur',
      description: 'An Error occurred. Please try again.',
    });
  }, [ModalService]);

  useEffect(() => {
    if (merchantID) {
      setLoadingState(true);
      MerchantService.get(merchantID, authState)
        .then((response) => {
          setMerchantData(response);
          setInitialMerchantData(response);
          setLoadingState(false);
        }).catch(handleError);
      MerchantAuditService.getPreviousAuditYears(merchantID, authState).then(setAuditYears);
    }
  }, [handleError, merchantID, authState]);

  const handleChange = useCallback((value, path) => {
    const clonedData = _.cloneDeep(merchantData);
    _.set(clonedData, path, value);
    setMerchantData(clonedData);
  }, [merchantData]);

  const handleSaveMerchant = useCallback(() => {
    setLoadingState(true);
    MerchantService.update(merchantData, authState).then(() => {
      const initialData = _.clone(merchantData);
      setInitialMerchantData(initialData);
      setLoadingState(false);
    }).catch(handleError);
  }, [handleError, merchantData, authState]);

  if (!merchantID) {
    return <Navigate to="/" />;
  }

  if (isLoading || _.isEmpty(merchantData)) {
    return (
      <div className={styles.loadingWrapper}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <PageLoadingComponent isLoading={isLoading}>
      <div className={styles.merchantStatusContainer}>
        <Chip
          label={merchantData.active ? 'Merchant is Active' : 'Merchant is Not Active'}
          color={merchantData.active ? 'primary' : 'secondary'}
        />
      </div>
      <PersonalInfo
        info={merchantData}
        auditYears={auditYears}
        onValueChange={handleChange}
        onSaveMerchant={handleSaveMerchant}
        isSaveDisabled={_.isEqual(initialMerchantData, merchantData)}
      />
    </PageLoadingComponent>
  );
}

MerchantDetails.propTypes = {
  merchantID: PropTypes.string.isRequired,
};

export default MerchantDetails;
