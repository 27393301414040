import React from 'react';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';

type GrowerActiveChipTypes = {
  firstname?: string;
  surname?: string;
  active?: boolean;
};

function GrowerActiveChip(props: GrowerActiveChipTypes) {
  const { firstname, surname, active } = props;

  if (!firstname || !surname) {
    return null;
  }

  return (
    <Chip
      avatar={<Avatar>{firstname[0] + surname[0]}</Avatar>}
      label={active ? 'Grower is Active' : 'Grower is Not Active'}
      color={active ? 'primary' : 'secondary'}
    />
  );
}

GrowerActiveChip.defaultProps = {
  firstname: undefined,
  surname: undefined,
  active: false,
};

export default GrowerActiveChip;
