import { DocumentService, BaseService } from '../TransportService';
import { downloadResponse } from '../TransportService/DocumentService';
import { FileService } from '../File';

const SERVICE_NAME = 'merchant/document';

export default {
  generate(merchantID, valuesForDocument, filename, auth) {
    const { selectedDocument } = valuesForDocument;
    const { sizeCategory, ...restOfValues } = valuesForDocument;

    if (sizeCategory) {
      restOfValues.exclude = 'sizeCategory';
    }
    const params = { ...restOfValues, merchantID };

    if (selectedDocument === 'growersAuditList' || selectedDocument === 'sustainabilitySurvey') {
      return DocumentService.get(`${SERVICE_NAME}/auditList`, params, filename, auth);
    }
    return DocumentService.get(`${SERVICE_NAME}/list`, params, filename, auth);
  },
  tagAudit(file, metadata, auth) {
    return DocumentService.sendFileAndData(`${SERVICE_NAME}/uploadFile`, file, metadata, auth)
      .then(({ data }) => FileService.get(data, auth))
      .then(({ data, isSuccessful }) => {
        if (isSuccessful) {
          downloadResponse(data, metadata.filename);
        } else {
          throw new Error('An error occurred when generating the tag audit');
        }
      });
  },
  getTagAuditMetadata(merchantID, auth) {
    const params = { merchantID };
    return BaseService.get(`${SERVICE_NAME}/metadata`, { params, auth });
  },
};
