import React, { useEffect, useCallback } from 'react';
import {
  Autocomplete as MuiAutocomplete,
  AutocompleteRenderOptionState,
  CircularProgress,
  FilterOptionsState,
} from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
// import TextField from '@mui/material/TextField';
import _ from 'lodash';
import Input from '../Input/Input';
import { AvailableValue } from '../../model/AvailableValues';

const filter = createFilterOptions<AvailableValue>();

export type AutocompleteType = {
  onLoadData: (data: string) => AvailableValue[] | Promise<AvailableValue[]>;
  onValueChange: (value?: AvailableValue | null, path?: string) => void;
  onNewOption?: (value?: AvailableValue | null, path?: string) => void;
  renderOption?: (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: AvailableValue,
    state: AutocompleteRenderOptionState,
  ) => React.ReactNode;
  id?: string;
  path?: string;
  name?: string;
  label: string;
  noOptionsText?: string;
  initialOptionText?: string;
};

export default function Autocomplete(props: AutocompleteType) {
  const {
    onLoadData,
    renderOption,
    onValueChange,
    path,
    id,
    label,
    name,
    onNewOption,
    noOptionsText,
    initialOptionText,
  } = props;
  const [value, setValue] = React.useState<AvailableValue | null>(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState<readonly AvailableValue[]>([]);
  const [isLoading, setLoadingState] = React.useState<boolean>(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getSearchResults = useCallback(
    _.debounce((searchQuery: string) => {
      if (searchQuery && searchQuery !== '') {
        Promise.resolve(onLoadData(searchQuery))
          .then(setOptions)
          .finally(() => setLoadingState(false));
      }
    }, 200),
    [],
  );

  useEffect(() => {
    getSearchResults(inputValue);
  }, [getSearchResults, inputValue]);

  const handleFilterOptions = useCallback((
    newOptions: AvailableValue[],
    params: FilterOptionsState<AvailableValue>,
  ) => {
    const filtered = filter(newOptions, params);

    if (onNewOption) {
      const { inputValue: searchValue } = params;

      if (searchValue !== '' && !isLoading && filtered.length === 0) {
        filtered.push({
          code: searchValue,
          label: `Add "${searchValue}"`,
        });
      }
    }

    return filtered;
  }, [isLoading, onNewOption]);

  const handleSelectValue = useCallback((event: any, newValue: AvailableValue | null) => {
    if (onNewOption) {
      if (/Add\s".*"/.test(newValue?.label!)) {
        onNewOption(newValue, path);
        setValue({ ...newValue, label: newValue?.code } as AvailableValue);
        return;
      }
    }
    setValue(newValue);
    onValueChange(newValue, path);
  }, [onNewOption, onValueChange, path]);

  return (
    <MuiAutocomplete
      id={id}
      getOptionLabel={(option: AvailableValue) => option.label}
      filterOptions={handleFilterOptions}
      options={options}
      autoComplete
      includeInputInList
      fullWidth
      filterSelectedOptions
      loading={isLoading}
      value={value}
      noOptionsText={inputValue !== '' ? noOptionsText : initialOptionText}
      onChange={handleSelectValue}
      onInputChange={(event, newInputValue) => {
        if (newInputValue !== '') {
          setLoadingState(true);
        }
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <Input
          {...params}
          label={label}
          name={name!}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      renderOption={renderOption}
    />
  );
}

Autocomplete.defaultProps = {
  renderOption: undefined,
  id: undefined,
  name: '',
  noOptionsText: 'No options',
  initialOptionText: 'Start typing for options',
  path: undefined,
};
