import React, { useCallback, useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { MerchantService } from '../../services/Merchant';
import ModalWithContent from '../ModalWithContent/ModalWithContent';
import SearchComponent from '../SearchComponent';
import config from '../../config.json';
import { useMaintainedState } from '../../contexts/MaintainedState/MaintainedStateContext';
import { Merchant } from '../../model/Merchant';

export type SearchMerchantModalType = {
  onMerchantSelect: (value: Merchant) => void;
  className?: string;
};

const ADD_MERCHANT_MS_PATH = 'addMerchant';

export default function SearchMerchantModal(props: SearchMerchantModalType) {
  const { onMerchantSelect, className } = props;
  const [isModalOpen, setModalState] = useState(false);
  const [merchants, setMerchants] = useState<Merchant[]>([]);
  const { setMaintainedState, getMaintainedState } = useMaintainedState();
  const { authState } = useOktaAuth();

  useEffect(() => {
    if (!isModalOpen) {
      const previouslyAddedMerchants = getMaintainedState(ADD_MERCHANT_MS_PATH);
      setMerchants(previouslyAddedMerchants as Merchant[]);
    } else {
      setMerchants([]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleModal = useCallback(() => {
    setModalState(!isModalOpen);
  }, [isModalOpen]);

  const handleSearch = useCallback((userInput: string, searchType: string) => (
    MerchantService.search(userInput, searchType, authState)
      .then(setMerchants)
  ), [authState]);

  const handleMerchantSelect = useCallback((selectedValue: any) => {
    const selectedMerchant = merchants.find((merchant) => merchant.merchantID === selectedValue);
    const merchantState = getMaintainedState(ADD_MERCHANT_MS_PATH) || [];
    const joinedArray = [selectedMerchant, ...merchantState] as Merchant[];
    const newMerchantState = joinedArray.filter((merchant, index) => (
      joinedArray.findIndex((m) => m.merchantID === merchant.merchantID) === index
    ));
    setMaintainedState(newMerchantState, ADD_MERCHANT_MS_PATH);
    Promise.resolve(onMerchantSelect(selectedMerchant!)).then(toggleModal);
  }, [getMaintainedState, merchants, onMerchantSelect, setMaintainedState, toggleModal]);

  return (
    <>
      <Fab
        color="primary"
        className={className}
        onClick={toggleModal}
        id="addNewMerchant"
      >
        <AddIcon />
      </Fab>
      <ModalWithContent
        isOpen={isModalOpen}
        onClose={toggleModal}
      >
        <SearchComponent
          data={merchants}
          metadata={config.merchantSearch}
          onSearch={handleSearch}
          onSelect={handleMerchantSelect}
        />
      </ModalWithContent>
    </>
  );
}
