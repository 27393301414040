import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
} from 'react';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { AuditorAuditsService, AuditorService } from '../../services/Auditor';
import {
  Button,
  PageLoadingComponent,
  getAddressDisplayName,
  Table,
  Date,
  useModal,
  ProgressBar,
} from '../../components';
import config from '../../config.json';
import AuthUtil from '../../util/authUtil';
import styles from './AuditorReviewPage.module.scss';
import { AuditorTotal, ZAuditorReviewDates, AuditorReviewDates } from '../../model/Auditor';

function AuditorReviewPage(): React.ReactElement {
  const navigate = useNavigate();
  const [auditorTotal, setAuditorTotals] = useState<AuditorTotal | undefined>();
  const [data, setData] = useState<{
    address: string;
    firstname: string;
    surname: string;
    igasNumber: string;
  }[] | undefined>();
  const [loadingState, setLoadingState] = useState(false);
  const { authState } = useOktaAuth();
  const ModalService = useModal();
  const methods = useForm<AuditorReviewDates>({ resolver: zodResolver(ZAuditorReviewDates) });
  const { handleSubmit, formState: { isValid } } = methods;

  const isGrowerAuditor = useMemo(() => AuthUtil.hasAccess(['Auditor'], authState!), [authState]);

  const handleError = useCallback(() => {
    setLoadingState(false);
    ModalService!.openModal({
      title: 'Error Occur',
      description: 'An Error occurred. Please try again.',
    });
  }, [ModalService]);

  useEffect(() => {
    setLoadingState(true);
    AuditorService.getRunningTotals(authState!)
      .then((value) => setAuditorTotals(value[0]))
      .catch(handleError)
      .finally(() => setLoadingState(false));
  }, [authState, handleError]);

  const handleExportReview: SubmitHandler<AuditorReviewDates> = useCallback((dates) => {
    setLoadingState(true);
    AuditorAuditsService.exportReview(dates.fromDate, dates.toDate, authState!)
      .finally(() => setLoadingState(false));
  }, [authState]);

  const auditorTotalComponent = useMemo(() => (auditorTotal ? (
    <>
      <span>{`${auditorTotal?.firstname} ${auditorTotal?.surname}`}</span>
      <ProgressBar
        id="auditorTotal"
        number={(auditorTotal?.completedAudits || 0) + (auditorTotal?.toBeReviewed || 0)}
        total={auditorTotal?.totalAudits}
        bufferValue={
          (auditorTotal?.completedAudits || 0)
          + (auditorTotal?.draftAudits || 0)
          + (auditorTotal?.toBeReviewed || 0)
        }
      />
    </>
  ) : null), [auditorTotal]);

  const onSubmit: SubmitHandler<AuditorReviewDates> = useCallback((dates) => {
    setLoadingState(true);
    AuditorAuditsService.getReview(dates.fromDate, dates.toDate, authState!)
      .then((completedAudits = []) => {
        const formattedData = completedAudits.map((audit) => (
          { ...audit, address: getAddressDisplayName(audit.address) }
        ));
        setData(formattedData);
      }).finally(() => setLoadingState(false));
  }, [authState]);

  const handleSelect = useCallback((selectedID: number) => {
    if (isGrowerAuditor) {
      navigate('/growerDetail/details', {
        state: {
          igasNumber: selectedID,
        },
      });
    } else {
      navigate('/merchantDetail', {
        state: {
          merchantID: selectedID,
        },
      });
    }
  }, [isGrowerAuditor, navigate]);

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FormProvider {...methods}>
      <Paper className={styles.container}>
        <PageLoadingComponent isLoading={loadingState} />
        {auditorTotalComponent}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.dateContainer}>
            <Date label="Start Date" name="fromDate" />
            <Date label="To Date" name="toDate" />
          </div>
          <div className={styles.buttonContainer}>
            <Button disabled={!isValid} id="getList" type="submit" onClick={handleSubmit(onSubmit)}>
              Get List
            </Button>
            <Button onClick={handleSubmit(handleExportReview)} disabled={!isValid} id="exportList">
              Export List
            </Button>
          </div>
        </form>
        <Table
          data={data}
          onSelect={handleSelect}
          metadata={
            isGrowerAuditor
              ? config.auditCompletedGrowerAuditorList.table
              : config.auditCompletedMerchantAuditorList.table
          }
        />
      </Paper>
    </FormProvider>
  );
}

export default AuditorReviewPage;
