import React from 'react';
import { useController } from 'react-hook-form';
import { Checkbox as MUICheckbox } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';

type CheckboxType = {
  control?: any;
  id?: string;
  name: string;
  label: string;
  disabled?: boolean;
  required?: boolean;
  className?: string;
  inputFieldClass?: string;
  defaultValue?: boolean;
};

Checkbox.defaultProps = {
  disabled: false,
  required: false,
  className: undefined,
  id: undefined,
  control: undefined,
  inputFieldClass: undefined,
  defaultValue: undefined,
};

function Checkbox(props: CheckboxType) {
  const {
    id,
    name,
    label,
    control,
    disabled,
    required,
    className,
    defaultValue,
    inputFieldClass,
    ...rest
  } = props;

  const {
    field: {
      onChange, value, ref,
    },
    // fieldState: { error },
    // formState: { touchedFields, dirtyFields }
  } = useController({
    name,
    control,
    rules: { required },
    defaultValue,
  });

  return label ? (
    <FormControlLabel
      label={label}
      className={className}
      control={(
        <MUICheckbox
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...rest}
          checked={value}
          color="primary"
          id={id || name}
          className={inputFieldClass}
          onChange={onChange}
          inputRef={ref}
          name={name}
          value={value}
          disabled={disabled}
        />
      )}
    />
  ) : (
    <MUICheckbox
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      checked={value}
      color="primary"
      id={id || name}
      className={inputFieldClass}
      onChange={onChange}
      name={name}
      inputRef={ref}
      value={value}
      disabled={disabled}
    />
  );
}

export default Checkbox;
