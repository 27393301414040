import React, { useMemo } from 'react';
import _ from 'lodash';
import InputField, { ValueChangeType } from '../InputField/InputField';
import SelectField from '../SelectField/SelectField';
import CheckboxField from '../CheckboxField/CheckboxField';
import DateField from '../DateField/DateField';
import FileUpload from '../FileUpload/FileUpload';
import styles from './MetadataComponent.module.scss';

export type MetadataType = {
  fieldID: string;
  path: string;
  type: string;
  label: string;
  defaultValue?: string;
};

export type MetadataComponentType = {
  onValueChange: ValueChangeType;
  value?: any,
  metadata: Array<MetadataType>;
};

const MetadataComponent: React.FC<MetadataComponentType> = (props) => {
  const { metadata, onValueChange, value } = props;

  const metadataToRender = useMemo(() => (
    metadata?.map((fieldMetadata) => {
      const {
        type,
        label,
        path,
        fieldID,
        defaultValue,
      } = fieldMetadata;

      if (type.toLowerCase() === 'dropdown') {
        return (
          <SelectField
            key={fieldID}
            path={path}
            id={fieldID}
            label={label}
            onValueChange={onValueChange}
            value={_.get(value, path)}
          />
        );
      }

      if (type.toLowerCase() === 'fileupload') {
        return (
          <FileUpload
            key={fieldID}
            onFileUpload={
              onValueChange as unknown as (files: File[], path?: string) => Promise<any> | undefined
            }
            path={path}
            id={fieldID}
          />
        );
      }

      if (type.toLowerCase() === 'number') {
        return (
          <InputField
            key={fieldID}
            path={path}
            id={fieldID}
            label={label}
            type="number"
            placeholder="Enter a number"
            onValueChange={onValueChange}
            value={_.get(value, path)}
            defaultValue={defaultValue}
          />
        );
      }

      if (type.toLowerCase() === 'checkbox') {
        return (
          <CheckboxField
            key={fieldID}
            path={path}
            id={fieldID}
            label={label}
            onValueChange={onValueChange}
            value={_.get(value, path)}
            defaultValue={defaultValue}
          />
        );
      }

      if (type.toLowerCase() === 'date') {
        return (
          <DateField
            key={fieldID}
            path={path}
            label={label}
            onValueChange={onValueChange}
            value={_.get(value, path)}
            defaultValue={defaultValue as unknown as Date}
          />
        );
      }

      return (
        <InputField
          key={fieldID}
          path={path}
          id={fieldID}
          label={label}
          onValueChange={onValueChange}
          value={_.get(value, path)}
          defaultValue={defaultValue}
        />
      );
    })
  ), [metadata, onValueChange, value]);

  if (_.isEmpty(metadata)) {
    return null;
  }

  return (
    <div className={styles.fieldContainer} data-testid="metadata-cotainer">
      {metadataToRender}
    </div>
  );
};

export default MetadataComponent;
