import React from 'react';
import {
  AuditQuestionsSection,
  useAuditData,
  AuditNotes,
} from '../../../auditComponents';
import { AuditInfoFields } from './components';

const PRODUCTION_ONLY = 'productionOnly';
const PRODUCTION_STORAGE = 'productionStorage';

function GrowerAuditForm() {
  const { isAuditReadOnly } = useAuditData();

  return (
    <>
      <AuditInfoFields readOnly={isAuditReadOnly} />
      <AuditQuestionsSection
        names={[PRODUCTION_ONLY, PRODUCTION_STORAGE]}
        readOnly={isAuditReadOnly}
      />
      <AuditNotes />
    </>
  );
}

export default React.memo(GrowerAuditForm);
