import _ from 'lodash';
import { StorageLocationAudit } from '../../../model/Merchant/MerchantAudit';

export const LIST_KEY = 'auditListKey';

export function setItem<T>(key: string, item: T) {
  window.localStorage.setItem(key, JSON.stringify(item));
}

export function getItem<T>(key: string): T | undefined {
  const data = window.localStorage.getItem(key);
  return data ? JSON.parse(data) : undefined;
}

export function removeItem(key: string) {
  window.localStorage.removeItem(key);
}

export function getAuditList<T>(): T[] {
  return getItem<T[]>(LIST_KEY) || [];
}

export function addToList<T>(item: T) {
  const currentList = getItem(LIST_KEY) || [];

  if ((item as any)?.igasNumber || (item as any)?.storageLocationDetailsID) {
    if (Array.isArray(currentList)) {
      setItem(LIST_KEY, [item, ...currentList]);
    }
  }
}

export function removeFromList(item: any) {
  const currentList = getItem(LIST_KEY) || [];

  if (Array.isArray(currentList)) {
    const index = currentList.findIndex((audit) => _.isEqual(audit, item));
    currentList.splice(index, 1);
    setItem(LIST_KEY, currentList);
  }
}

function isStorageLocationAudit<T>(audit: T, auditToCheck: T): boolean {
  const storageAudit = audit as unknown as StorageLocationAudit;
  const checkAudit = auditToCheck as unknown as StorageLocationAudit;
  return !!(
    storageAudit?.storageLocationDetailsID
    && storageAudit?.storageLocationDetailsID === checkAudit?.storageLocationDetailsID
  );
}

function isGrowerAudit(audit: any, auditToCheck: any): boolean {
  return !!(audit?.igasNumber && audit?.igasNumber === auditToCheck.igasNumber);
}

function isMatch<T>(audit: T, auditToCheck: T): boolean {
  return isStorageLocationAudit(audit, auditToCheck) || isGrowerAudit(audit, auditToCheck);
}

export function removeAudit<T>(audit: T) {
  const auditList = getAuditList();

  const newList = auditList.filter((item) => !isMatch(audit, item));
  setItem(LIST_KEY, newList);
}

type AuditFinderType<T> = {
  match: T[];
  rest: T[];
};

export function findAudit<T>(payload: Partial<T>): Partial<T> | undefined {
  // check if other audit exists within list
  const auditList = getItem(LIST_KEY);

  if (auditList && Array.isArray(auditList)) {
    // find the first audit
    const auditObject: AuditFinderType<T> = auditList.reduce((
      acc: AuditFinderType<T>,
      audit: T,
    ): AuditFinderType<T> => {
      if (isMatch(audit, payload)) {
        return {
          ...acc,
          match: [...(acc?.match || []), audit],
        };
      }
      return {
        ...acc,
        rest: [...(acc?.rest || []), audit],
      };
    }, { match: [], rest: [] });

    if (auditObject.match.length !== 0) {
      // get the latest matched key
      const foundAudit = auditObject.match.pop();

      if (foundAudit) {
        // remove the entries from the list
        setItem(LIST_KEY, auditObject.rest);

        // return the found audit
        return foundAudit as T;
      }
    }
  }
  return undefined;
}
