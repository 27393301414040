import React, {
  useState, useCallback, useEffect,
} from 'react';
import { useLocation } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import { useOktaAuth } from '@okta/okta-react';
import { GrowerAuditService } from '../../../services/Grower';
import { AuditDataProvider, setAuditQuestions } from '../../../auditComponents';
import { useAuditQuestions } from '../../../hooks';
import { NotAuditable } from './components';
import { PageLoadingComponent, getAddressDisplayName } from '../../../components';
import { GrowerActiveChip } from '../../../growerComponents';
import styles from './GrowerAudit.module.scss';
import { Grower } from '../../../model/Grower';
import { GrowerAudit, ZGrowerAudit } from '../../../model/Audit';
import GrowerAuditForm from './GrowerAuditForm';

const PRODUCTION_ONLY = 'productionOnly';
const PRODUCTION_STORAGE = 'productionStorage';

type GrowerAuditTypes = {
  grower?: Grower;
};

type GrowerAuditStateTypes = {
  auditID?: number;
  igasNumber?: string;
  readOnly?: boolean;
};

function GrowerAudits({ grower }: GrowerAuditTypes) {
  const {
    data: poQuestions,
  } = useAuditQuestions('PRODUCTIONONLY');
  const {
    data: psQuestions,
    isFetching: isPSFetching,
    refetch: getPSQuestion,
  } = useAuditQuestions('PRODUCTIONSTORAGE', true, false);
  const { state } = useLocation();
  const { authState } = useOktaAuth();
  const [initialData, setInitialData] = useState<Partial<GrowerAudit>>({});
  const [isLoading, setLoadingState] = useState(false);
  const [isScheduledAudit, setScheduledAuditState] = useState(true);

  useEffect(() => {
    if (poQuestions && !initialData?.productionOnly) {
      setInitialData((previousData: Partial<GrowerAudit> = {}) => ({
        ...previousData,
        [PRODUCTION_ONLY]: previousData.productionOnly || setAuditQuestions(poQuestions),
      }));
    }
  }, [poQuestions, initialData]);

  useEffect(() => {
    if (grower?.growerType === 'PRODUCTIONSTORAGE') {
      const hasPSQuestions = !!initialData?.productionStorage?.length;
      if (!isPSFetching && !hasPSQuestions && !psQuestions) {
        getPSQuestion();
      } else if (!isPSFetching && psQuestions && !hasPSQuestions) {
        setInitialData((previousData: Partial<GrowerAudit> = {}) => ({
          ...previousData,
          [PRODUCTION_STORAGE]: setAuditQuestions(psQuestions),
        }));
      }
    }
  }, [
    getPSQuestion,
    grower?.growerType,
    isPSFetching,
    psQuestions,
    initialData?.productionStorage,
  ]);

  const handleStartNewAudit = useCallback(() => {
    setInitialData((previousData: Partial<GrowerAudit> = {}) => ({
      ...previousData,
      auditType: grower?.growerType,
      igasNumber: Number(grower?.igasNumber?.replaceAll(/[a-zA-Z\t ]+/g, '')),
    }));
  }, [grower?.growerType, grower?.igasNumber]);

  const handleLatestAudit = useCallback((latestAudit: GrowerAudit) => {
    const newAudit = (Object.keys(latestAudit) as unknown as (keyof GrowerAudit)[])
      .reduce<Partial<GrowerAudit>>((acc: Partial<GrowerAudit>, key: keyof GrowerAudit) => {
      if (
        latestAudit[key] === null || (typeof latestAudit[key] === 'object' && Object.keys(latestAudit[key]!)?.length === 0)
      ) {
        return acc;
      }
      return { ...acc, [key]: latestAudit[key] };
    }, {});
    setInitialData((previousData: Partial<GrowerAudit> = {}) => ({
      ...previousData,
      ...newAudit as unknown as GrowerAudit,
    }));
  }, []);

  const handleGetLatestAudit = useCallback(() => (
    GrowerAuditService.getLatestAudit(
      (state as GrowerAuditStateTypes).igasNumber!,
      authState!,
    ).then((latestAudit) => {
      if (latestAudit?.auditID) {
        handleLatestAudit(latestAudit);
      } else {
        setScheduledAuditState(false);
        handleStartNewAudit();
      }
    })
  ), [authState, handleLatestAudit, handleStartNewAudit, state]);

  const handleGetAuditByID = useCallback(() => (
    GrowerAuditService.getByAuditID(
      (state as GrowerAuditStateTypes).auditID!,
      authState!,
    ).then((audit) => setInitialData(audit))
  ), [authState, state]);

  useEffect(() => {
    setLoadingState(true);
    let promise;
    if ((state as GrowerAuditStateTypes).auditID) {
      promise = handleGetAuditByID();
    } else {
      promise = handleGetLatestAudit();
    }
    promise.finally(() => setLoadingState(false));
    // execute once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNewAudit = useCallback(() => {
    setScheduledAuditState(true);
    handleStartNewAudit();
  }, [handleStartNewAudit]);

  const handleSaveAudit = useCallback(
    (data: GrowerAudit): Promise<GrowerAudit> => GrowerAuditService.create(data, authState!),
    [authState],
  );

  const handleGetAudit = useCallback(
    (auditID: number): Promise<GrowerAudit> => GrowerAuditService.getByAuditID(auditID, authState!),
    [authState],
  );

  const handleUpdateAudit = useCallback(
    (data: GrowerAudit): Promise<void> => GrowerAuditService.update(data, authState!),
    [authState],
  );

  const handleSendEmail = useCallback(
    (auditID?: number): Promise<void> => (
      GrowerAuditService.sendAuditEmail(auditID!, authState!)
    ),
    [authState],
  );

  return (
    <PageLoadingComponent
      isLoading={
        isLoading
        || !initialData?.productionOnly?.length
        || (
          grower?.growerType === 'PRODUCTIONSTORAGE'
          && !initialData?.productionStorage?.length
        )
      }
    >
      {!isScheduledAudit ? (
        <NotAuditable onClick={handleNewAudit} />
      ) : (
        <AuditDataProvider
          initialData={initialData}
          schema={ZGrowerAudit}
          onCreate={handleSaveAudit}
          onGet={handleGetAudit}
          onSendEmail={handleSendEmail}
          onUpdate={handleUpdateAudit}
        >
          <Stack spacing={1} justifyContent="center" alignItems="center" mt={1} mb={1}>
            <div>
              <GrowerActiveChip
                firstname={grower?.firstname}
                surname={grower?.surname}
                active={grower?.active}
              />
            </div>
            <span className={styles.name}>
              {grower?.firstname}
              {' '}
              {grower?.surname}
            </span>
            <span>{getAddressDisplayName(grower?.address)}</span>
          </Stack>
          <GrowerAuditForm />
        </AuditDataProvider>
      )}
    </PageLoadingComponent>
  );
}

GrowerAudits.defaultProps = {
  grower: undefined,
};

export default GrowerAudits;
