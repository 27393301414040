import React, { useEffect, useState, useCallback } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { Table, SelectField } from '../../components';
import NewAuditQuestion, { DEFAULT_QUESTION } from './components/NewAuditQuestion/NewAuditQuestion';
import { AuditQuestionService } from '../../services/Audit';
import { AuditQuestion, AuditType } from '../../model/Audit';
import styles from './AuditQuestionsPage.module.scss';
import config from '../../config.json';

function AuditQuestionsPage() {
  const [auditQuestions, setAuditQuestions] = useState<AuditQuestion[]>([]);
  const [selectedQuestion, setSelectedQuestion] = useState<AuditQuestion | undefined>();
  const [selectedQuestionSet, setSelectedQuestionSet] = useState<AuditType>('PRODUCTIONONLY');
  const [isLoading, setLoadingState] = useState(false);
  const [isModalOpen, setModalState] = useState(false);
  const { authState } = useOktaAuth();

  useEffect(() => {
    setLoadingState(true);
    AuditQuestionService.getAllPO(authState!)
      .then(setAuditQuestions)
      .finally(() => setLoadingState(false));
  }, [authState]);

  const toggleModal = useCallback(() => {
    setModalState(!isModalOpen);
  }, [isModalOpen]);

  const handleSelect = useCallback((selectedID: number) => {
    setModalState(true);
    setSelectedQuestion(
      auditQuestions.find((question) => question.questionID === selectedID),
    );
  }, [auditQuestions]);

  const handleQuestionValueChange = useCallback((value: AuditType) => {
    setSelectedQuestionSet(value);
    setLoadingState(true);
    switch (value) {
      case 'PRODUCTIONSTORAGE':
        AuditQuestionService.getAllPS(authState!)
          .then(setAuditQuestions)
          .finally(() => setLoadingState(false));
        break;
      case 'STORAGELOCATION':
        AuditQuestionService.getAllSL(authState!)
          .then(setAuditQuestions)
          .finally(() => setLoadingState(false));
        break;
      case 'ISOMERCHANT':
        AuditQuestionService.getAllISOM(authState!)
          .then(setAuditQuestions)
          .finally(() => setLoadingState(false));
        break;
      case 'SLVISUAL':
        AuditQuestionService.getAllSLV(authState!)
          .then(setAuditQuestions)
          .finally(() => setLoadingState(false));
        break;
      default:
        AuditQuestionService.getAllPO(authState!)
          .then(setAuditQuestions)
          .finally(() => setLoadingState(false));
        break;
    }
  }, [authState]);

  const handleClose = useCallback(() => {
    toggleModal();
    handleQuestionValueChange(selectedQuestionSet);
  }, [handleQuestionValueChange, selectedQuestionSet, toggleModal]);

  const handleAddQuestion = useCallback(() => {
    setSelectedQuestion(undefined);
    toggleModal();
  }, [toggleModal]);

  const handleDrag = useCallback((newDataOrder: AuditQuestion[]) => {
    setLoadingState(true);
    const reorderedData = newDataOrder.map((question, index) => (
      { ...question, questionOrder: index }
    ));
    setAuditQuestions(reorderedData);
    AuditQuestionService.updateOrder(reorderedData, authState!)
      .finally(() => setLoadingState(false));
  }, [authState]);

  return (
    <>
      <SelectField label="Audit Type" onValueChange={handleQuestionValueChange} availableValues={config.typelist.auditType} value={selectedQuestionSet} id="auditTypeQuestion" />
      <Fab color="primary" className={styles.addButton} onClick={handleAddQuestion} id="addNewQuestion">
        <AddIcon />
      </Fab>
      <Table
        metadata={config.auditQuestion.table}
        data={auditQuestions}
        isLoading={isLoading}
        onSelect={handleSelect}
        onDrag={handleDrag}
      />
      {isModalOpen && (
        <NewAuditQuestion
          question={selectedQuestion || {
            ...DEFAULT_QUESTION, questionOrder: auditQuestions.length,
          }}
          onSubmit={handleClose}
          onClose={toggleModal}
        />
      )}
    </>
  );
}

export default AuditQuestionsPage;
