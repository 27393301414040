import * as z from 'zod';

export const ZAuditCount = z.object({
  totalAudits: z.number(),
  completedAudits: z.number(),
  toBeApprovedAudits: z.number(),
  draftAudits: z.number(),
  assignedAudits: z.number(),
});

export type AuditCount = z.infer<typeof ZAuditCount>;
