import { AuthState } from '@okta/okta-auth-js';
import { getUrl, getHeaders, checkStatus } from './util/TransferUtil';
import { handleResponse } from './BaseService';

function createURLFromBlob(data: any): Promise<string> {
  return Promise.resolve(data)
    .then((response) => response.blob())
    .then((blob) => window.URL.createObjectURL(blob));
}

export function downloadResponse(url: string, filename: string): void {
  const a = document.createElement('a');

  a.href = url;
  a.target = '_blank';
  a.download = filename;

  document.body.appendChild(a);

  a.click();
  a.remove();
}

export default {
  get: (
    endpoint: string,
    params: Record<string, string | number>,
    filename: string,
    auth: AuthState,
  ): Promise<void> => {
    const requestOptions = {
      method: 'GET',
      headers: getHeaders(auth),
    };

    const composedURL = getUrl({ endpoint, params });

    return fetch(composedURL, requestOptions)
      .then(checkStatus)
      .then(createURLFromBlob)
      .then((blobURL: string) => downloadResponse(blobURL, filename));
  },
  send: (
    endpoint: string,
    file: File,
    fileMetadata: Record<string, string | number>,
    auth: AuthState,
  ): Promise<void> => {
    const { filename } = fileMetadata;

    const data = new FormData();
    data.append('file', file);
    data.append('metadata', new Blob([JSON.stringify(fileMetadata)], {
      type: 'application/json',
    }));

    const headers = getHeaders(auth);
    headers.delete('Content-Type');

    const requestOptions = {
      method: 'POST',
      body: data,
      headers,
    };

    const composedURL = getUrl({ endpoint });

    return fetch(composedURL, requestOptions)
      .then(checkStatus)
      .then(createURLFromBlob)
      .then((blobURL: string) => downloadResponse(blobURL, filename as string));
  },
  sendFileAndData: <T> (
    endpoint: string,
    file: File,
    fileMetadata: Record<string, string | number>,
    auth: AuthState,
  ): Promise<T> => {
    const data = new FormData();
    data.append('file', file);
    data.append('metadata', new Blob([JSON.stringify(fileMetadata)], {
      type: 'application/json',
    }));

    const headers = getHeaders(auth);
    headers.delete('Content-Type');

    const requestOptions = {
      method: 'POST',
      body: data,
      headers,
    };

    const composedURL = getUrl({ endpoint });

    return fetch(composedURL, requestOptions)
      .then(checkStatus)
      .then(handleResponse) as Promise<T>;
  },
  sendFilesJson: <T> (
    endpoint: string,
    files: File[],
    auth: AuthState,
  ): Promise<T> => {
    const data = new FormData();

    files.forEach((file) => {
      data.append('files', file);
    });

    const headers = getHeaders(auth);
    headers.delete('Content-Type');

    const requestOptions = {
      method: 'POST',
      body: data,
      headers,
    };

    const composedURL = getUrl({ endpoint });

    return fetch(composedURL, requestOptions)
      .then(checkStatus)
      .then(handleResponse) as Promise<T>;
  },
  downloadLink: (
    endpoint: string,
    id: number,
    filename: string,
    auth: AuthState,
  ): Promise<void> => {
    const requestOptions = {
      method: 'GET',
      headers: getHeaders(auth),
    };

    const params = { id };
    const composedURL = getUrl({ endpoint, params });

    return fetch(composedURL, requestOptions)
      .then(checkStatus)
      .then(handleResponse)
      .then((key: unknown) => downloadResponse((key as { data: string }).data, filename));
  },
};
