import React, {
  useCallback,
  useMemo,
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { useOktaAuth } from '@okta/okta-react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { MerchantContactService } from '../../../services/Merchant';
import {
  PageLoadingComponent,
  useModal,
  Table,
} from '../../../components';
import { MerchantContactFields } from './components';
import config from '../../../config.json';
import useUserAccess from '../../../hooks/useUserAccess/useUserAccess';
import styles from './MerchantContact.module.scss';

function MerchantContact(props) {
  const { merchantID } = props;
  const [isLoading, setLoadingState] = useState(false);
  const [selectedContact, setSelectedContact] = useState();
  const [contacts, setContacts] = useState([]);
  const { authState } = useOktaAuth();
  const ModalService = useModal();

  const DEFAULT_NEW_CONTACT = useMemo(() => ({
    active: true,
    merchantID,
  }), [merchantID]);

  const { isAdmin } = useUserAccess();

  const handleError = useCallback(() => {
    setLoadingState(false);
    ModalService.openModal({
      title: 'Error Occur',
      description: 'An Error occurred. Please try again.',
    });
  }, [ModalService]);

  const getContacts = useCallback(() => {
    setLoadingState(true);
    (
      isAdmin
        ? MerchantContactService.get(merchantID, authState)
        : MerchantContactService.get(merchantID, authState, 'QUALITY')
    ).then(setContacts)
      .catch(handleError)
      .finally(() => setLoadingState(false));
  }, [authState, handleError, isAdmin, merchantID]);

  useEffect(() => {
    getContacts();
  }, [getContacts]);

  const handleSaveContact = useCallback(() => {
    setLoadingState(true);
    return (
      selectedContact?.merchantContactID
        ? MerchantContactService.update(selectedContact, authState)
        : MerchantContactService.create(selectedContact, authState)
    ).then(getContacts)
      .catch(handleError)
      .finally(() => {
        setLoadingState(false);
        setSelectedContact();
      });
  }, [selectedContact, authState, getContacts, handleError]);

  const handleSelect = useCallback((selectedID) => {
    setSelectedContact(
      contacts.find(({ merchantContactID }) => merchantContactID === selectedID),
    );
  }, [contacts]);

  const handleAdd = useCallback(() => {
    setSelectedContact({ ...DEFAULT_NEW_CONTACT });
  }, [DEFAULT_NEW_CONTACT]);

  return (
    <>
      <PageLoadingComponent isLoading={isLoading} />
      <List className={styles.columns}>
        <ListItem>
          <Typography variant="h5" component="h1" className={styles.title}>
            Merchant Contact Info
            <Fab color="primary" className={styles.addButton} onClick={handleAdd} id="addMerchantContact">
              <AddIcon />
            </Fab>
          </Typography>
        </ListItem>
        <Table
          metadata={config.merchantContacts.table}
          data={contacts}
          onSelect={handleSelect}
        />
      </List>
      <MerchantContactFields
        contact={selectedContact}
        updateContact={setSelectedContact}
        onSave={handleSaveContact}
      />
    </>
  );
}

MerchantContact.propTypes = {
  merchantID: PropTypes.string,
};

MerchantContact.defaultProps = {
  merchantID: undefined,
};

export default MerchantContact;
