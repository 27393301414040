import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
} from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import _ from 'lodash';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Button from '@mui/material/Button';

import InputField from '../../components/InputField/InputField';
import SelectField from '../../components/SelectField/SelectField';
import CheckboxField from '../../components/CheckboxField/CheckboxField';
import AddressComponent from '../../components/AddressComponent/AddressComponent';
import { DateField } from '../../components';
import useUserAccess from '../../hooks/useUserAccess/useUserAccess';
import styles from './PersonalInfo.module.scss';
import config from '../../config.json';

function PersonalInfo(props) {
  const {
    info, onValueChange, onSaveGrower, isSaveDisabled,
    isAdding, primaryMerchant, auditYears,
  } = props;
  const navigate = useNavigate();
  const [auditID, setAuditID] = useState();

  const { isAdmin } = useUserAccess();

  useEffect(() => {
    setAuditID(auditYears ? auditYears[0]?.auditID : undefined);
  }, [auditYears]);

  const handleAuditYearChange = useCallback((value) => {
    setAuditID(value);
  }, []);

  const onAuditView = useCallback(() => {
    navigate('/growerDetail/audit', {
      state: {
        auditID,
        igasNumber: info?.igasNumber,
      },
    });
  }, [auditID, navigate, info]);

  const availableAudits = useMemo(() => (
    auditYears.map((previousAudit) => ({
      label: previousAudit.harvestYear,
      code: previousAudit.auditID,
    }))), [auditYears]);

  const firstYearRegisteredAvailableValues = useMemo(() => {
    const currentYear = new Date().getFullYear();
    const range = _.range(currentYear + 2, 2000);
    const availableValues = range.map((year) => ({
      label: year.toString(),
      code: year,
    }));
    return [...availableValues, {
      label: '20 (Please change this)',
      code: 20,
    }];
  }, []);

  return (
    <div className={styles.container}>
      <List className={styles.columns}>
        {!isAdding ? (
          <ListItem>
            <InputField
              id="igasNumberField"
              label="IGAS Number"
              value={info?.igasNumber}
              className={styles.items}
              disabled
            />
          </ListItem>
        ) : null}
        <ListItem>
          <InputField
            id="firstnameField"
            label="Firstname"
            value={info?.firstname}
            className={styles.items}
            onValueChange={onValueChange}
            path="firstname"
            required={isAdding}
          />
        </ListItem>
        <ListItem>
          <InputField
            id="surnameField"
            label="Surname"
            value={info?.surname}
            className={styles.items}
            onValueChange={onValueChange}
            path="surname"
            required={isAdding}
          />
        </ListItem>
        <ListItem>
          <InputField
            id="companyNameField"
            label="Company Name"
            value={info?.companyName}
            className={styles.items}
            onValueChange={onValueChange}
            path="companyName"
          />
        </ListItem>
        <ListItem>
          <InputField
            id="phoneNumberField"
            label="Phone Number"
            value={info?.phoneNumber}
            className={styles.items}
            onValueChange={onValueChange}
            path="phoneNumber"
          />
        </ListItem>
        <ListItem>
          <InputField
            id="landlineNumberField"
            label="Landline Number"
            value={info?.landlineNumber}
            className={styles.items}
            onValueChange={onValueChange}
            path="landlineNumber"
          />
        </ListItem>
        <ListItem>
          <InputField
            id="emailField"
            label="Email"
            value={info?.email}
            className={styles.items}
            onValueChange={onValueChange}
            path="email"
          />
        </ListItem>
        <ListItem>
          <CheckboxField
            id="activeField"
            label="Active"
            value={info?.active}
            className={styles.items}
            onValueChange={onValueChange}
            path="active"
            required={isAdding}
            disabled={!isAdmin}
          />
        </ListItem>
      </List>
      <List className={styles.columns}>
        <AddressComponent
          address={info?.address}
          seperatorComponent={ListItem}
          className={styles.items}
          onValueChange={onValueChange}
          path="address"
        />
      </List>
      <List className={styles.columns}>
        <ListItem>
          <SelectField
            id="paymentMethodField"
            label="Payment Method"
            name="payment_method"
            helpText={info?.paymentMethod === 'MERCHANT' ? primaryMerchant?.merchant?.name : null}
            value={info?.paymentMethod}
            className={styles.items}
            onValueChange={onValueChange}
            path="paymentMethod"
            availableValues={config.typelist.paymentMethod}
            required={isAdding}
            disabled={!isAdmin}
          />
        </ListItem>
        <ListItem>
          <SelectField
            id="growerTypeField"
            label="Grower Type"
            name="growerType"
            value={info?.growerType}
            className={styles.items}
            onValueChange={onValueChange}
            path="growerType"
            availableValues={config.typelist.growerType}
            required={isAdding}
            disabled={!isAdmin}
          />
        </ListItem>
        <ListItem>
          <SelectField
            id="firstYearRegistered"
            label="First Harvest Year"
            name="firstYearRegistered"
            value={info?.firstYearRegistered}
            className={styles.items}
            onValueChange={onValueChange}
            path="firstYearRegistered"
            availableValues={firstYearRegisteredAvailableValues}
            required={isAdding}
            disabled={!isAdmin}
            // disabled={!isAdding}
          />
        </ListItem>
        {!isAdding ? (
          <ListItem>
            <DateField
              id="joinDateField"
              label="Join Date"
              name="joinDate"
              value={info?.joinDate}
              className={styles.items}
              onValueChange={onValueChange}
              path="joinDate"
              disabled
            />
          </ListItem>
        ) : null}
        <ListItem>
          <InputField
            id="applicationNumberField"
            label="Application Number"
            value={info?.applicationNumber}
            className={styles.items}
            onValueChange={onValueChange}
            type="number"
            path="applicationNumber"
            disabled={!isAdmin}
          />
        </ListItem>
        <ListItem>
          <InputField
            id="deptAgriNumberField"
            label="Dept Agri Number"
            value={info?.deptAgriNumber}
            className={styles.items}
            onValueChange={onValueChange}
            path="deptAgriNumber"
            disabled={!isAdmin}
          />
        </ListItem>
        <ListItem>
          <SelectField
            id="sizeCategoryField"
            label="Size Category"
            value={info?.sizeCategory}
            className={styles.items}
            onValueChange={onValueChange}
            path="sizeCategory"
            availableValues={config.typelist.sizeCategory}
            required={isAdding}
            disabled={!isAdmin}
          />
          <InputField
            id="areaGrownField"
            label="Area Grown"
            value={info?.areaGrown}
            className={classNames(styles.items, styles.seperator)}
            onValueChange={onValueChange}
            type="number"
            path="areaGrown"
          />
        </ListItem>
        {
          !isAdding ? (
            <div>
              <ListItem>
                <SelectField
                  id="auditsField"
                  label="Audits"
                  value={auditID}
                  className={styles.items}
                  onValueChange={handleAuditYearChange}
                  availableValues={availableAudits}
                />
                <Button
                  id="viewAuditButton"
                  variant="contained"
                  color="primary"
                  type="submit"
                  onClick={onAuditView}
                >
                  View
                </Button>
              </ListItem>
              <ListItem>
                <Button
                  id="saveGrowerButton"
                  variant="contained"
                  color="primary"
                  type="submit"
                  onClick={onSaveGrower}
                  disabled={isSaveDisabled}
                >
                  Save Grower Info
                </Button>
              </ListItem>
            </div>
          ) : null
        }
      </List>
    </div>
  );
}

PersonalInfo.propTypes = {
  auditYears: PropTypes.arrayOf(PropTypes.shape({
    harvestYear: PropTypes.number,
    applicationNumber: PropTypes.number,
    auditID: PropTypes.number,
  })),
  info: PropTypes.shape({
    igasNumber: PropTypes.string,
    firstname: PropTypes.string,
    surname: PropTypes.string,
    companyName: PropTypes.string,
    phoneNumber: PropTypes.string,
    email: PropTypes.string,
    address: PropTypes.shape({}),
    paymentMethod: PropTypes.string,
    growerType: PropTypes.string,
    joinDate: PropTypes.string,
    applicationNumber: PropTypes.string,
    deptAgriNumber: PropTypes.string,
    sizeCategory: PropTypes.number,
    firstYearRegistered: PropTypes.number,
    areaGrown: PropTypes.number,
    landlineNumber: PropTypes.string,
    active: PropTypes.bool,
  }),
  onValueChange: PropTypes.func.isRequired,
  onSaveGrower: PropTypes.func.isRequired,
  isSaveDisabled: PropTypes.bool,
  primaryMerchant: PropTypes.shape({
    merchant: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
  isAdding: PropTypes.bool,
};

PersonalInfo.defaultProps = {
  auditYears: [],
  info: undefined,
  primaryMerchant: undefined,
  isSaveDisabled: false,
  isAdding: false,
};

export default PersonalInfo;
