import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';

import classNames from 'classnames';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ListItemButton from '@mui/material/ListItemButton';
import ListItem from '@mui/material/ListItem';
import Badge from '@mui/material/Badge';
import BarChartIcon from '@mui/icons-material/BarChart';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import TaskIcon from '@mui/icons-material/Task';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import NotesIcon from '@mui/icons-material/Notes';
import CheckIcon from '@mui/icons-material/Check';
import HelpIcon from '@mui/icons-material/Help';
import PersonIcon from '@mui/icons-material/Person';
import BusinessIcon from '@mui/icons-material/Business';
import AuditorIcon from '@mui/icons-material/HowToReg';
import AssignmentIcon from '@mui/icons-material/Assignment';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import AddSharpIcon from '@mui/icons-material/AddSharp';
import PostAddIcon from '@mui/icons-material/PostAdd';
import DraftsIcon from '@mui/icons-material/Drafts';
import GroupIcon from '@mui/icons-material/Group';
import Tooltip from '@mui/material/Tooltip';

import AuthUtil from '../../util/authUtil';
import { AuditAnalysisService } from '../../services/Audit';
import { useBadge, BadgeContextTypes } from '../../contexts/BadgeContext/BadgeContext';
import { GrowerAnalysisService } from '../../services/Grower';
import { GrowerAnalysis } from '../../model/Grower';
import { AuditCount } from '../../model/Audit';
import ProgressBar from '../ProgressBar/ProgressBar';
import InputField from '../InputField/InputField';
import styles from './Menu.module.scss';

type LinkTypes = {
  key: string;
  title: string;
  icon: React.ReactNode;
  link: string;
  access: string[],
  badge?: keyof BadgeContextTypes;
};

const links = [
  {
    key: 'IgasNumber',
    title: 'IGAS Number',
    icon: <PersonIcon />,
    link: '/',
    access: ['GrowerAuditEnterer'],
  },
  {
    key: 'SearchGrower',
    title: 'Search for Grower',
    icon: <PersonIcon />,
    link: '/',
    access: ['Admin', 'Auditor'],
  },
  {
    key: 'SearchMerchant',
    title: 'Search for Merchant',
    icon: <BusinessIcon />,
    link: '/',
    access: ['MerchantAuditor'],
  },
  {
    key: 'AddGrower',
    title: 'Add a Grower',
    icon: <PersonAddIcon />,
    link: '/addGrowerWizard',
    access: ['Admin'],
  },
  {
    key: 'SearchMerchant',
    title: 'Search for Merchant',
    icon: <BusinessIcon />,
    link: '/merchantSearch',
    access: ['Admin'],
  },
  {
    key: 'AddMerchant',
    title: 'Add a Merchant',
    icon: <AddSharpIcon />,
    link: '/addMerchant',
    access: ['Admin'],
  },
  {
    key: 'AuditAssign',
    title: 'Audit Management',
    icon: <AssignmentIcon />,
    link: '/auditAssign',
    access: ['Admin'],
  },
  {
    key: 'auditQuestion',
    title: 'Audit Questions',
    icon: <HelpIcon />,
    link: '/auditQuestions',
    access: ['Admin'],
  },
  {
    key: 'Review',
    title: 'Review',
    icon: <NotesIcon />,
    link: '/review',
    access: ['Auditor', 'MerchantAuditor'],
  },
  {
    key: 'FixStorage',
    title: 'Fix Storage',
    icon: <RemoveCircleIcon />,
    link: '/clear',
    access: ['MerchantAuditor'],
  },
  {
    key: 'follow-up',
    title: 'Follow-up Audits',
    icon: <TaskIcon />,
    link: '/follow-up',
    access: ['Auditor', 'MerchantAuditor'],
    badge: 'auditFollowUpCount',
  },
  {
    key: 'draft',
    title: 'Draft Audits',
    icon: <DraftsIcon />,
    link: '/draft',
    access: ['Auditor', 'MerchantAuditor'],
  },
  {
    key: 'savedAudits',
    title: 'Local Audits',
    icon: <FormatListBulletedIcon />,
    link: '/savedAudits',
    access: ['Auditor'],
  },
  {
    key: 'recordedAudits',
    title: 'Recorded Audits',
    icon: <CheckIcon />,
    link: '/recorded/audits',
    access: ['Admin'],
    badge: 'recordedAuditCount',
  },
  {
    key: 'Auditors',
    title: 'Auditors',
    icon: <AuditorIcon />,
    link: '/auditors',
    access: ['Admin'],
  },
  {
    key: 'generateDocuments',
    title: 'Generate Documents',
    icon: <PostAddIcon />,
    link: '/generateDocuments',
    access: ['Admin'],
  },
  {
    key: 'Analytics',
    title: 'Analytics',
    icon: <BarChartIcon />,
    link: '/analytics',
    access: ['Admin'],
  },
  {
    key: 'employees',
    title: 'Employees',
    icon: <GroupIcon />,
    link: '/employees',
    access: ['Admin'],
  },
  {
    key: 'signOut',
    title: 'Log Out',
    icon: <ExitToAppIcon />,
    access: ['Auditor', 'MerchantAuditor', 'GrowerAuditEnterer', 'Admin'],
  },
] as LinkTypes[];

type MenuTypes = {
  menuCallback: (isOpen?: boolean) => void;
  isOpen: boolean;
};

function Menu(props: MenuTypes) {
  const { menuCallback, isOpen } = props;
  const navigate = useNavigate();
  const [igasNumber, updateIGASNumber] = useState<string>();
  const [auditCount, setAuditCount] = useState<AuditCount | undefined>();
  const [growerCount, setGrowerCount] = useState<GrowerAnalysis | undefined>();
  const { authState, oktaAuth } = useOktaAuth();
  const badge = useBadge();

  const hasGrowerAccess = useMemo(() => AuthUtil.hasAccess(['Admin', 'Auditor'], authState!), [authState]);

  useEffect(() => {
    if (AuthUtil.hasAccess('Admin', authState!)) {
      AuditAnalysisService.count(authState!).then(setAuditCount);
      GrowerAnalysisService.count(authState!).then(setGrowerCount);
    }
  }, [authState]);

  const handleLogout = useCallback(async () => oktaAuth.signOut(), [oktaAuth]);

  const filteredLinks = useMemo(() => (
    links.filter((link) => AuthUtil.hasAccess(link?.access, authState!)).map((link) => {
      if (badge && link.badge && badge[link.badge] && badge[link.badge] !== 0) {
        return {
          ...link,
          icon: (
            <Badge
              badgeContent={badge[link.badge] as any}
              color="primary"
            >
              {link.icon}
            </Badge>
          ),
        };
      }
      return link;
    })
  ), [authState, badge]);

  const handleSubmit = useCallback((event: any) => {
    event.preventDefault();
    const pathname = '/growerDetail';
    navigate(pathname, { state: { igasNumber }, replace: true });
    updateIGASNumber(undefined);
  }, [igasNumber, navigate]);

  const handleClick = useCallback((link: any) => () => {
    menuCallback(false);
    navigate(link);
  }, [navigate, menuCallback]);

  const menuItems = useMemo(() => filteredLinks.map((link) => {
    const regex = new RegExp(`${link.link}$`);
    const isCurrentUrl = regex.test(window.location.href);
    const className = isCurrentUrl ? styles.activeLink : '';
    if (!isOpen) {
      return (
        <ListItemButton
          key={link.key}
          id={link.key}
          onClick={link.link ? handleClick(link.link) : handleLogout}
          className={className}
        >
          <Tooltip title={link.title} arrow placement="right" className={styles.tooltip}>
            <ListItemIcon>
              {link.icon}
            </ListItemIcon>
          </Tooltip>
        </ListItemButton>
      );
    }
    return (
      <ListItem
        button
        key={link.key}
        id={link.key}
        onClick={handleClick(link.link)}
        className={className}
      >
        <ListItemIcon>
          {link.icon}
        </ListItemIcon>
        <ListItemText primary={link.title} className={styles.noTextWrap} />
      </ListItem>
    );
  // we want to refresh on each page refresh
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [navigate, isOpen, window.location.href, authState, filteredLinks]);

  const growerSearch = useMemo(() => (
    <ListItem>
      <form onSubmit={handleSubmit}>
        <InputField
          label="Enter IGAS Number"
          id="menuIGASNumberEntry"
          value={igasNumber}
          onValueChange={(value) => updateIGASNumber(value as string)}
        />
      </form>
    </ListItem>
  ), [igasNumber, handleSubmit]);

  const totalsComponent = useMemo(() => {
    if (auditCount === undefined || growerCount === undefined) {
      return null;
    }

    return (
      <div className={styles.totalsContainer}>
        <div className={styles.growerTotals}>
          <div className={styles.totals}>
            <span className={styles.growerText}>Total Growers:</span>
            <span id="totalGrowerCount">{growerCount.totalCount}</span>
          </div>
          <div className={styles.totals}>
            <span className={styles.growerText}>Total Production Only:</span>
            <span id="poGrowerCount">{growerCount.productionOnlyCount}</span>
          </div>
          <div className={styles.totals}>
            <span className={styles.growerText}>Total Production Storage:</span>
            <span id="psGrowerCount">{growerCount.productionStorageCount}</span>
          </div>
        </div>
        <ProgressBar
          id="audit"
          number={auditCount.completedAudits}
          total={auditCount.totalAudits}
          bufferValue={
            (auditCount.toBeApprovedAudits || 0)
            + (auditCount.completedAudits || 0)
            + (auditCount.draftAudits || 0)
          }
        />
      </div>
    );
  }, [auditCount, growerCount]);

  return (
    <Drawer
      variant="permanent"
      open={isOpen}
      className={classNames('drawer', {
        [styles.drawerOpen]: isOpen,
        [styles.drawerClose]: !isOpen,
      })}
    >
      <IconButton
        onClick={() => menuCallback(false)}
        className={styles.drawerToolbar}
        id="closeMenu"
      >
        <ChevronLeftIcon />
      </IconButton>
      <Divider />
      <List>
        {isOpen && hasGrowerAccess ? growerSearch : null}
        {menuItems}
      </List>
      <Divider />
      {isOpen ? totalsComponent : null}
    </Drawer>
  );
}

export default Menu;
