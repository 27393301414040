import React, { useMemo, useCallback } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { Select as MUISelect, SelectProps } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Input from '@mui/material/Input';
import InputField from '../InputField/InputField';

export type AvailableValuesType = {
  label: string;
  code: string | number;
};

type SelectTypes = SelectProps & {
  availableValues: AvailableValuesType[];
  labelClassName?: any;
  onValueChange?: (event: any) => void,
  className?: string;
  dataType?: string;
  required?: boolean;
  label: string;
  name: string;
  id?: string;
  control?: any;
  helpText?: string;
  disabled?: boolean;
};

Select.defaultProps = {
  dataType: 'string',
  control: undefined,
  className: undefined,
  onValueChange: undefined,
  required: false,
  disabled: false,
  labelClassName: undefined,
  helpText: undefined,
  id: undefined,
};

function Select(props: SelectTypes) {
  const {
    labelClassName,
    onValueChange,
    availableValues,
    defaultValue,
    className,
    dataType,
    disabled,
    helpText,
    required,
    label,
    control,
    name,
    id,
    ...rest
  } = props;

  const {
    field,
    fieldState: { error },
    // formState: { touchedFields, dirtyFields }
  } = useController({
    name,
    control,
    rules: { required },
    defaultValue,
  });
  const { trigger } = useFormContext();

  const handleChange = useCallback(async (event: any) => {
    if (onValueChange) {
      onValueChange(event);
    }
    field.onChange(event);
    trigger(name);
  }, [onValueChange, field, trigger, name]);

  const options = useMemo(() => {
    const availableValueOptions = [{ code: undefined, label: 'Please Select' }, ...availableValues].map((option) => (
      <MenuItem
        value={option.code}
        key={`${id}_${option.code}`}
      >
        {option.label}
      </MenuItem>
    ));

    return availableValueOptions;
  }, [id, availableValues]);

  if (disabled) {
    const chosenAV = availableValues.find((availableValue) => availableValue.code === field.value);
    return (
      <InputField
        label={label}
        id={id || name}
        value={chosenAV?.label}
        className={className}
        disabled
      />
    );
  }

  return (
    <FormControl className={className} required={required} error={!!error}>
      <InputLabel htmlFor={name} classes={labelClassName}>{label}</InputLabel>
      <MUISelect
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...field}
        id={id || name}
        onChange={handleChange}
        input={<Input />}
        inputProps={{
          name,
        }}
      >
        {options}
      </MUISelect>
      {
        (error?.message || helpText)
          && <FormHelperText>{error?.message || helpText}</FormHelperText>
      }
    </FormControl>
  );
}

export default React.memo(Select);
