import React, {
  useContext,
  useState,
  useCallback,
  useMemo,
} from 'react';

export type MaintainedStateContextTypes<T> = {
  setMaintainedState: (value: T, path: string) => void;
  getMaintainedState: (path: string) => T[];
};

export const MaintainedStateContext = React.createContext({
  setMaintainedState: () => {},
  getMaintainedState: () => undefined,
});

export function MaintainedStateContextProvider(props: React.PropsWithChildren<{}>) {
  const { children } = props;
  const [maintainedState, updateMaintainedState] = useState<Record<string, any>>({});

  const getMaintainedState = useCallback(
    (path: string) => maintainedState[path],
    [maintainedState],
  );

  const setMaintainedState = useCallback((value: any, path: string) => {
    updateMaintainedState((previousMaintainedState) => ({
      ...previousMaintainedState,
      [path]: value,
    }));
  }, []);

  const maintainedStateContextObject = useMemo(() => ({
    setMaintainedState,
    getMaintainedState,
  }), [
    setMaintainedState,
    getMaintainedState,
  ]);

  return (
    <MaintainedStateContext.Provider value={maintainedStateContextObject as any}>
      {children}
    </MaintainedStateContext.Provider>
  );
}

export function useMaintainedState<T>() {
  return useContext<MaintainedStateContextTypes<T>>(MaintainedStateContext as any);
}
