/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useCallback, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import Paper from '@mui/material/Paper';
import { GrowerDocumentService } from '../../services/Grower';
import { AuditService, AuditListService } from '../../services/Audit';
import { AuditorService } from '../../services/Auditor';
import {
  Button,
  useModal,
  DateField,
  SelectField,
  PageLoadingComponent,
} from '../../components';
import { AnnualInvoicesComponent } from '../../generationComponents';
import styles from './GenerateDocumentPage.module.scss';
import { AvailableValue } from '../../model/AvailableValues';

function GenerateDocumentPage() {
  const [entryDate, setEntryDate] = useState<string | undefined>();
  const [auditors, updateAuditors] = useState<AvailableValue[]>([]);
  const [selectedAuditor, updateSelectedAuditor] = useState<string | number>('all');
  const [isLoading, setLoadingState] = useState<boolean>(false);
  const [auditYears, setAuditYears] = useState<AvailableValue[]>([]);
  const [harvestYear, setHarvestYear] = useState<number | undefined>();
  const { authState } = useOktaAuth();
  const ModalService = useModal();

  useEffect(() => {
    AuditService.getAuditYears(authState!).then((audits) => {
      setHarvestYear(audits ? audits[0]?.harvestYear : undefined);
      const previousAudits: AvailableValue[] = audits.map((audit) => ({
        label: audit.harvestYear.toString(),
        code: audit.harvestYear,
      }));
      setAuditYears(previousAudits);
    });
    AuditorService.getActive(authState!).then((activeAuditors) => {
      const formattedAuditors: AvailableValue[] = activeAuditors.map((activeAuditor) => ({
        label: `${activeAuditor.firstname} ${activeAuditor.surname}`,
        code: activeAuditor.auditorID!,
      }));
      updateAuditors([{ label: 'All', code: 'all' }, ...formattedAuditors]);
    });
  }, [authState]);

  const generateDocuments = useCallback((selectedDocument: string) => {
    setLoadingState(true);
    const growerPromise = selectedDocument === 'labels'
      ? GrowerDocumentService.generateLabels(entryDate, 'GrowerLabels.docx', authState)
      : GrowerDocumentService.generateMultipleRegistrationLetter(entryDate, 'GrowerRegistrationLetters.docx', authState);

    growerPromise.catch(() => {
      ModalService!.openModal({
        title: 'Error Occurred',
        description: `Seems like no growers have been added since ${entryDate}`,
      });
    }).finally(() => {
      setLoadingState(false);
    });
  }, [ModalService, entryDate, authState]);

  const generateAuditorsAuditList = useCallback(() => {
    setLoadingState(true);
    const auditor = selectedAuditor !== 'all' ? selectedAuditor as unknown as number : undefined;
    AuditListService.auditorList(harvestYear!, auditor, 'AuditList.docx', authState!).catch(() => {
      ModalService!.openModal({
        title: 'Error Occurred',
        description: 'An error occurred generate audit list for auditors.',
      });
    }).finally(() => {
      setLoadingState(false);
    });
  }, [ModalService, authState, harvestYear, selectedAuditor]);

  const generateAuditNotificationForLetterAuditors = useCallback(() => {
    setLoadingState(true);
    const auditor = selectedAuditor !== 'all' ? selectedAuditor as unknown as number : undefined;
    AuditListService.notification(harvestYear!, auditor, 'AuditListNotification.docx', authState!).catch(() => {
      ModalService!.openModal({
        title: 'Error Occurred',
        description: 'An error occurred generate audit notification letter for auditors.',
      });
    }).finally(() => {
      setLoadingState(false);
    });
  }, [ModalService, authState, harvestYear, selectedAuditor]);

  const generateCancellationDocument = useCallback(() => {
    ModalService!.openModal({
      title: 'Are you sure?',
      description: 'Clicking ok will send a text to all of the cancelled growers within the date range. Are you sure you want to proceed?',
    }).then(() => {
      setLoadingState(true);
      GrowerDocumentService.notifyGrowersOfCancellation(entryDate, 'CancelledGrowerLetters.docx', authState).catch(() => {
        ModalService!.openModal({
          title: 'Error Occurred',
          description: 'An error occurred generating cancellation documents.',
        });
      }).finally(() => {
        setLoadingState(false);
      });
    });
  }, [ModalService, entryDate, authState]);

  const renewalNotice = useCallback(() => {
    ModalService!.openModal({
      title: 'Are you sure?',
      description: 'Clicking ok will send a text to all of the growers. Are you sure you want to proceed?',
    }).then(() => {
      setLoadingState(true);
      GrowerDocumentService.renewalNotice('RenewalNotice.xlsx', authState).catch(() => {
        ModalService!.openModal({
          title: 'Error Occurred',
          description: 'Ensure this hasn\'t ran and try again. If it has run, please contact the developer.',
        });
      }).finally(() => {
        setLoadingState(false);
      });
    });
  }, [ModalService, authState]);

  return (
    <div className={styles.container}>
      <PageLoadingComponent isLoading={isLoading || !harvestYear} />
      <Paper className={styles.differentSection}>
        <DateField onValueChange={(value) => setEntryDate(value)} value={entryDate} />
        <Button onClick={() => generateDocuments('labels')} id="generateLabels">Labels</Button>
        <Button onClick={() => generateDocuments('growerRegistrationLetter')} id="generateRegistrationLetters">Registration Document</Button>
        <p>
          We wish to remind you that your merchant "nameOfPrimaryMerchant" cancelled
          your membership of IGAS because you didn't supply grain to them.
          Please contact us on 059 863 3166 to renew your membership. "igasNumber"
        </p>
        <Button onClick={generateCancellationDocument}>Cancellation Texts/Document</Button>
      </Paper>
      <Paper className={styles.differentSection}>
        <p>
          Your membership of the Irish Grain Assurance Scheme has been renewed.
          The "currentYear" fee of €"amountOwed" + VAT will shortly be deducted by
          "nameOfMerchant or 'the same process as last year'". "igasNumber"
        </p>
        <Button onClick={renewalNotice}>Renewal Notice</Button>
      </Paper>
      <Paper className={styles.differentSection}>
        <SelectField
          label="Select Audit Year"
          value={harvestYear}
          name="harvestYear"
          onValueChange={setHarvestYear}
          availableValues={auditYears}
        />
        <br />
        <SelectField
          label="Select Auditor"
          value={selectedAuditor}
          name="selectedAuditor"
          onValueChange={updateSelectedAuditor}
          availableValues={auditors}
        />
        <Button onClick={generateAuditorsAuditList} id="retrieveAuditList">
          Audit List for Auditors
        </Button>
        <Button onClick={generateAuditNotificationForLetterAuditors} id="retrieveAuditLetters">
          Audit Notification Letter for Auditors
        </Button>
      </Paper>
      <AnnualInvoicesComponent setLoadingState={setLoadingState} />
    </div>
  );
}

export default GenerateDocumentPage;
