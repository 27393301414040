import React, {
  useEffect, useState, useCallback, useMemo,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { useOktaAuth } from '@okta/okta-react';
import { List, ListItem, Button } from '@mui/material';
import { zodResolver } from '@hookform/resolvers/zod';
import { EmployeeService } from '../../services/Employee';
import {
  Checkbox, Input, PageLoadingComponent, Select,
} from '../../components';
import { Employee, ZEmployee } from '../../model/Employee';
import styles from './EmployeePage.module.scss';
import config from '../../config.json';

type StateType = {
  employeeID?: string;
};

const defaultValue: Partial<Employee> = {
  role: 'Admin',
  active: true,
};

function EmployeePage(): React.ReactElement {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [isLoading, setLoadingState] = useState(true);
  const { authState } = useOktaAuth();
  const methods = useForm<Employee>({ mode: 'onBlur', resolver: zodResolver(ZEmployee) });
  const { handleSubmit, reset, formState: { isValid, isDirty } } = methods;

  const isNewEmployee = useMemo(() => !(state as StateType)?.employeeID, [state]);

  useEffect(() => {
    if ((state as StateType)?.employeeID) {
      setLoadingState(true);
      EmployeeService.get((state as StateType).employeeID!, authState!)
        .then((data) => {
          reset(data);
        })
        .finally(() => setLoadingState(false));
    } else {
      reset(defaultValue);
      setLoadingState(false);
    }
  }, [state, authState, reset]);

  const onSubmit: SubmitHandler<Employee> = useCallback((data) => {
    if (!isNewEmployee) {
      setLoadingState(true);
      EmployeeService.update(data, authState!)
        .finally(() => {
          setLoadingState(false);
          navigate('/employees');
        });
    } else {
      setLoadingState(true);
      EmployeeService.create(data, authState!)
        .finally(() => {
          setLoadingState(false);
          navigate('/employees');
        });
    }
  }, [authState, isNewEmployee, navigate]);

  return (
    <PageLoadingComponent isLoading={isLoading}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <List>
            <ListItem className={styles.itemContainer}>
              <Input label="First Name" className={styles.items} name="firstname" />
            </ListItem>
            <ListItem className={styles.itemContainer}>
              <Input label="Surname" className={styles.items} name="surname" />
            </ListItem>
            <ListItem className={styles.itemContainer}>
              <Input label="Email" className={styles.items} name="email" />
            </ListItem>
            <ListItem className={styles.itemContainer}>
              <Input label="IGAS Email" className={styles.items} name="igasEmail" />
            </ListItem>
            <ListItem className={styles.itemContainer}>
              <Input label="Phone Number" className={styles.items} name="phoneNumber" />
            </ListItem>
            <ListItem className={styles.itemContainer}>
              <Select label="Role" className={styles.items} name="role" availableValues={config.typelist.employeeTypes} />
            </ListItem>
            <ListItem className={styles.itemContainer}>
              <Checkbox label="Active" className={styles.items} name="active" />
            </ListItem>
          </List>
          {(state as StateType)?.employeeID ? (
            <Button variant="contained" color="primary" type="submit" id="updateEmployee" disabled={!isValid || !isDirty}>Update</Button>
          ) : (
            <Button variant="contained" color="primary" type="submit" id="addEmployee" disabled={!isValid || !isDirty}>Add</Button>
          )}
        </form>
      </FormProvider>
    </PageLoadingComponent>
  );
}

export default EmployeePage;
