import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import InputField from '../../components/InputField/InputField';
import styles from './GrowerNumberPage.module.scss';

function GrowerNumberPage() {
  const navigate = useNavigate();
  const [igasNumber, updateIGASNumber] = useState<string>();

  const handleSubmit = useCallback((event: any) => {
    event.preventDefault();
    const pathname = '/growerDetail';
    navigate(pathname, { state: { igasNumber }, replace: true });
    updateIGASNumber(undefined);
  }, [igasNumber, navigate]);

  const handleChange = useCallback((value: any) => {
    updateIGASNumber(value);
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <InputField
        label="Enter IGAS Number"
        id="menuIGASNumberEntry"
        value={igasNumber}
        className={styles.igasNumber}
        onValueChange={handleChange}
      />
    </form>
  );
}

export default GrowerNumberPage;
