import React from 'react';
import Paper from '@mui/material/Paper';

type TabPanelTypes = {
  children: React.ReactElement;
  value: string | number;
  className?: string;
};

function TabPanel(props: TabPanelTypes) {
  const {
    children, value, className, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      id={`scrollable-prevent-tabpanel-${value}`}
      aria-labelledby={`scrollable-prevent-tab-${value}`}
      {...other}
    >
      <Paper className={className}>{children}</Paper>
    </div>
  );
}

export default TabPanel;
