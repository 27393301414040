import React, {
  useCallback,
  useMemo,
  useState,
  useEffect,
} from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useOktaAuth } from '@okta/okta-react';
import { MerchantBranchService } from '../../../services/Merchant';
import { BranchComponent, HeadquarterComponent } from '../../../merchantComponents';
import {
  PageLoadingComponent,
} from '../../../components';

function MerchantBranch(props) {
  const { merchantID } = props;
  const navigate = useNavigate();
  const [isLoading, setLoadingState] = useState(false);
  const [branchInfo, setBranchInfo] = useState();
  const { authState } = useOktaAuth();

  const handleViewMerchant = useCallback((selectedID) => {
    navigate('/merchantDetail', {
      replace: true,
      state: {
        merchantID: selectedID,
      },
    });
  }, [navigate]);

  const getBranches = useCallback(() => (
    MerchantBranchService.getBranches(merchantID, authState)
  ), [authState, merchantID]);

  const getHeadquarter = useCallback(() => (
    MerchantBranchService.getHeadquarter(merchantID, authState)
  ), [authState, merchantID]);

  useEffect(() => {
    setLoadingState(true);
    Promise.all([
      getBranches(),
      getHeadquarter(),
    ]).then(([branches, headquarter]) => {
      setBranchInfo({
        isHeadquarter: !_.isEmpty(branches),
        isBranch: !_.isNil(headquarter),
        branches,
        headquarter,
      });
    }).finally(() => setLoadingState(false));
  }, [merchantID, authState, getBranches, getHeadquarter]);

  // from the Branch perspective
  const handleDeleteHeadquarter = useCallback(() => {
    setLoadingState(true);
    MerchantBranchService.deleteBranch(branchInfo?.headquarter?.merchantID, merchantID, authState)
      .then(getHeadquarter)
      .then((headquarter) => {
        setBranchInfo({
          ...branchInfo,
          isBranch: !_.isNil(headquarter),
          headquarter,
        });
      })
      .finally(() => setLoadingState(false));
  }, [authState, branchInfo, getHeadquarter, merchantID]);

  const handleAddBranch = useCallback((selectedID) => {
    setLoadingState(true);
    MerchantBranchService.createBranch(merchantID, selectedID, authState)
      .then(getBranches)
      .then((branches) => {
        setBranchInfo({
          ...branchInfo,
          branches,
        });
      })
      .finally(() => setLoadingState(false));
  }, [authState, branchInfo, getBranches, merchantID]);

  const content = useMemo(() => {
    if (!branchInfo) {
      return null;
    }

    if (branchInfo?.isBranch) {
      return (
        <HeadquarterComponent
          merchant={branchInfo?.headquarter}
          onSelect={handleViewMerchant}
          onDelete={handleDeleteHeadquarter}
        />
      );
    }
    return (
      <BranchComponent
        branches={branchInfo?.branches}
        onAdd={handleAddBranch}
        onSelect={handleViewMerchant}
        merchantID={merchantID}
      />
    );
  }, [branchInfo, handleAddBranch, handleViewMerchant, merchantID, handleDeleteHeadquarter]);

  return (
    <>
      <PageLoadingComponent isLoading={isLoading} />
      {content}
    </>
  );
}

MerchantBranch.propTypes = {
  merchantID: PropTypes.string,
};

MerchantBranch.defaultProps = {
  merchantID: undefined,
};

export default React.memo(MerchantBranch);
