import * as z from 'zod';

export const ZNoteType = z.enum(['AUTOMATED', 'MANUAL']);

export const ZNote = z.object({
  content: z.string().min(1),
  createdDate: z.string().optional(),
  noteID: z.number().optional(),
  noteType: ZNoteType.optional(),
});

export const ZAuditNote = ZNote.extend({
  auditID: z.number().optional(),
});

export type Note = z.infer<typeof ZNote>;
export type NoteType = z.infer<typeof ZNoteType>;
export type AuditNote = z.infer<typeof ZAuditNote>;
