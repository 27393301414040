import React, { useCallback, useEffect } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import { getFormattedDate, getDateFromString } from '../../util/dateUtils';

interface DateTypes<TDate> extends Omit<DatePickerProps<TDate>, 'onChange' | 'value'> {
  id?: string,
  name: string,
  defaultValue?: TDate,
  value?: string,
  required?: boolean,
  onChange?: (value: TDate, keyboardInputValue?: string) => void;
}

function DateField(props: DateTypes<Date>): React.ReactElement {
  const {
    format = 'dd MM yyyy',
    id,
    name,
    defaultValue,
    required,
  } = props;
  const { setValue } = useFormContext();

  const {
    field: { value, ref },
    fieldState: { error },
  } = useController({
    name,
    rules: { required },
    defaultValue,
  });

  const handleChange = useCallback((date: Date | null) => {
    setValue(name, getFormattedDate(date || undefined), {
      shouldValidate: true,
      shouldDirty: true,
    });
  }, [name, setValue]);

  const getValue = useCallback(() => {
    if (typeof value === 'string') {
      return getDateFromString(value);
    }
    return value || null;
  }, [value]);

  useEffect(() => {
    if (value === undefined || value === '') {
      handleChange(new Date());
    }
  // Set value when first entering
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DatePicker
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      format={format}
      value={getValue()}
      inputRef={ref}
      onChange={handleChange}
      slotProps={{
        textField: {
          variant: 'standard', id: id || name, error: !!error, helperText: error?.message,
        },
      }}
    />
  );
}

DateField.defaultProps = {
  defaultValue: undefined,
  onChange: undefined,
  id: undefined,
  value: undefined,
  required: false,
};

export default React.memo(DateField);
