import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useOktaAuth } from '@okta/okta-react';
import ButtonGroup from '@mui/material/ButtonGroup';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { InvoiceDirectDebitService, InvoiceChequeService, InvoiceMerchantService } from '../../services/Invoice';
import { Button, useModal } from '../../components';
import styles from './AnnualInvoicesComponent.module.scss';

// 20 seconds
const letsBeAwkward = () => setTimeout(() => 'asd', 20000);

function AnnualInvoicesComponent({ setLoadingState }) {
  const { authState } = useOktaAuth();
  const ModalService = useModal();

  const generateDirectDebit = useCallback(() => {
    setLoadingState(true);
    InvoiceDirectDebitService.validate(authState).then((invalidEntries) => {
      if (invalidEntries && invalidEntries.length > 0) {
        return ModalService.openModal({
          title: 'Invalid Entries',
          description: `There is no Big Red Cloud account for the following: ${invalidEntries.map(({ igasNumber, firstname, surname }) => `${igasNumber} ${firstname} ${surname}`).join(', ')}`,
        });
      }
      return InvoiceDirectDebitService.generateAll('DirectDebitReport.xlsx', authState);
    }).finally(() => {
      setLoadingState(false);
    });
  }, [authState, setLoadingState, ModalService]);

  const generateCheque = useCallback(() => {
    setLoadingState(true);
    InvoiceChequeService.validate(authState).then((invalidEntries) => {
      if (invalidEntries && invalidEntries.length > 0) {
        return ModalService.openModal({
          title: 'Invalid Entries',
          description: `There is no Big Red Cloud account for the following: ${invalidEntries.map(({ igasNumber, firstname, surname }) => `${igasNumber} ${firstname} ${surname}`).join(', ')}`,
        });
      }
      return InvoiceChequeService.generateAll(authState).then(letsBeAwkward);
    }).finally(() => {
      setLoadingState(false);
    });
  }, [authState, setLoadingState, ModalService]);

  const generateMerchant = useCallback(() => {
    setLoadingState(true);
    InvoiceMerchantService.validate(authState).then((invalidEntries) => {
      if (invalidEntries) {
        let description = '';
        if (invalidEntries.inactiveMerchants && invalidEntries.inactiveMerchants.length > 0) {
          description += `There is no Big Red Cloud account for the following: ${invalidEntries.inactiveMerchants.map(({ merchantID, name, numberOfGrowers }) => `IGAS${merchantID} ${name} ${numberOfGrowers} growers`).join(', ')}. `;
        }
        if (invalidEntries.orphanedGrowers && invalidEntries.orphanedGrowers.length > 0) {
          description += `There is no merchants assigned to these growers: ${invalidEntries.orphanedGrowers.map(({ igasNumber }) => igasNumber).join(', ')}. `;
        }
        if (description.length > 0) {
          return ModalService.openModal({
            title: 'Invalid Entries',
            description,
          });
        }
      }
      return InvoiceMerchantService.generateAll(authState).then(letsBeAwkward);
    }).finally(() => {
      setLoadingState(false);
    });
  }, [authState, setLoadingState, ModalService]);

  return (
    <Paper className={styles.container}>
      <Typography variant="h5" component="h1">
        Generate Annual Invoices
      </Typography>
      <Typography variant="body1">
        Ensure the automate invoice number feature is turned on.
        In Big Red Cloud check Setup -&gt; Reference settings -&gt; Sales is checked.
      </Typography>
      <ButtonGroup variant="text" color="primary" aria-label="text primary button group" className={styles.buttons}>
        <Button onClick={generateMerchant}>Trade Accounts</Button>
        <Button onClick={generateDirectDebit}>Direct Debit</Button>
        <Button onClick={generateCheque}>Cheque Account</Button>
      </ButtonGroup>
    </Paper>
  );
}

AnnualInvoicesComponent.propTypes = {
  setLoadingState: PropTypes.func.isRequired,
};

export default AnnualInvoicesComponent;
