import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import InputField from '../../components/InputField/InputField';
import CheckboxField from '../../components/CheckboxField/CheckboxField';
import { Button, SelectField } from '../../components';
import useUserAccess from '../../hooks/useUserAccess/useUserAccess';
import AddressComponent from '../../components/AddressComponent/AddressComponent';
import styles from './PersonalInfo.module.scss';

function PersonalInfo(props) {
  const {
    info, onValueChange, onSaveMerchant, isSaveDisabled, isAdding, title, auditYears,
  } = props;
  const navigate = useNavigate();
  const [merchantAuditID, setMerchantAuditID] = useState();
  const { isAdmin } = useUserAccess();

  useEffect(() => {
    setMerchantAuditID(auditYears ? auditYears[0]?.merchantAuditID : undefined);
  }, [auditYears]);

  const availableAudits = useMemo(() => (
    auditYears.map((previousAudit) => ({
      label: previousAudit.harvestYear,
      code: previousAudit.merchantAuditID,
    }))), [auditYears]);

  const onAuditView = useCallback(() => {
    navigate('/merchantDetail/audit', {
      state: {
        merchantAuditID,
        merchantID: info?.merchantID,
      },
    });
  }, [merchantAuditID, navigate, info]);

  return (
    <Paper className={styles.container}>
      <List className={classNames({ [styles.columns]: !isAdding, [styles.fullWidth]: isAdding })}>
        <ListItem>
          <Typography variant="h5" component="h1" className={styles.title}>
            {title}
          </Typography>
        </ListItem>
        <ListItem>
          <CheckboxField
            value={info.active}
            onValueChange={onValueChange}
            path="active"
            id="active"
            className={styles.items}
            label="Active"
            disabled={!isAdmin}
          />
        </ListItem>
        {!isAdding ? (
          <ListItem>
            <InputField id="merchantID" label="Merchant ID" value={info.merchantID} className={styles.items} onValueChange={onValueChange} path="merchantID" disabled />
          </ListItem>
        ) : null}
        <ListItem>
          <InputField id="merchantNameField" label="Merchant Name" value={info.name} className={styles.items} onValueChange={onValueChange} path="name" disabled={!isAdmin} />
        </ListItem>
        <ListItem>
          <InputField id="emailField" label="Email" value={info.email} className={styles.items} onValueChange={onValueChange} path="email" disabled={!isAdmin} />
        </ListItem>
        <ListItem>
          <InputField id="landlineNumberField" label="Landline Number" value={info.landlineNumber} className={styles.items} onValueChange={onValueChange} path="landlineNumber" disabled={!isAdmin} />
        </ListItem>
        <AddressComponent
          address={info.address}
          seperatorComponent={ListItem}
          className={styles.items}
          onValueChange={onValueChange}
          path="address"
          disabled={!isAdmin}
        />
        <ListItem>
          <SelectField
            id="auditsField"
            label="Audits"
            value={merchantAuditID}
            className={styles.items}
            onValueChange={(value) => setMerchantAuditID(value)}
            availableValues={availableAudits}
          />
          <Button
            id="viewAuditButton"
            variant="contained"
            color="primary"
            type="submit"
            onClick={onAuditView}
          >
            View
          </Button>
        </ListItem>
        <ListItem>
          <Button
            id="saveMerchant"
            variant="contained"
            color="primary"
            type="submit"
            className={styles.button}
            onClick={onSaveMerchant}
            disabled={isSaveDisabled || !isAdmin}
          >
            Save Merchant
          </Button>
        </ListItem>
      </List>
    </Paper>
  );
}

PersonalInfo.propTypes = {
  info: PropTypes.shape({
    contacts: PropTypes.arrayOf(PropTypes.shape({
      firstname: PropTypes.string,
      surname: PropTypes.string,
      phoneNumber: PropTypes.string,
      email: PropTypes.string,
    })),
    name: PropTypes.string,
    landlineNumber: PropTypes.string,
    merchantID: PropTypes.string,
    email: PropTypes.string,
    capacity: PropTypes.number,
    active: PropTypes.bool,
    address: PropTypes.shape({}),
  }).isRequired,
  onValueChange: PropTypes.func,
  onSaveMerchant: PropTypes.func,
  title: PropTypes.string,
  isSaveDisabled: PropTypes.bool,
  isAdding: PropTypes.bool,
  auditYears: PropTypes.arrayOf(PropTypes.shape({
    merchantAuditID: PropTypes.number,
    harvestYear: PropTypes.number,
  })),
};

PersonalInfo.defaultProps = {
  onValueChange: _.noop,
  onSaveMerchant: _.noop,
  title: 'Merchant Info',
  isSaveDisabled: false,
  isAdding: false,
  auditYears: [],
};

export default PersonalInfo;
