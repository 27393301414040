import React, { useCallback, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import { AuditorAuditsService } from '../../services/Auditor';
import { GrowerAuditService } from '../../services/Grower';
import { Grower } from '../../model/Grower';
import SearchTable from '../../components/Table';
import config from '../../config.json';
import { GrowerAudit } from '../../model/Audit';

type GrowerAuditListPageTypes = {
  type: 'follow-up' | 'draft';
};

const GrowerAuditListPage: React.FC<GrowerAuditListPageTypes> = ({ type }) => {
  const navigate = useNavigate();
  const [isLoading, setLoadingState] = useState(false);
  const [growers, setGrowers] = useState<Grower[] | GrowerAudit[]>([]);
  const { authState } = useOktaAuth();

  useEffect(() => {
    setLoadingState(true);
    let promise: Promise<Grower[] | GrowerAudit[]> = Promise.resolve([]);
    if (type === 'draft') {
      promise = GrowerAuditService.getDrafts(authState!);
    } else if (type === 'follow-up') {
      promise = AuditorAuditsService.followUp(authState!) as unknown as Promise<GrowerAudit[]>;
    }
    promise.then(setGrowers).finally(() => setLoadingState(false));
  }, [authState, type]);

  const handleSelect = useCallback((selectedID: string) => {
    navigate('/growerDetail/audit', {
      state: {
        igasNumber: selectedID,
      },
    });
  }, [navigate]);

  return (
    <SearchTable
      metadata={type === 'draft' ? config.auditorGrowerSearch.table : { ...config.recordedAudit.growerTable, selectableKey: 'igasNumber' }}
      data={growers}
      isLoading={isLoading}
      onSelect={handleSelect}
    />
  );
};

export default GrowerAuditListPage;
