import React, { useMemo } from 'react';
import { Chart } from 'react-google-charts';

type IrelandMapDataTypes = Record<string, any>;

type IrelandMapHeaderTypes = {
  title: string;
  accessor: keyof IrelandMapDataTypes;
};

export type IrelandMapTypes = {
  data: IrelandMapDataTypes[];
  headers: IrelandMapHeaderTypes[];
};

const options = {
  region: 'IE',
  resolution: 'provinces',
};

export const IrelandMap: React.FC<IrelandMapTypes> = ({ headers, data }) => {
  const chartData = useMemo(() => {
    const chartHeaders = headers.map(({ title }) => title);
    const chartsData = data.map((row) => headers.map(({ accessor }) => row[accessor]));
    return [chartHeaders, ...chartsData];
  }, [data, headers]);

  return (
    <Chart
      chartType="GeoChart"
      width="100%"
      height="400px"
      options={options}
      data={chartData}
    />
  );
};

export default IrelandMap;
