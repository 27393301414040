import * as z from 'zod';

export const ZContact = z.object({
  active: z.boolean(),
  companyRole: z.string().optional(),
  email: z.string(),
  firstname: z.string(),
  surname: z.string(),
  landlineNumber: z.string().optional(),
  phoneNumber: z.string().optional(),
  merchantID: z.number().optional(),
  merchantContactID: z.string().optional(),
});

export type Contact = z.infer<typeof ZContact>;
