import { AuthState } from '@okta/okta-auth-js';
import { BaseService } from '../TransportService';
import { GrowerAudit } from '../../model/Audit';
import { Grower } from '../../model/Grower';

const SERVICE_NAME = 'grower/audit';

export default {
  create(audit: GrowerAudit, auth: AuthState): Promise<GrowerAudit> {
    return BaseService.post<GrowerAudit>(SERVICE_NAME, audit, auth);
  },
  getByApplicationNumber(applicationNumber: number, auth: AuthState) {
    const params = { applicationNumber };
    return BaseService.get(`${SERVICE_NAME}/applicationNumber`, { params, auth });
  },
  getByAuditID(auditID: number, auth: AuthState): Promise<GrowerAudit> {
    const params = { auditID };
    return BaseService.get<GrowerAudit>(SERVICE_NAME, { params, auth });
  },
  update(audit: GrowerAudit, auth: AuthState): Promise<void> {
    return BaseService.put(SERVICE_NAME, audit, auth);
  },
  previousYears(igasNumber: string, auth: AuthState) {
    const params = { igasNumber };
    return BaseService.get(`${SERVICE_NAME}/previous`, { params, auth });
  },
  getLatestInfo(igasNumber: string, auth: AuthState) {
    const params = { igasNumber };
    return BaseService.get(`${SERVICE_NAME}/latest`, { params, auth });
  },
  getLatestAudit(igasNumber: string, auth: AuthState): Promise<GrowerAudit> {
    const params = { igasNumber };
    return BaseService.get(`${SERVICE_NAME}/latest/audit`, { params, auth });
  },

  search(query: string, searchType: string, auth: AuthState) {
    const params = { searchType, query };
    return BaseService.get(`${SERVICE_NAME}/search`, { params, auth });
  },
  getDrafts(auth: AuthState): Promise<Grower[]> {
    return BaseService.get(`${SERVICE_NAME}/draft`, { auth });
  },
  getRecordedList(auth: AuthState): Promise<GrowerAudit[]> {
    return BaseService.get(`${SERVICE_NAME}/recorded/list`, { auth });
  },
  sendAuditEmail(auditID: number, auth: AuthState): Promise<void> {
    return BaseService.get(`${SERVICE_NAME}/sendAuditEmail`, { params: { auditID }, auth });
  },
};
