import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import Tooltip from '@mui/material/Tooltip';
import styles from './ProgressBar.module.scss';

function ProgressBar(props) {
  const {
    number, total, id, bufferValue,
  } = props;

  const percentage = useMemo(() => (number / total) * 100, [number, total]);
  const bufferPercentage = useMemo(() => (
    ((number + bufferValue) / total) * 100
  ), [bufferValue, number, total]);

  return (
    <>
      <div className={styles.totals}>
        <span id={`${id}_amount`}>
          <Tooltip title="Currently assigned for this year" arrow placement="top">
            <span className={styles.number}>{number}</span>
          </Tooltip>
          /
          <Tooltip title="Amount they had last year" arrow placement="top">
            <span className={styles.number}>{total}</span>
          </Tooltip>
        </span>
        <span id={`${id}_percentage`}>
          {Math.floor(percentage)}
          %
        </span>
      </div>
      <LinearProgress variant="buffer" value={percentage} valueBuffer={bufferPercentage} id="auditProgressBar" />
    </>
  );
}

ProgressBar.propTypes = {
  id: PropTypes.string.isRequired,
  number: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  bufferValue: PropTypes.number,
};

ProgressBar.defaultProps = {
  bufferValue: 0,
};

export default ProgressBar;
