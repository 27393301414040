import React, { useCallback, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import { AuditorAuditsService } from '../../services/Auditor';
import { MerchantAudit } from '../../model/Merchant';
import SearchTable from '../../components/Table';
import config from '../../config.json';

function MerchantAuditFollowUpPage() {
  const navigate = useNavigate();
  const { authState } = useOktaAuth();
  const [isLoading, setLoadingState] = useState(false);
  const [merchants, setMerchants] = useState<MerchantAudit[]>([]);

  useEffect(() => {
    setLoadingState(true);
    (AuditorAuditsService.followUp(authState!) as unknown as Promise<MerchantAudit[]>)
      .then(setMerchants)
      .finally(() => setLoadingState(false));
  }, [authState]);

  const handleSelect = useCallback((selectedID: number) => {
    navigate('/merchantDetail/audit', {
      state: {
        merchantID: selectedID,
      },
    });
  }, [navigate]);

  return (
    <SearchTable
      metadata={{ ...config.recordedAudit.growerTable, selectableKey: 'merchantID' }}
      data={merchants}
      isLoading={isLoading}
      onSelect={handleSelect}
    />
  );
}

export default MerchantAuditFollowUpPage;
