import React, { useMemo } from 'react';
import _ from 'lodash';
import Typography from '@mui/material/Typography';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DangerousIcon from '@mui/icons-material/Dangerous';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import { Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { Accordion, InlineLoading } from '../../components';
import { AuditAnswer, AuditQuestion as AuditQuestionType } from '../../model/Audit/Audit';
import AuditQuestion from '../AuditQuestion/AuditQuestion';
import styles from './AuditCategoryQuestions.module.scss';

interface AuditCategoryQuestionsProps {
  name: string;
  readOnly?: boolean;
  isQuestionsLoading?: boolean;
}

function getPath(name: string, questions: AuditAnswer[], question: AuditQuestionType): string {
  const index = questions.findIndex(({ question: { questionID } }) => (
    questionID === question?.questionID
  ));
  return `${name}.${index}`;
}

function getCategoryName(
  questionAndAnswers: AuditAnswer[],
  category: string,
  readOnly: boolean,
): React.ReactElement {
  const questionCodeSections = questionAndAnswers ? questionAndAnswers[0]?.question?.questionCode?.split('.') : undefined;
  questionCodeSections?.pop();
  const categoryNumber = questionCodeSections?.join('.');
  const auditorNotes = questionAndAnswers.filter((question) => !!question.recommendation);
  const attachments = questionAndAnswers.filter((question) => (
    question?.attachments && question?.attachments?.length > 0
  ));
  const containsWrongAnswers = questionAndAnswers.filter((question) => question?.isIncorrect);

  return (
    <Stack flexDirection="row" justifyContent="space-between" width="100%">
      <span>{`${categoryNumber}. ${category}`}</span>
      <Stack flexDirection="row">
        {readOnly && auditorNotes.length > 0 ? <SpeakerNotesIcon /> : null}
        {readOnly && attachments.length > 0 ? <AttachFileIcon /> : null}
        {readOnly && containsWrongAnswers.length > 0 ? <DangerousIcon /> : null}
      </Stack>
    </Stack>
  );
}

function getSectionTitle(questionAndAnswers: AuditAnswer[]): string | undefined | null {
  return questionAndAnswers ? questionAndAnswers[0]?.question?.sectionTitle : undefined;
}

function AuditCategoryQuestions(props: AuditCategoryQuestionsProps) {
  const {
    name,
    readOnly = false,
    isQuestionsLoading = false,
  } = props;
  // const { fields: auditQuestions } = useFieldArray({ name });
  const { getValues } = useFormContext();

  const categoryQuestions = useMemo(() => {
    const questions = getValues(name);
    if (!questions) {
      return null;
    }
    const questionsSplitByCategory = _.groupBy(questions, 'question.category');
    const entries = Object.entries(questionsSplitByCategory);

    return entries.map(([category, qAndA], index) => {
      const questionAndAnswers = qAndA as unknown as (AuditAnswer & { id: string })[];
      const sectionTitle = getSectionTitle(questionAndAnswers);
      let previousSectionTitle: string | undefined | null;
      if (index !== 0) {
        const [, previousQuestionAndAnswers] = entries[index - 1];
        previousSectionTitle = getSectionTitle(
          previousQuestionAndAnswers as unknown as (AuditAnswer & { id: string })[],
        );
      }

      return (
        <div key={`${category}_category`}>
          {
              sectionTitle && previousSectionTitle !== sectionTitle ? (
                <Typography variant="h6" component="h3" className={styles.sectionTitle}>{sectionTitle}</Typography>
              ) : null
            }
          {
              !sectionTitle && previousSectionTitle !== sectionTitle
                ? <br />
                : null
            }
          <Accordion
            key={category}
            className={styles.questionContainer}
            containerClassName="auditCategory"
            renderHeader={() => getCategoryName(questionAndAnswers, category, readOnly)}
          >
            <>
              {questionAndAnswers.map(({ question, id }, i) => (
                <AuditQuestion
                  question={question}
                  key={`question_${id}`}
                  name={getPath(name, questions, question)}
                  readOnly={readOnly}
                  index={i}
                />
              ))}
            </>
          </Accordion>
        </div>
      );
    });
  }, [getValues, name, readOnly]);

  return (
    <InlineLoading isLoading={isQuestionsLoading}>
      {categoryQuestions as unknown as React.ReactElement}
    </InlineLoading>
  );
}

AuditCategoryQuestions.defaultProps = {
  isQuestionsLoading: false,
  readOnly: false,
};

export default AuditCategoryQuestions;
