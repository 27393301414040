import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useOktaAuth } from '@okta/okta-react';
import Typography from '@mui/material/Typography';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import HomeOutlineIcon from '@mui/icons-material/HomeOutlined';
import Button from '@mui/material/Button';
import InputField from '../../components/InputField/InputField';
import Accordion from '../../components/Accordion/Accordion';
import DateField from '../../components/DateField/DateField';
import styles from './GrowerInfoOnMerchant.module.scss';
import AuthUtil from '../../util/authUtil';

function GrowerInfoOnMerchant(props) {
  const { authState } = useOktaAuth();
  const navigate = useNavigate();
  const {
    info, deleteGrowersMerchant, hasPrimaryMerchant, onMakePrimaryMerchant, onValueChange,
    onMerchantAccountNumber, path,
  } = props;
  const {
    active, merchant, isPrimaryMerchant, previousPrimaryMerchant, merchantAccountNumber,
    isPreviousPrimaryMerchant, primaryMerchantSwitchDate,
  } = info;

  const handleGoToMerchant = useCallback(() => {
    navigate('/merchantDetail', {
      state: {
        merchantID: merchant.merchantID,
      },
    });
  }, [navigate, merchant.merchantID]);

  const accordionHeader = useCallback(() => (
    <div className={styles.summary}>
      <Typography className={styles.summaryStatus}>
        {active ? <DoneIcon color="primary" /> : <ClearIcon color="error" />}
      </Typography>
      <Typography className={styles.primaryMerchant}>
        {isPrimaryMerchant && active ? <HomeOutlineIcon color="primary" /> : null}
        {previousPrimaryMerchant && active ? <HomeOutlineIcon color="secondary" /> : null}
      </Typography>
      <Typography className={styles.summaryName}>
        {merchant.name}
      </Typography>
      <Typography className={styles.summaryDescription}>
        {`${merchant.address.addressLine1}, ${merchant.address.county}`}
      </Typography>
    </div>
  ), [active, isPrimaryMerchant, merchant, previousPrimaryMerchant]);

  return (
    <Accordion renderHeader={accordionHeader} containerClassName="merchantInfo">
      <div className={styles.container}>
        {AuthUtil.hasAccess('Admin', authState) ? (
          <Button
            color="primary"
            variant="contained"
            className={styles.goToMerchant}
            onClick={handleGoToMerchant}
          >
            Go to merchant
          </Button>
        ) : null}
        {isPreviousPrimaryMerchant ? (
          <DateField
            id="primaryMerchantSwitchDate"
            label="Primary merchant switch date"
            name="primaryMerchantSwitchDate"
            value={primaryMerchantSwitchDate}
            disabled
          />
        ) : null}
        <InputField
          label="Merchant Account Number"
          id="merchantAccountNumber"
          path={`${path}.merchantAccountNumber`}
          value={merchantAccountNumber}
          onValueChange={onValueChange}
        />
        <div className={styles.buttonContainer}>
          {!isPrimaryMerchant && AuthUtil.hasAccess('Admin', authState) ? (
            <Button
              variant="contained"
              color="primary"
              type="submit"
              className="removeMerchant"
              onClick={() => deleteGrowersMerchant(info)}
            >
              Remove Merchant
            </Button>
          ) : null}
          {hasPrimaryMerchant && !isPrimaryMerchant && AuthUtil.hasAccess('Admin', authState) ? (
            <Button
              variant="contained"
              color="primary"
              type="submit"
              className="makeMerchantPrimary"
              onClick={() => onMakePrimaryMerchant(merchant.merchantID)}
            >
              Make Primary Merchant
            </Button>
          ) : null}
          <Button
            variant="contained"
            color="primary"
            type="submit"
            className="makeMerchantPrimary"
            onClick={() => onMerchantAccountNumber(merchant.merchantID)}
          >
            Save
          </Button>
        </div>
      </div>
    </Accordion>
  );
}

GrowerInfoOnMerchant.propTypes = {
  path: PropTypes.string,
  deleteGrowersMerchant: PropTypes.func,
  hasPrimaryMerchant: PropTypes.bool,
  onMakePrimaryMerchant: PropTypes.func,
  onMerchantAccountNumber: PropTypes.func,
  onValueChange: PropTypes.func,
  info: PropTypes.shape({
    active: PropTypes.bool,
    isPreviousPrimaryMerchant: PropTypes.bool,
    primaryMerchantSwitchDate: PropTypes.string,
    isPrimaryMerchant: PropTypes.bool,
    previousPrimaryMerchant: PropTypes.bool,
    merchantAccountNumber: PropTypes.string,
    merchant: PropTypes.shape({
      merchantID: PropTypes.string,
      name: PropTypes.string,
      address: PropTypes.shape({
        addressLine1: PropTypes.string,
        county: PropTypes.string,
      }),
    }),
  }),
};

GrowerInfoOnMerchant.defaultProps = {
  path: '',
  deleteGrowersMerchant: _.noop,
  onMakePrimaryMerchant: _.noop,
  onMerchantAccountNumber: _.noop,
  onValueChange: _.noop,
  hasPrimaryMerchant: false,
  info: undefined,
};

export default GrowerInfoOnMerchant;
