import React, {
  useState,
  useEffect,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { useOktaAuth } from '@okta/okta-react';
import Typography from '@mui/material/Typography';
import { MerchantNoteService } from '../../../services/Merchant';
import { NotesComponent, PageLoadingComponent } from '../../../components';

function MerchantNotes(props) {
  const { merchantID } = props;
  const [notes, setNotes] = useState([]);
  const { authState } = useOktaAuth();
  const [isLoading, setLoadingState] = useState(true);

  const getNotes = useCallback(() => {
    MerchantNoteService.get(merchantID, authState).then(setNotes).finally(() => {
      setLoadingState(false);
    });
  }, [merchantID, authState]);

  useEffect(() => {
    setLoadingState(true);
    getNotes();
  }, [getNotes]);

  const handleNewNote = useCallback((newNote) => {
    const note = {
      ...newNote,
      merchantID,
    };
    setLoadingState(true);
    MerchantNoteService.create(note, authState).then(getNotes);
  }, [getNotes, merchantID, authState]);

  const handleNoteDelete = useCallback((_, noteToDelete) => {
    setLoadingState(true);
    return MerchantNoteService.delete(noteToDelete.noteID, authState).then(getNotes);
  }, [getNotes, authState]);

  return (
    <PageLoadingComponent isLoading={isLoading}>
      <Typography variant="h5" component="h1">
        Notes
      </Typography>
      <NotesComponent
        notes={notes}
        onNewNote={handleNewNote}
        onDeleteNote={handleNoteDelete}
      />
    </PageLoadingComponent>
  );
}

MerchantNotes.propTypes = {
  merchantID: PropTypes.string.isRequired,
};

export default MerchantNotes;
