import { AuthState } from '@okta/okta-auth-js';
import { AuditType, AuditQuestion } from '../../model/Audit';
import { BaseService } from '../TransportService';

const SERVICE_NAME = 'audit/question';

export default {
  get(type: AuditType, onlyActive: boolean, auth: AuthState) {
    const params = { type, onlyActive };
    return BaseService.get<AuditQuestion[]>(SERVICE_NAME, { params, auth });
  },
  getPO(auth: AuthState) {
    const params = { type: 'PRODUCTIONONLY', onlyActive: true };
    return BaseService.get<AuditQuestion[]>(SERVICE_NAME, { params, auth });
  },
  getPS(auth: AuthState) {
    const params = { type: 'PRODUCTIONSTORAGE', onlyActive: true };
    return BaseService.get<AuditQuestion[]>(SERVICE_NAME, { params, auth });
  },
  getSL(auth: AuthState) {
    const params = { type: 'STORAGELOCATION', onlyActive: true };
    return BaseService.get<AuditQuestion[]>(SERVICE_NAME, { params, auth });
  },
  getISOM(auth: AuthState) {
    const params = { type: 'ISOMERCHANT', onlyActive: true };
    return BaseService.get<AuditQuestion[]>(SERVICE_NAME, { params, auth });
  },
  getAllPO(auth: AuthState) {
    const params = { type: 'PRODUCTIONONLY' };
    return BaseService.get<AuditQuestion[]>(SERVICE_NAME, { params, auth });
  },
  getAllPS(auth: AuthState) {
    const params = { type: 'PRODUCTIONSTORAGE' };
    return BaseService.get<AuditQuestion[]>(SERVICE_NAME, { params, auth });
  },
  getAllSL(auth: AuthState) {
    const params = { type: 'STORAGELOCATION' };
    return BaseService.get<AuditQuestion[]>(SERVICE_NAME, { params, auth });
  },
  getAllISOM(auth: AuthState) {
    const params = { type: 'ISOMERCHANT' };
    return BaseService.get<AuditQuestion[]>(SERVICE_NAME, { params, auth });
  },
  getAllSLV(auth: AuthState) {
    const params = { type: 'SLVISUAL' };
    return BaseService.get<AuditQuestion[]>(SERVICE_NAME, { params, auth });
  },
  create(question: AuditQuestion, auth: AuthState) {
    return BaseService.post<AuditQuestion>(SERVICE_NAME, question, auth);
  },
  update(question: AuditQuestion, auth: AuthState) {
    return BaseService.put<AuditQuestion, void>(SERVICE_NAME, question, auth);
  },
  updateOrder(questions: AuditQuestion[], auth: AuthState) {
    return BaseService.put<AuditQuestion[], void>(`${SERVICE_NAME}/order`, questions, auth);
  },
};
