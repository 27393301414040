import {
  format,
  isValid,
  parse,
  isBefore,
  isEqual,
} from 'date-fns';

export const DATE_FORMAT = 'yyyy-MM-dd';

export function isDateStringValid(date: string): boolean {
  return /\d{4}-\d{2}-\d{2}/.test(date);
}

export function getFormattedDate(date: Date = new Date()): string {
  if (isValid(date)) {
    return format(date, DATE_FORMAT);
  }
  return date.toString();
}

export function getFormattedDatePickerDate(date: Date = new Date()): string {
  if (isValid(date)) {
    return format(date, 'dd MM yyyy');
  }
  return date.toString();
}

export function getDateFromString(stringDate: string): Date | null {
  return parse(stringDate, DATE_FORMAT, new Date()) || null;
}

export function isBeforeDate(date: Date, dateToCompare: Date): boolean {
  return isBefore(date, dateToCompare) || isEqual(date, dateToCompare);
}

export function printDate(date: Date | string): string {
  if (typeof date === 'string') {
    return format(getDateFromString(date as string)!, 'do MMMM yyyy');
  }
  return format(date as Date, 'do MMMM yyyy');
}
