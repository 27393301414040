import { BaseService } from '../TransportService';

const SERVICE_NAME = 'merchant/contact';

export default {
  create(contact, auth) {
    return BaseService.post(SERVICE_NAME, contact, auth);
  },
  get(id, auth, contactType) {
    if (!id) {
      return Promise.resolve();
    }
    const params = { id };
    if (contactType) {
      params[contactType] = contactType;
    }
    return BaseService.get(SERVICE_NAME, { params, auth });
  },
  update(contact, auth) {
    return BaseService.put(SERVICE_NAME, contact, auth);
  },
  delete(id, auth) {
    const params = { id };
    return BaseService.delete(SERVICE_NAME, params, auth);
  },
};
