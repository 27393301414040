import React, { useCallback, useMemo, useState } from 'react';
import { compareDesc } from 'date-fns';
import _ from 'lodash';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import styles from './NoteComponent.module.scss';
import InputField from '../InputField/InputField';
import Button from '../Button/Button';
import { getFormattedDate, getDateFromString } from '../../util/dateUtils';
import NoteSection from './NoteSection/NoteSection';
import CheckboxField from '../CheckboxField/CheckboxField';
import {
  AuditNote,
  NoteType,
  Note,
  ZNoteType,
} from '../../model/Note';

const isAutomatedNote = (type: NoteType) => ZNoteType.Values[type] === ZNoteType.Values.AUTOMATED;

type NotesComponentType = {
  notes: AuditNote[],
  onNewNote: (note: AuditNote) => void,
  onDeleteNote: (newNotes: AuditNote[], noteToDelete: AuditNote) => void,
};

function NotesComponent(props: NotesComponentType) {
  const [note, updateNote] = useState<string>();
  const [showAutomatedNotes, setShowAutomatedNotes] = useState(false);
  const {
    notes,
    onDeleteNote,
    onNewNote,
  } = props;

  const hasAutomatedNotes = useMemo(() => {
    if (_.isEmpty(notes)) {
      return null;
    }

    return notes.some(({ noteType }) => isAutomatedNote(noteType!));
  }, [notes]);

  const handleChange = useCallback((value: any) => {
    updateNote(value as string);
  }, []);

  const handleSubmit = useCallback(() => {
    const newNote: AuditNote = {
      content: note!,
      createdDate: getFormattedDate(),
    };
    onNewNote(newNote);
    updateNote(undefined);
  }, [note, onNewNote]);

  const handleDeleteNote = useCallback((pathToRemove: string, noteToDelete: AuditNote) => {
    const newNotes = notes.filter((singleNote) => !_.isEqual(singleNote, noteToDelete));

    if (onDeleteNote) {
      onDeleteNote(newNotes, noteToDelete);
    }
  }, [notes, onDeleteNote]);

  const notesList = useMemo(() => {
    if (_.isEmpty(notes)) {
      return null;
    }

    const noteList = notes
      .filter(({ noteType }) => showAutomatedNotes || !isAutomatedNote(noteType!))
      .sort((noteA, noteB) => (
        compareDesc(
          getDateFromString(noteA.createdDate!)!,
          getDateFromString(noteB.createdDate!)!,
        )
      ))
      .map((theNote: Note, index: number) => (
        <NoteSection
          key={theNote.content}
          note={theNote}
          path={`${index}`}
          onDeleteNote={handleDeleteNote}
        />
      ));

    return <List>{noteList}</List>;
  }, [handleDeleteNote, notes, showAutomatedNotes]);

  return (
    <Paper className={styles.container}>
      <div className={styles.form}>
        <InputField
          label="Enter new note"
          name="noteComponent"
          onValueChange={handleChange}
          value={note}
          inputFieldClass={styles.inputField}
          className={styles.inputContainer}
          id="noteInput"
          multiline
          rows={4}
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={!note && note?.length !== 0}
          onClick={handleSubmit}
          id="submitNote"
        >
          Submit Note
        </Button>
      </div>
      <div id="notesContainer">
        {notesList}
      </div>
      {hasAutomatedNotes ? (
        <CheckboxField
          id="automatedNotesToggle"
          label="Toggle automated notes"
          onValueChange={(value: any) => setShowAutomatedNotes(value)}
          value={showAutomatedNotes}
        />
      ) : null}
    </Paper>
  );
}

export default React.memo(NotesComponent);
