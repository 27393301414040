import { AuthState } from '@okta/okta-auth-js';
import { AuditNote } from '../../model/Note';
import { BaseService } from '../TransportService';

const SERVICE_NAME = 'audit/note';

export default {
  create(note: AuditNote, auth: AuthState): Promise<AuditNote[]> {
    return BaseService.post(SERVICE_NAME, note, auth) as unknown as Promise<AuditNote[]>;
  },
  delete(noteID: number, auth: AuthState) {
    const params = { noteID };
    return BaseService.delete<void>(SERVICE_NAME, params, auth);
  },
};
