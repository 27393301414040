import React, { useCallback, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Done from '@mui/icons-material/Done';
import Clear from '@mui/icons-material/Clear';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import CircularProgress from '@mui/material/CircularProgress';
import { isDateStringValid, printDate } from '../../util/dateUtils';
import CheckboxField from '../CheckboxField/CheckboxField';
import styles from './Table.module.scss';
import config from '../../config.json';

function getStructuredValue(value, typelist) {
  if (typelist) {
    const availableValues = _.get(config.typelist, typelist);

    return availableValues.find((av) => av.code === value)?.label;
  }
  return value;
}

function getLoadingIcon(numberOfColumns) {
  return (
    <TableRow>
      <TableCell colSpan={numberOfColumns} className={styles.centerText} padding="checkbox">
        <CircularProgress data-testid="table-loading" />
      </TableCell>
    </TableRow>
  );
}

function getEmptyDataRow(numberOfColumns) {
  return (
    <TableRow>
      <TableCell colSpan={numberOfColumns} className={styles.centerText} padding="checkbox">
        <span data-testid="no-data-table">No data available</span>
      </TableCell>
    </TableRow>
  );
}

function IgasTable(props) {
  const {
    metadata,
    data,
    onSelect,
    isLoading,
    selectedRowIndex,
    onMultiSelection,
    selectedRows,
    disabled,
    onDrag,
  } = props;

  useEffect(() => {
    if (selectedRowIndex && !_.isEmpty(data)) {
      const selectedRow = document.getElementsByTagName('tr')[selectedRowIndex];
      if (selectedRow) {
        selectedRow.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }
  }, [data, selectedRowIndex]);

  const handleRowSelect = useCallback((selectedValue, rowIndex) => {
    if (metadata.multiSelect) {
      let newSelectedRows;
      if (_.some(selectedRows, selectedValue)) {
        newSelectedRows = selectedRows.filter(
          (selectedRow) => !_.isEqual(selectedRow, selectedValue),
        );
      } else {
        newSelectedRows = _.uniq([...selectedRows, selectedValue]);
      }
      onMultiSelection(newSelectedRows);
    } else {
      onSelect(selectedValue, rowIndex);
    }
  }, [metadata.multiSelect, onMultiSelection, onSelect, selectedRows]);

  const getTableData = useCallback(() => {
    if (_.isEmpty(data)) {
      return null;
    }

    return data.map((item, rowIndex) => {
      const rowKey = `${item}_${rowIndex}`;

      const id = item[metadata.selectableKey] || rowIndex;
      const selectableValue = _.get(item, metadata.selectableKey) || (metadata.multiSelect && item);

      const rows = metadata.multiSelect
        ? [{ isMultiSelectCell: true }, ...metadata.rows] : metadata.rows;

      let isItemSelected;

      const rowData = rows.map(({ path, typelist, isMultiSelectCell }, cellIndex) => {
        const paths = Array.isArray(path) ? path : [path];
        let value = paths.map((p) => _.get(item, p)).filter((v) => (
          v !== undefined && v !== null
        ))[0];

        const cellKey = `${path}_${cellIndex}`;

        if (_.isBoolean(value)) {
          value = value ? <Done color="primary" /> : <Clear color="error" />;
        } else if (isDateStringValid(value)) {
          value = printDate(value);
        }
        if (isMultiSelectCell) {
          isItemSelected = _.some(selectedRows, item);
        }

        return (
          <TableCell
            key={cellKey}
            variant="body"
            padding="checkbox"
            style={{ width: `${100 / rows.length}%` }}
            className={classNames(styles.tableCell, { [styles.multiSelect]: isMultiSelectCell })}
          >
            {isMultiSelectCell ? (
              <CheckboxField
                value={isItemSelected}
              />
            ) : getStructuredValue(value, typelist)}
          </TableCell>
        );
      });

      const rowProps = {
        hover: true,
        selected: _.get(item, metadata.selectRowIfFieldExists),
        onClick: () => handleRowSelect(selectableValue, rowIndex),
        className: styles.tableRow,
        role: 'button',
      };

      if (onDrag) {
        return (
          <Draggable key={`table_row_${id}`} draggableId={`table_row_${id}`} index={rowIndex}>
            {(provided) => (
              <TableRow
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                {...rowProps}
              >
                {rowData}
              </TableRow>
            )}
          </Draggable>
        );
      }
      return (
        <TableRow
          key={rowKey}
          {...rowProps}
        >
          {rowData}
        </TableRow>
      );
    });
  }, [data, handleRowSelect, onDrag, metadata, selectedRows]);

  const tableHeaders = useMemo(() => {
    const rows = metadata.multiSelect ? [{}, ...metadata.rows] : metadata.rows;

    return rows.map(({ title, path }, index) => {
      const key = `${path}_${index}`;
      return (
        <TableCell component="th" scope="row" key={key} variant="head" padding="checkbox" className={styles.tableCell}>
          {title}
        </TableCell>
      );
    });
  }, [metadata]);

  const content = useMemo(() => {
    const numberOfCells = metadata.multiSelect ? metadata.rows.length + 1 : metadata.rows.length;

    if (isLoading) {
      return getLoadingIcon(numberOfCells);
    }
    if (_.isEmpty(data)) {
      return getEmptyDataRow(numberOfCells);
    }
    return getTableData();
  }, [isLoading, data, getTableData, metadata]);

  const handleDragEnd = useCallback((result) => {
    const newIndex = result.destination.index;
    const oldIndex = result.source.index;
    const dataClone = [...data];
    dataClone.splice(newIndex, 0, dataClone.splice(oldIndex, 1)[0]);
    onDrag(dataClone);
  }, [data, onDrag]);

  return (
    <Paper className={styles.tableContainer} padding="checkbox">
      {disabled && <div className={styles.disabledTable} data-testid="disabled-table" />}
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <Table ref={provided.innerRef} {...provided.droppableProps}>
              <TableHead>
                <TableRow>{tableHeaders}</TableRow>
              </TableHead>

              <TableBody>
                {content}
                {provided.placeholder}
              </TableBody>
            </Table>
          )}
        </Droppable>
      </DragDropContext>
    </Paper>
  );
}

IgasTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  metadata: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.shape({})),
    selectableKey: PropTypes.string,
    selectRowIfFieldExists: PropTypes.string,
    multiSelect: PropTypes.bool,
  }),
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  onSelect: PropTypes.func,
  onMultiSelection: PropTypes.func,
  onDrag: PropTypes.func,
  selectedRowIndex: PropTypes.number,
  selectedRows: PropTypes.arrayOf(PropTypes.shape({})),
};

IgasTable.defaultProps = {
  data: [],
  metadata: undefined,
  isLoading: false,
  disabled: false,
  onSelect: _.noop,
  onMultiSelection: _.noop,
  onDrag: undefined,
  selectedRowIndex: undefined,
  selectedRows: [],
};

export default IgasTable;
