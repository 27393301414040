import * as z from 'zod';

export const ZAuditor = z.object({
  active: z.boolean(),
  auditorID: z.number().optional(),
  email: z.string(),
  igasEmail: z.string().optional(),
  firstname: z.string(),
  surname: z.string(),
});

export type Auditor = z.infer<typeof ZAuditor>;
