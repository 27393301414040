import { AuthState } from '@okta/okta-auth-js';
import { BaseService, DocumentService } from '../TransportService';
import { AuditAnswerAttachment } from '../../model/Audit';

const SERVICE_NAME = 'audit/answer';

export default {
  uploadFile(
    type: string,
    id: string,
    code: string,
    files: File[],
    auth: AuthState,
    answerID?: number,
  ): Promise<AuditAnswerAttachment[]> {
    let url = `${SERVICE_NAME}/upload?id=${id}&code=${code}&type=${type}`;
    if (answerID) {
      url += `&answerID=${answerID}`;
    }
    return DocumentService.sendFilesJson<AuditAnswerAttachment[]>(url, files, auth);
  },
  downloadFile(id: number, filename: string, auth: AuthState) {
    return DocumentService.downloadLink(`${SERVICE_NAME}/download`, id, filename, auth);
  },
  delete(id: number, auth: AuthState) {
    const params = { id };
    return BaseService.delete<void>(`${SERVICE_NAME}/delete`, params, auth);
  },
};
