import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import { MerchantAuditService } from '../../../services/Merchant';
import { useLocalStorage } from '../../../hooks';
import { MerchantAudit as MerchantAuditType } from '../../../model/Merchant';
import { MerchantInfoFields } from './components';
import {
  PageLoadingComponent,
  Table,
} from '../../../components';
import config from '../../../config.json';

function MerchantAudit({ merchantID }: { merchantID: string }) {
  const [isLoading, setLoadingState] = useState(false);
  const [merchantAudit, setMerchantAudit, handleRemoveStoredValue] = useLocalStorage<MerchantAuditType>(`merchantAuditFor${merchantID}`);
  const { authState } = useOktaAuth();
  const { state } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setLoadingState(true);
    if (state.merchantAuditID) {
      MerchantAuditService.get(state.merchantAuditID, authState!)
        .then(setMerchantAudit)
        .finally(() => setLoadingState(false));
    } else {
      MerchantAuditService.getByHarvestYear(merchantID, authState!)
        .then((data) => {
          setMerchantAudit((previousAuditData = {}) => {
            if (data.merchantAuditID) {
              return data;
            }
            // eslint-disable-next-line prefer-object-spread
            return Object.assign({}, data, previousAuditData);
          });
        })
        .finally(() => setLoadingState(false));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const data = useMemo(() => {
    const growerStructure = merchantAudit?.merchantGrowerStorageLocationAudits?.map((grower) => ({
      ...grower,
      siteName: `${grower.igasNumber} ${grower.firstname} ${grower.surname}`,
    }));

    return [...(merchantAudit?.merchantStorageLocationAudits || []), ...(growerStructure || [])];
  }, [merchantAudit]);

  const handleSelect = useCallback((selectedValue: number) => {
    const selectedMerchantAudit = data?.find(
      (storageLocations) => storageLocations.storageLocationDetailsID === selectedValue,
    );

    navigate('/storageLocationAudit', {
      state: {
        merchantAudit,
        storageLocationDetailsID: selectedValue,
        storageLocationAuditID: selectedMerchantAudit?.storageLocationAuditID!,
      },
    });
  }, [data, navigate, merchantAudit]);

  const isAuditComplete = useMemo(() => (
    merchantAudit?.merchantStorageLocationAudits?.length !== 0
    && merchantAudit?.merchantStorageLocationAudits?.every((slAudit) => (
      slAudit.isComplete && slAudit.isReviewed
    ))
  ), [merchantAudit]);

  return (
    <>
      <PageLoadingComponent isLoading={isLoading} />
      <MerchantInfoFields
        data={merchantAudit}
        onValueChange={setMerchantAudit}
        onRemoveStoredValue={handleRemoveStoredValue}
        readOnly={state?.readOnly || isAuditComplete}
      />
      <Table
        metadata={
          state?.readOnly
            ? config.merchantStorageLocationAudits.reviewTable
            : config.merchantStorageLocationAudits.table
        }
        data={data}
        disabled={!merchantAudit?.merchantAuditID}
        onSelect={handleSelect}
      />
    </>
  );
}

export default MerchantAudit;
