import React, { useCallback } from 'react';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { useOktaAuth } from '@okta/okta-react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Input } from '../../components';
import { Login, ZLogin } from '../../model/Login';
import styles from './LoginPage.module.scss';

function LoginPage() {
  const { oktaAuth } = useOktaAuth();
  const [errorMessage, setErrorMessage] = React.useState<undefined | string>();
  const methods = useForm<Login>({ mode: 'onBlur', resolver: zodResolver(ZLogin) });
  const { handleSubmit } = methods;

  const onSubmit: SubmitHandler<Login> = useCallback((data) => {
    oktaAuth.signInWithCredentials(data)
      .then(({ sessionToken }) => {
        if (!sessionToken) {
          setErrorMessage('Authentication process failed');
          throw new Error('authentication process failed');
        }
        oktaAuth.signInWithRedirect({ sessionToken });
        setErrorMessage(undefined);
      })
      .catch((err: any) => {
        const error = err as Error;
        setErrorMessage(error.message);
      });
  }, [oktaAuth]);

  return (
    <Paper>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} className={styles.pageContainer}>
          <img src="/logo512.png" alt="IGAS Logo" className={styles.logo} />
          {!!errorMessage && <span className={styles.errorMessage}>{errorMessage}</span>}
          <Grid item xs={12}>
            <Input label="Email" name="username" />
          </Grid>
          <Grid item xs={12}>
            <Input label="Password" name="password" type="password" />
          </Grid>
          <Button size="small" color="primary" id="loginButton" type="submit">
            Login
          </Button>
        </form>
      </FormProvider>
    </Paper>
  );
}

export default LoginPage;
