import React, {
  useContext,
  useEffect,
  useCallback,
  useState,
  useMemo,
} from 'react';
import { useLocation } from 'react-router-dom';
import z, { ZodType } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, FormProvider } from 'react-hook-form';
import { getFormattedDate } from '../../util/dateUtils';
import ValidationErrors from '../ValidationErrors/ValidationErrors';
import AuditStatusComponent from '../AuditStatusComponent/AuditStatusComponent';
import StoredAuditNotification from '../StoredAuditNotification/StoredAuditNotification';
import styles from './AuditDataProvider.module.scss';
import { findAudit, addToList } from './util/AuditStorageUtil';
import { Audit, AuditQuestion, AuditAnswer } from '../../model/Audit';
import useUserAccess from '../../hooks/useUserAccess/useUserAccess';
import { AuditButtons } from '../AuditButtons/AuditButtons';

export const EDITABLE_STAGES = ['ASSIGNED', 'DRAFT', 'FAILED'];

export interface AuditDataContextTypes<T extends Audit> {
  startAudit: (data: Partial<T>) => Partial<T>;
  setAuditQuestions: (data: AuditQuestion[]) => AuditAnswer[];
  isAuditReadOnly: boolean;
}

export const AuditDataContext = React
  .createContext<AuditDataContextTypes<any>>({
  startAudit: () => ({}),
  setAuditQuestions: () => [] as unknown as AuditAnswer[],
  isAuditReadOnly: false,
});

export type AuditDataProviderPropTypes<T> = React.PropsWithChildren<{
  initialData: Partial<T>,
  schema: ZodType,
  onCreate: (data: T) => Promise<T>;
  onUpdate: (data: T) => Promise<void>;
  // eslint-disable-next-line react/require-default-props
  onSendEmail?: (auditID?: number) => Promise<void>;
  onGet: (auditID: number) => Promise<T>;
}>;

export const setAuditQuestions = (data: AuditQuestion[] = []): AuditAnswer[] => (
  data.map((question) => ({
    answer: question.defaultAnswer,
    question,
  }))
);

export function AuditDataProvider<T extends Audit>({
  children,
  initialData,
  schema,
  onCreate,
  onUpdate,
  onSendEmail,
  onGet,
}: AuditDataProviderPropTypes<T>) {
  const { state } = useLocation();
  const [isUsingStoredAudit, setStoredAuditState] = useState<boolean>(false);

  const { isAdmin } = useUserAccess();

  const closeStoredAuditState = useCallback(() => setStoredAuditState(false), []);

  const startAudit = useCallback((data: Partial<T> = initialData): Partial<T> => {
    let storedAudit: Partial<T> | undefined = {};

    if (!data?.auditResult) {
      storedAudit = findAudit<T>(data);

      if (storedAudit) {
        setStoredAuditState(true);
      }
    } else {
      closeStoredAuditState();
    }

    if (!data?.auditResult) {
      const existingData = { ...data, ...(storedAudit || {}) };
      const audit = {
        ...existingData,
        auditDate: existingData.auditDate || getFormattedDate(new Date()),
        auditStage: existingData.auditStage || isAdmin ? 'REVIEWED' : 'DRAFT',
      };
      return audit;
    }
    return data;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const defaultValues = useMemo(() => startAudit(initialData), []);

  const methods = useForm<T>({
    mode: 'onBlur',
    resolver: zodResolver(schema),
    defaultValues: defaultValues as z.infer<typeof schema>,
  });

  const {
    getValues,
    reset,
  } = methods;
  const {
    auditStage,
    resultDescription,
    auditResult,
  } = getValues();

  const isAuditReadOnly = useMemo<boolean>(() => (
    (state as any).readOnly || (!EDITABLE_STAGES.includes(auditStage) && !!auditResult)
  ), [state, auditStage, auditResult]);

  const handleLocalSave = useCallback(() => {
    const data = getValues();
    if (!data?.auditResult) {
      addToList(data);
    }
  }, [getValues]);

  useEffect(() => {
    window.addEventListener('beforeunload', handleLocalSave);

    return () => {
      window.removeEventListener('beforeunload', handleLocalSave);
      handleLocalSave();
    };
  }, [getValues, handleLocalSave]);

  const value = useMemo(() => ({
    startAudit,
    setAuditQuestions,
    isAuditReadOnly,
  }), [
    startAudit,
    isAuditReadOnly,
  ]);

  return (
    <AuditDataContext.Provider value={value}>
      <FormProvider {...methods}>
        <form className={styles.auditContainer}>
          <AuditStatusComponent
            resultDescription={resultDescription}
            auditResult={auditResult}
            auditStage={auditStage}
          />
          {children}
          <StoredAuditNotification
            isOpen={isUsingStoredAudit}
            onNewAudit={reset}
            onClose={closeStoredAuditState}
          />
          <ValidationErrors />
          <AuditButtons
            onGet={onGet}
            schema={schema}
            onCreate={onCreate}
            onUpdate={onUpdate}
            onSendEmail={onSendEmail}
          />
        </form>
      </FormProvider>
    </AuditDataContext.Provider>
  );
}

AuditDataProvider.defaultProps = {};

export function useAuditData<Type extends Audit>() {
  return useContext<AuditDataContextTypes<Type>>(AuditDataContext);
}
