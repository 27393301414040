import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import InputField, { ValueChangeType, ValueType } from '../InputField/InputField';
import SelectField from '../SelectField/SelectField';
import styles from './AddressComonent.module.scss';
import config from '../../config.json';

export function getAddressDisplayName(address = {}) {
  return Object.values(address)
    .filter((addressSection) => !!addressSection)
    .join(', ');
}

type AddressComponentTypes = {
  seperatorComponent: typeof React.Component,
  address: {
    addressLine1: string,
    addressLine2: string,
    town: string,
    county: string,
    eircode: string,
  },
  className: string,
  onValueChange: ValueChangeType,
  path: string,
  disabled: boolean,
};

function AddressComponent(props: AddressComponentTypes) {
  const {
    seperatorComponent: Component,
    className,
    onValueChange,
    address,
    path,
    disabled,
  } = props;

  const handleChange = useCallback((value: ValueType, changedPath?: string) => {
    const newPath = path ? `${path}.${changedPath}` : changedPath;
    if (onValueChange) {
      onValueChange(value, newPath);
    }
  }, [onValueChange, path]);

  const addressFields = useMemo(() => {
    const classes = classNames(styles.addressComponent, className);

    return [
      <InputField className={classes} id="addressLine1" label="Address Line 1" value={address?.addressLine1} required name="addressLine1" onValueChange={handleChange} path="addressLine1" key="addressLine1" disabled={disabled} />,
      <InputField className={classes} id="addressLine2" label="Address Line 2" value={address?.addressLine2} name="addressLine2" onValueChange={handleChange} path="addressLine2" key="addressLine2" disabled={disabled} />,
      <InputField className={classes} id="town" label="Town" value={address?.town} name="town" onValueChange={handleChange} path="town" key="town" disabled={disabled} />,
      <SelectField className={classes} id="county" label="County" value={address?.county} name="county" required onValueChange={handleChange} path="county" availableValues={config.typelist.counties} key="county" disabled={disabled} />,
      <InputField className={classes} id="eircode" label="Eircode" value={address?.eircode} name="eircode" onValueChange={handleChange} path="eircode" key="eircode" disabled={disabled} />,
    ];
  }, [address, className, disabled, handleChange]);

  if (Component) {
    return addressFields.map((addressSection, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <Component key={`address_field_${index}`}>
        {addressSection}
      </Component>
    ));
  }

  return (
    <div className={styles.address}>
      {addressFields}
    </div>
  );
}

AddressComponent.propTypes = {
  seperatorComponent: PropTypes.shape({}),
  address: PropTypes.shape({
    addressLine1: PropTypes.string,
    addressLine2: PropTypes.string,
    town: PropTypes.string,
    county: PropTypes.string,
    eircode: PropTypes.string,
  }),
  className: PropTypes.string,
  onValueChange: PropTypes.func,
  path: PropTypes.string,
  disabled: PropTypes.bool,
};

AddressComponent.defaultProps = {
  seperatorComponent: undefined,
  address: undefined,
  className: '',
  onValueChange: undefined,
  path: undefined,
  disabled: false,
};

export default AddressComponent;
