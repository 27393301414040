import React, { useMemo } from 'react';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import styles from './AuditStatusComponent.module.scss';

type AuditStatusComponentTypes = {
  auditResult?: string;
  resultDescription?: string;
  auditStage?: string;
};

const classes = {
  root: styles.chipRoot,
  label: styles.label,
};

function AuditStatusComponent({
  auditResult, resultDescription, auditStage,
}: AuditStatusComponentTypes) {
  const auditStageColor = useMemo(() => {
    if (auditStage === 'RECORDED') {
      return 'warning';
    }
    if (auditStage === 'REVIEWED') {
      return 'primary';
    }
    if (auditStage === 'FAILED') {
      return 'error';
    }
    return 'info';
  }, [auditStage]);

  if (!auditResult) {
    return null;
  }

  return (
    <Stack direction="row" spacing={2} justifyContent="center">
      <Chip label={resultDescription} color={auditResult === 'RECOMMENDED' ? 'primary' : 'secondary'} classes={classes} />
      {auditStage ? <Chip label={auditStage} color={auditStageColor} classes={classes} /> : null}
    </Stack>
  );
}

AuditStatusComponent.defaultProps = {
  auditResult: undefined,
  resultDescription: undefined,
};

export default React.memo(AuditStatusComponent);
