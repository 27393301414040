import React, { useEffect, useState, useCallback } from 'react';
import _ from 'lodash';
import { useOktaAuth } from '@okta/okta-react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import {
  Table,
  Button,
  InputField,
  // SelectField,
  CheckboxField,
  ModalWithContent,
} from '../../components';
import FormUtil from '../../util/formUtil';
import { AuditorService } from '../../services/Auditor';
import config from '../../config.json';
import styles from './AuditorPage.module.scss';

function getFields(selectedAuditor, handleChange) {
  return (
    <List>
      <ListItem>
        <InputField
          label="First Name"
          path="firstname"
          id="firstname"
          onValueChange={handleChange}
          className={styles.items}
          value={selectedAuditor?.firstname}
        />
      </ListItem>
      <ListItem>
        <InputField
          label="Surname"
          path="surname"
          id="surname"
          onValueChange={handleChange}
          className={styles.items}
          value={selectedAuditor?.surname}
        />
      </ListItem>
      <ListItem>
        <InputField
          label="Phone Number"
          path="phoneNumber"
          id="phoneNumber"
          onValueChange={handleChange}
          className={styles.items}
          value={selectedAuditor?.phoneNumber}
        />
      </ListItem>
      <ListItem>
        <InputField
          label="Email"
          path="email"
          id="email"
          onValueChange={handleChange}
          className={styles.items}
          value={selectedAuditor?.email}
        />
      </ListItem>
      <ListItem>
        <InputField
          label="IGAS Email"
          path="igasEmail"
          id="igasEmail"
          onValueChange={handleChange}
          className={styles.items}
          value={selectedAuditor?.igasEmail}
        />
      </ListItem>
      <ListItem>
        <InputField
          label="Okta ID"
          path="okta"
          id="okta"
          onValueChange={handleChange}
          className={styles.items}
          value={selectedAuditor?.okta}
        />
      </ListItem>
      {/* <ListItem>
        <SelectField
          path="auditType"
          label="Audit Type"
          onValueChange={handleChange}
          className={styles.items}
          availableValues={config.typelist.growerType}
          value={selectedAuditor?.auditType}
        />
      </ListItem> */}
      <ListItem>
        <CheckboxField
          path="active"
          id="active"
          label="Active"
          onValueChange={handleChange}
          className={styles.items}
          value={selectedAuditor?.active}
        />
      </ListItem>
    </List>
  );
}

function AuditorPage() {
  const [auditors, updateAuditors] = useState();
  const [isLoading, setLoadingState] = useState(false);
  const [isModalOpen, setModalState] = useState(false);
  const [selectedAuditor, setSelectedAuditor] = useState();
  const { authState } = useOktaAuth();

  useEffect(() => {
    setLoadingState(true);
    AuditorService.get(authState).then((response) => {
      updateAuditors(response);
      setLoadingState(false);
    });
  }, [authState]);

  const handleSelect = useCallback((selectedID) => {
    setModalState(true);
    setSelectedAuditor(
      auditors.find((auditor) => auditor.auditorID === selectedID),
    );
  }, [auditors]);

  const toggleModal = useCallback(() => {
    setModalState(!isModalOpen);
  }, [isModalOpen]);

  const createNewAuditor = useCallback(() => {
    setSelectedAuditor({ active: true });
    toggleModal();
  }, [toggleModal]);

  const handleUpdateAuditor = useCallback(() => {
    setLoadingState(true);

    // update locally
    const index = auditors.findIndex((auditor) => auditor.auditorID === selectedAuditor.auditorID);
    auditors[index] = selectedAuditor;
    updateAuditors(auditors);

    // update server
    AuditorService.update(selectedAuditor, authState).then(() => {
      setLoadingState(false);
      toggleModal();
    });
  }, [auditors, selectedAuditor, toggleModal, authState]);

  const handleAddAuditor = useCallback(() => {
    setLoadingState(true);
    AuditorService.create(selectedAuditor, authState)
      .then(() => AuditorService.get(authState))
      .then((response) => {
        updateAuditors(response);
        setLoadingState(false);
        toggleModal();
      });
  }, [selectedAuditor, toggleModal, authState]);

  const handleChange = useCallback((value, path) => {
    const clonedAuditorData = _.cloneDeep(selectedAuditor);
    _.set(clonedAuditorData, path, value);
    setSelectedAuditor(clonedAuditorData);
  }, [selectedAuditor]);

  if (!auditors) {
    return null;
  }

  return (
    <div>
      <Fab color="primary" className={styles.addButton} onClick={createNewAuditor} id="addNewAuditor">
        <AddIcon />
      </Fab>
      <Table
        metadata={config.auditorInfo.table}
        data={auditors}
        isLoading={isLoading}
        onSelect={handleSelect}
      />
      <ModalWithContent
        modalClassName={styles.modal}
        contentClassName={styles.modalContent}
        isOpen={isModalOpen}
        onClose={toggleModal}
      >
        <Typography variant="h5" component="h1">Auditor</Typography>
        {getFields(selectedAuditor, handleChange)}
        <Button
          variant="contained"
          color="primary"
          type="submit"
          id="saveButton"
          disabled={FormUtil.isSaveDisabled(selectedAuditor, config.auditorInfo.requiredFields)}
          onClick={selectedAuditor?.auditorID ? handleUpdateAuditor : handleAddAuditor}
        >
          {selectedAuditor?.auditorID ? 'Update Auditor' : 'Add Auditor'}
        </Button>
      </ModalWithContent>
    </div>
  );
}

export default AuditorPage;
