import { AuthState } from '@okta/okta-auth-js';
import { AuditYear, CurrentAuditYear, RecordedAuditCount } from '../../model/Audit';
import { BaseService } from '../TransportService';

const SERVICE_NAME = 'audit';

export default {
  getYear(auth: AuthState): Promise<CurrentAuditYear> {
    return BaseService.get<CurrentAuditYear>(`${SERVICE_NAME}/year`, { auth });
  },
  getAuditYears(auth: AuthState): Promise<AuditYear[]> {
    return BaseService.get<AuditYear[]>(`${SERVICE_NAME}/previous/years`, { auth });
  },
  approve(auditID: number, auth: AuthState) {
    return BaseService.put(`${SERVICE_NAME}/approve`, {}, auth, { auditID });
  },
  failed(auditID: number, auth: AuthState) {
    return BaseService.put(`${SERVICE_NAME}/failed`, {}, auth, { auditID });
  },
  revertToDraft(auditID: number, auth: AuthState) {
    return BaseService.put(`${SERVICE_NAME}/revert`, {}, auth, { auditID });
  },
  sendForReview(auditID: number, auth: AuthState) {
    return BaseService.put(`${SERVICE_NAME}/review`, {}, auth, { auditID });
  },
  countRecordedAudits(auth: AuthState): Promise<RecordedAuditCount> {
    return BaseService.get<RecordedAuditCount>(`${SERVICE_NAME}/recorded/count`, { auth });
  },
};
