import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import PersonIcon from '@mui/icons-material/Person';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { ModalWithContent, TabSet } from '../../../../../components';
import LocationFields from '../LocationFields/LocationFields';
import GrowerFields from '../GrowerFields/GrowerFields';
import styles from './LocationDetails.module.scss';
import { StorageLocation, GrowerLocation } from '../../../../../model/Merchant';
import { AvailableValue } from '../../../../../model/AvailableValues';

type LocationDetailsTypes = {
  location?: Partial<StorageLocation | GrowerLocation>,
  linkableLocations: StorageLocation[],
  onClose: () => void;
  isOpen: boolean;
};

export default function LocationDetails(props: LocationDetailsTypes) {
  const {
    location, linkableLocations, onClose, isOpen,
  } = props;
  const { state } = useLocation();

  const DEFAULT_NEW_STORAGE_LOCATION = useMemo(() => ({
    active: true,
    isDropOff: false,
    merchantID: state.merchantID,
  }), [state.merchantID]);

  const isGrowerLocation = useMemo(() => !!(location as GrowerLocation)?.igasNumber, [location]);

  const linkableLocationsAV = useMemo(() => (
    linkableLocations.filter(({ storageLocationID, linkedTo }) => (
      storageLocationID !== (location as StorageLocation)?.storageLocationID
      && !linkedTo
    )).map(({ siteName, storageLocationDetailsID }) => ({
      label: siteName,
      code: storageLocationDetailsID,
    }) as AvailableValue)
  ), [linkableLocations, location]);

  const TABS = useMemo(() => (
    [
      {
        icon: <LocationOnIcon />,
        label: 'Storage Location',
        content: (
          <LocationFields
            onClose={onClose}
            location={location || DEFAULT_NEW_STORAGE_LOCATION}
            linkableLocations={linkableLocationsAV}
          />
        ),
        id: 'location',
        state: ['merchantID'],
      },
      {
        icon: <PersonIcon />,
        label: 'Grower',
        content: (
          <GrowerFields
            onClose={onClose}
            location={location || DEFAULT_NEW_STORAGE_LOCATION}
            linkableLocations={linkableLocationsAV}
          />
        ),
        id: 'grower',
        state: ['merchantID'],
      },
    ]
  ), [DEFAULT_NEW_STORAGE_LOCATION, onClose, linkableLocationsAV, location]);

  return (
    <ModalWithContent
      isOpen={isOpen}
      onClose={onClose}
      contentClassName={styles.fieldContainer}
      modalClassName={styles.container}
    >
      <TabSet
        tabs={TABS}
        contentClassName={styles.tabContainer}
        activeID={isGrowerLocation ? 'grower' : undefined}
        hideTabs={!!location}
      />
    </ModalWithContent>
  );
}
