import React, { useCallback } from 'react';
import classNames from 'classnames';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Paper from '@mui/material/Paper';
import styles from './ModalWithContent.module.scss';

const closeButtonStyles = {
  zIndex: 1000,
  position: 'absolute',
  top: 5,
  right: 5,
};

type ModalWithContentTypes = {
  isOpen: boolean;
  onClose: (isOpen: boolean) => void;
  modalClassName?: string;
  contentClassName?: string;
  children: React.ReactElement;
};

const ModalWithContent: React.FC<ModalWithContentTypes> = (props) => {
  const {
    isOpen = false,
    onClose,
    modalClassName,
    contentClassName,
    children,
  } = props;

  const handleClose = useCallback(() => {
    onClose(false);
  }, [onClose]);

  const modalClasses = classNames(styles.modal, modalClassName);
  const modalContentClasses = classNames(styles.modalContent, contentClassName);

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      className={modalClasses}
      closeAfterTransition
    >
      <Fade in={isOpen}>
        <Paper className={modalContentClasses}>
          <IconButton sx={closeButtonStyles} onClick={handleClose} id="closeModal">
            <CloseIcon />
          </IconButton>
          {children}
        </Paper>
      </Fade>
    </Modal>
  );
};

export default ModalWithContent;
