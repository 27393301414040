import { BaseService } from '../TransportService';

const SERVICE_NAME = 'merchant';

export default {
  create(merchant, auth) {
    return BaseService.post(SERVICE_NAME, merchant, auth);
  },
  get(merchantID, auth) {
    const params = { merchantID };
    return BaseService.get(SERVICE_NAME, { params, auth });
  },
  update(merchant, auth) {
    return BaseService.put(SERVICE_NAME, merchant, auth);
  },

  search(merchantQuery, searchType, auth) {
    const params = { query: merchantQuery, searchType };
    return BaseService.get(`${SERVICE_NAME}/search`, { params, auth });
  },
};
