import React, { useState, useCallback, useMemo } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useOktaAuth } from '@okta/okta-react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { useBreakpoint } from '../../contexts/BreakpointContext/BreakpointContext';
import AuthUtil from '../../util/authUtil';
import InputField from '../InputField/InputField';
import navStyles from './Nav.module.scss';

function Nav(props) {
  const { menuCallback, isOpen } = props;
  const [igasNumber, updateIGASNumber] = useState();
  const navigate = useNavigate();
  const { isMobile } = useBreakpoint();
  const { authState } = useOktaAuth();

  const hasGrowerAccess = useMemo(() => AuthUtil.hasAccess(['Admin', 'Auditor'], authState), [authState]);

  const handleSubmit = useCallback((event) => {
    event.preventDefault();
    const pathname = '/growerDetail';
    navigate(pathname, { state: { igasNumber }, replace: true });
    updateIGASNumber(undefined);
  }, [igasNumber, navigate]);

  const growerSearch = useMemo(() => (
    hasGrowerAccess ? (
      <form onSubmit={handleSubmit}>
        <InputField
          label="IGAS Number"
          value={igasNumber}
          id="headerIGASNumberEntry"
          labelClassName={{
            root: navStyles.igasNumberInput,
          }}
          onValueChange={(value) => updateIGASNumber(value)}
        />
      </form>
    ) : null
  ), [igasNumber, handleSubmit, hasGrowerAccess]);

  return (
    <div className={navStyles.container}>
      <AppBar
        className={classNames(navStyles.navBar, {
          [navStyles.navBarDrawerOpen]: isOpen,
          [navStyles.navBarDrawerClosed]: !isOpen,
        })}
        position="relative"
      >
        <Toolbar disableGutters={!isOpen} className={navStyles.headerTitle}>
          <div className={navStyles.title}>
            <IconButton
              color="inherit"
              id="openMenu"
              aria-label="Open drawer"
              onClick={menuCallback}
              className={classNames({
                [navStyles.hide]: isOpen,
              })}
            >
              <MenuIcon />
            </IconButton>
            <Link to="/" className={navStyles.titleLink}>
              <Typography variant="h6" color="inherit" noWrap id="companyName">
                {isMobile ? 'IGAS' : 'Irish Grain Assurance Scheme'}
              </Typography>
            </Link>
          </div>
          <div className={navStyles.igasNumberContainer}>
            {growerSearch}
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}

Nav.propTypes = {
  menuCallback: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default Nav;
