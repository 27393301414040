import React from 'react';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Fade from '@mui/material/Fade';
import LinearProgress from '@mui/material/LinearProgress';
import styles from './PageLoadingComponent.module.scss';

type PageLoadingComponentType = {
  isLoading: boolean;
  message?: string;
  children?: React.ReactNode;
};

const PageLoadingComponent: React.FC<PageLoadingComponentType> = (props) => {
  const { isLoading = false, message, children } = props;

  if (!isLoading && children) {
    return children;
  }

  return (
    <Modal
      open={isLoading}
      className={styles.modal}
      closeAfterTransition
    >
      <Fade in={isLoading}>
        <div className={styles.modalContent}>
          {message ? (
            <Typography variant="h5" component="h1" className={styles.message}>
              {message}
            </Typography>
          ) : null}
          <LinearProgress />
        </div>
      </Fade>
    </Modal>
  );
};

export default PageLoadingComponent;
