import { AuthState } from '@okta/okta-auth-js';
import { AuditeeAssign } from '../../model/Audit';
import { BaseService, DocumentService } from '../TransportService';

const SERVICE_NAME = 'audit/list';

export default {
  create(auth: AuthState) {
    return BaseService.get(`${SERVICE_NAME}/new`, { auth });
  },
  get(harvestYear: number, auth: AuthState) {
    const params = { harvestYear };
    return BaseService.get(SERVICE_NAME, { params, auth });
  },
  assign(
    audits: AuditeeAssign[],
    shouldFilter: boolean,
    shouldOverride: boolean,
    auth: AuthState,
  ): Promise<AuditeeAssign[]> {
    return BaseService.put<AuditeeAssign[], AuditeeAssign[]>(
      `${SERVICE_NAME}/assign`,
      audits,
      auth,
      { shouldFilter, shouldOverride },
    );
  },
  auditorList(harvestYear: number, auditor: number | undefined, filename: string, auth: AuthState) {
    const params: { harvestYear: number, auditorID?: number } = { harvestYear };
    if (auditor) {
      params.auditorID = auditor;
    }
    return DocumentService.get(`${SERVICE_NAME}/auditors`, params, filename, auth);
  },
  notification(
    harvestYear: number,
    auditor: number | undefined,
    filename: string,
    auth: AuthState,
  ) {
    const params: { harvestYear: number, auditorID?: number } = { harvestYear };
    if (auditor) {
      params.auditorID = auditor;
    }
    return DocumentService.get(`${SERVICE_NAME}/notification`, params, filename, auth);
  },
  singleNotification(
    igasNumber: string | number,
    filename: string,
    auth: AuthState,
  ) {
    const params: { igasNumber: string | number } = { igasNumber };
    return DocumentService.get(`${SERVICE_NAME}/notification/single`, params, filename, auth);
  },
};
