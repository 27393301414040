import { AuthState } from '@okta/okta-auth-js';
import { BaseService } from '../TransportService';
import { Poll } from '../../model/File/File';
import { pollDownload } from '../TransportService/PollService';

const SERVICE_NAME = 'file';

export default {
  get(uuid: string, auth: AuthState): Promise<Poll | undefined> {
    return pollDownload(() => BaseService.get<Poll>(`${SERVICE_NAME}/poll`, { auth, params: { id: uuid } }));
  },
};
