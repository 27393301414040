import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import _ from 'lodash';

function CheckboxField(props) {
  const {
    id,
    name,
    path,
    label,
    value,
    onValueChange,
    disabled,
    className,
    defaultValue,
    inputFieldClass,
    ...rest
  } = props;

  useEffect(() => {
    if (defaultValue !== undefined && _.isUndefined(value)) {
      onValueChange(defaultValue, path);
    }
  // Disabling to stop re-renders
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue, path]);

  const handleChange = useCallback((event) => {
    const newValue = event.target.checked;

    onValueChange(newValue, path);
  }, [onValueChange, path]);

  return label ? (
    <FormControlLabel
      label={label}
      className={className}
      control={(
        <Checkbox
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...rest}
          checked={value === undefined ? defaultValue : value}
          color="primary"
          id={id}
          className={inputFieldClass}
          onChange={rest.onChange || handleChange}
          name={name}
          value={value === undefined ? defaultValue : value}
          disabled={disabled}
        />
      )}
    />
  ) : (
    <Checkbox
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      checked={value === undefined ? defaultValue : value}
      color="primary"
      id={id}
      className={inputFieldClass}
      onChange={rest.onChange || handleChange}
      name={name}
      value={value === undefined ? defaultValue : value}
      disabled={disabled}
    />
  );
}

CheckboxField.propTypes = {
  ...Checkbox.propTypes,
  name: PropTypes.string,
  path: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  onValueChange: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  inputFieldClass: PropTypes.string,
};

CheckboxField.defaultProps = {
  ...Checkbox.defaultProps,
  name: undefined,
  path: undefined,
  label: undefined,
  value: undefined,
  onValueChange: _.noop,
  disabled: false,
  className: undefined,
  inputFieldClass: undefined,
  defaultValue: undefined,
};

export default CheckboxField;
