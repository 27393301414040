export default {
  // Audit
  AUDIT_YEAR: 'auditYear',
  PREVIOUS_AUDIT_YEAR: 'previousAuditYear',
  AUDIT_QUESTION: 'auditQuestion',
  IS_ACTIVE: 'isActive',
  // Merchant
  MERCHANT: 'merchant',
  MERCHANT_CONTACT: 'merchantContact',
};
