import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useOktaAuth } from '@okta/okta-react';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import Tooltip from '@mui/material/Tooltip';
import { GrowerAuditService } from '../../services/Grower';

function GrowerAuditStatus(props) {
  const { igasNumber } = props;
  const { authState } = useOktaAuth();
  const [auditStatus, setAuditStatus] = useState();

  useEffect(() => {
    GrowerAuditService.getLatestInfo(igasNumber, authState).then(setAuditStatus);
  }, [igasNumber, authState]);

  if (!auditStatus) {
    return null;
  }

  return (
    <Tooltip title={auditStatus?.auditDate} arrow placement="top">
      <Chip
        avatar={<Avatar>{auditStatus?.auditResult === 'RECOMMENDED' ? <CheckIcon /> : <CloseIcon />}</Avatar>}
        label={auditStatus?.resultDescription}
        color={auditStatus?.auditResult === 'RECOMMENDED' ? 'primary' : 'secondary'}
      />
    </Tooltip>
  );
}

GrowerAuditStatus.propTypes = {
  igasNumber: PropTypes.string.isRequired,
};

export default GrowerAuditStatus;
