import { useCallback, useMemo } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import AuthUtil from '../../util/authUtil';

type UseUserAccessReturnType = {
  hasAccess: (userType: string | string[]) => boolean;
  isAdmin: boolean;
};

export default function useUserAccess(): UseUserAccessReturnType {
  const { authState } = useOktaAuth();

  const hasAccess = useCallback((userType: string | string[]) => (
    AuthUtil.hasAccess(userType, authState!)
  ), [authState]);

  const isAdmin = useMemo(() => hasAccess('Admin'), [hasAccess]);

  return {
    hasAccess,
    isAdmin,
  };
}
